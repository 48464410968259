import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import utils from './utils';

function CustomDialog(props) {
  let [dialog, setDialog] = useState({});
  let [message, setMessage] = useState({});
  let [title, setTitle] = useState({});
  let [actions, setActions] = useState([]);
  let [dialogOpen, setDialogOpen] = useState(false);

  dialog.alert = function (message, title, actions) {
    if (!actions) {
      actions = [];
    }

    if (!title) {
      title = 'Atenção';
    }
    setTitle(title);
    setMessage(message);
    setActions(actions);
    setDialogOpen(true);
  }


  const actClick = function (act) {
    act.action.call(this);
    setDialogOpen(false);
  }

  dialog.close = function () {
    setDialogOpen(false);

    if (actions.length == 1) {
      actions.forEach(ac => {
        ac.action.call(this);
      });
    }
  }

  window.dialog = dialog;

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={dialog.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              dangerouslySetInnerHTML={{
                __html: message
              }}></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.length <= 0 &&
            <Button onClick={dialog.close} variant="outlined" color="primary">{utils.lng('fechar','Fechar')}</Button>
          }
          {actions.length > 0 &&
            <>
              {actions.map((ac, i) => (
                <Button variant="outlined" onClick={() => { actClick(ac) }} color="primary">
                  {ac.label}
                </Button>
              ))}
            </>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomDialog;