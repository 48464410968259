import React, { useState, useEffect } from "react";
import utils from './utils';

const MmnItem = function (props) {
  let crud = props.crud;
  let view = props.view;
  let level = props.level ? props.level : 0;
  let parent = props.parent ? props.parent : 0;
  let idClienteFiltro = props.clienteFiltro ? props.clienteFiltro : 0;
  let [list, setList] = useState([]);
  let [show, setShow] = useState(parent == 0);


  let [ready, setReady] = useState(false);

  const load = function () {
    utils.execute(
      crud, 
      "listarRede?size=0&superior=" + parent +"&id_cliente_filtro="+idClienteFiltro, 
      null, 
      function (res) {
        setList(res.data);
      }
    );
  };

  if (!ready) {
    setReady(true);
    load();
  }

  const showDetail = function (li) {
    li.detail = true;
  };
  
  const showItem = function (li) {
    li.show = !li.show;

    const tmp = [];

    for (const i in list) {
      tmp.push(list[i]);
    }
    setList(tmp);
  };

  return (
    <>
      {list.length > 0 &&
        <div className="ui-mmn-itens">
          {list.map((li, ib) => (
            <div className={level % 2 === 0 ? 'ui-mmn-item ui-mni-even' : 'ui-mmn-item ui-mni-odd'}>
              <div className="ui-mmn-divisor"></div>
              <div className="ui-mmn-object" >
                <div className="ui-mmn-object-avatar" onClick={() => { showItem(li) }}>
                  <img src={li.avatar ? li.avatar : 'https://cdn.conecteme.com.vc/files/utilizador_0000000001/cb3392bbcb3bdbc7fd24d23d9c7fe7a6.jpeg'}></img>
                </div>
                <div className="ui-mmn-object-label" onClick={() => { showItem(li) }}>
                  <div className="ui-mmn-label-inner">{li.nome}</div>
                  <div className="ui-mmn-label-graduacao">{li.graduacao ? li.graduacao : 'Sem graduação'}</div>
                </div>
                <div className="ui-mmn-object-detail" onClick={() => { crud.openDetail(li) }}>
                  <i className="fas fa-ellipsis-v"></i>
                </div>
              </div>
              {li.show &&
                <MmnItem level={level + 1} parent={li.id} crud={crud} view={view}></MmnItem>
              }
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default MmnItem;