import React, { useState, useEffect } from "react";
import Element from './element';
import utils from "./utils";


const Carousel = function (props) {
  let crud = props.crud;
  let view = props.view;
  let data = props.data;
  let classificacao = props.classificacao;


  const id = 'c_produtos_' + data.id;

  const item = {
    name: "produtos",
    id: id,
    type: "list",
    layout: 'carousel',
    rowCols: 3,
    itemVar: 'p',
    rows: 10,
    rowStyleClass: 'ui-row-card ui-card-produto card-produto-classe card-produto-classe-banner',
    entity: 'HubProduto',
    parent: 'catalogo',
    label: data.descricao,
    add: false,
    empty: false,
    header: false,
    search: false,
    list: {
      url: 'listarProdutosCatalogo?id_home=' + data.id + (classificacao && classificacao > 0 ? '&classificacao=' + classificacao : ''),
      method: 'GET'
    },
    elements: {
      favorito: {
        click: {
          url: 'cart/changeFavorite/{id_sku}'
        },
        variant: 'text',
        styleClass: "favorito-classe ${favorito ? 'ui-selected' : ''}",
        type: 'button', icon: "${favorito ? 'fas fa-heart' : 'far fa-heart'}", theme: 'default', cols: 2
      },
      corpo: {
        elements: {
          destaque: { field: 'destaque_descricao', rendered: '#{typeof @this.destaque_descricao !== "undefined"}', background: '#{@this.destaque_cor}' },
          avatar: { type: 'link', field: 'avatar', label: ' ', upload: false, typeStyle: 'CardProduct', primeiro_avatar: '${@this.primeiro_avatar}', segundo_avatar: '${@this.segundo_avatar}' },
          descricao: { field: 'descricao' },
          indisponivel: { rendered: "${@this.saldo <= 0 && @this.valida_estoque}", value: utils.lng('produto_indisponivel','Produto indisponível') },
          tag_grade: { type: 'span', field: 'codigo_grade', styleClass: 'ui-tag ui-bottom ui-left ${@this.rowIndex % 2 == 0 ? "ui-tag-destaque" : ""}' },
          valor_preco_venda: {
            styleClass: 'ui-valor-original',
            type: 'span', format: 'money', field: 'valor_preco_venda', value: '#{"de " + utils.money(@this.valor_preco_venda) + " por "}',
            rendered: '${@this.percentual_desconto > 0}',
            style: { color: '#555', fontSize: '20px', fontWeight: 'bold' }
          },
          valor: { type: 'span', format: 'money', field: 'valor', value: "${utils.money(@this.valor) + (@this.codigo_grade === 'PE' ? ' / metro' : '')}"}
        },
        click: {
          action: 'open',
          url: '/carrinho/produto/{id_sku}'
        }
      },
      comandos: {
        elements: {
          comprar: {
            disabled: "${@this.saldo <= 0 && @this.valida_estoque}",
            click: {
              action: 'open',
              url: '/carrinho/produto/{id_sku}'
            },
            variant: 'text',
            type: 'button', label: 'COMPRAR', icon: 'fa fa-shopping-cart', theme: 'default', cols: 10
          }
          
        }
      }
    }
  };

  return (
    <div className="ui-catalogo-carousel">
      <Element crud={crud} view={view} value={item} ></Element>
    </div>
  );
};

export default Carousel;