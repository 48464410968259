import React, { useEffect, useRef, useState } from 'react';
import View from './view';
import utils from './utils';
import Element from './element';
import { DropzoneDialog } from "material-ui-dropzone";

function CredencialVirtual() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [index, setIndex] = useState(0);
  let [ready, setReady] = useState(false);
  let urlApi = utils.getUrlApi();
  let token = utils.getToken();
  
  let [listaCredenciais, setListaCredenciais] = useState([]);
  let [credencialSelecionada, setCredencialSelecionada] = useState(null);
  let [nomePessoacredencial, setNomePessoacredencial] = useState('');
  let [avatarPessoa, setAvatarPessoa] = useState('');
  let [tipoSocioCredencial, setTipoSocioCredencial] = useState('');
  let [nomeCliente, setNomeCliente] = useState('');
  let [codigoGraduacaoVigente, setCodigoGraduacaoVigente] = useState('');
  let [graduacaoVigente, setGraduacaoVigente] = useState('');
  let [codigoFilial, setCodigoFilial] = useState('');
  let [codigoMundialDi, setCodigoMundialDi] = useState('');
  let [codigoMundialPresidente, setCodigoMundialPresidente] = useState('');

  const defaultBackgroundImg = '/static/img/background_default_credencial_virtual.png';
  let urlCartaoCredencialCdn = 'https://storage.googleapis.com/interpennweb-storage/credential_card/graduacao_{cod_graduacao}.png';
  let [backgroundImg, setBackgroundImg] = useState(defaultBackgroundImg);
  
  const avatarDefault = '/static/img/avatar.jpg';

  let [showDialogAvatar, setShowDialogAvatar] = useState(false);

  const switchDialogAvatar = function () {
    showDialogAvatar = !showDialogAvatar;
    setShowDialogAvatar(showDialogAvatar);
  }

  crud.reprocessaCredenciais = function () {
    if (!ready) {
      const request_refresh = {};
      
      request_refresh.crud = crud;
      request_refresh.validate = false;
      request_refresh.changed = crud.data;
      request_refresh.element = {};

      request_refresh.cfg = {
        url: 'credencial_cliente/refresh',
        method: 'POST'
      };
      request_refresh.complete = (res) => {
        if (res.data && res.data.length > 0) {
          setListaCredenciais(res.data);
        }
      };
      utils.call(request_refresh);
      setReady(true);
    }
  };

  crud.onChangeCredencial = function (evt, args, x) {
    let idCredencial = args.credenciais.id;
    if (idCredencial) {
      const request_credencial = {};
    
      request_credencial.crud = crud;
      request_credencial.validate = false;
      request_credencial.changed = crud.data;
      request_credencial.element = {};

      request_credencial.cfg = {
        url: 'CredencialCliente/'+ idCredencial,
        method: 'GET'
      };
      request_credencial.complete = (res) => {
        if (res.data && res.data.id && res.data.id > 0) {
          credencialSelecionada = res.data;
          setCredencialSelecionada(res.data);

          try {
            setNomePessoacredencial(credencialSelecionada.nome);
            setAvatarPessoa(credencialSelecionada.avatar ? credencialSelecionada.avatar : avatarDefault);
            setTipoSocioCredencial(credencialSelecionada.descricaoTipoSocio ? credencialSelecionada.descricaoTipoSocio.toUpperCase() : '');
            setNomeCliente(credencialSelecionada.nomeCliente);
            setCodigoGraduacaoVigente(credencialSelecionada.codigoGraduacaoVigente);
            setGraduacaoVigente(credencialSelecionada.graduacaoVigente);
            setCodigoFilial(credencialSelecionada.codigoFilial);
            setCodigoMundialDi(credencialSelecionada.codigoMundialDi);
            setCodigoMundialPresidente(credencialSelecionada.codigoMundialPresidente);
            
            setBackgroundImg( urlCartaoCredencialCdn.replace("{cod_graduacao}", credencialSelecionada.codigoGraduacaoVigente) );
          } catch (e) {
            console.log(e);
          }
        }
      };
      utils.call(request_credencial);
    } else {
      credencialSelecionada = null;
      setCredencialSelecionada(null);
      setNomePessoacredencial('');
      setAvatarPessoa(avatarDefault);
      setTipoSocioCredencial('');
      setNomeCliente('');
      setCodigoGraduacaoVigente('');
      setGraduacaoVigente('')
      setCodigoFilial('');
      setCodigoMundialDi('');
      setCodigoMundialPresidente('');
      setBackgroundImg(defaultBackgroundImg);
    }
    view.refresh();
  };

  crud.reprocessaCredenciais();
  
  let viewCfg = {
    label: utils.lng('credencial_virtual', 'Credencial Virtual'),
    name: 'credencialVirtual',
    add: false,
    print: false,
    auto: true,
    type: 'card',
    widgetVar: 'credencialVirtual',
    elements: {
      credenciais: {
        type: 'select',
        name: 'credenciais',
        field: 'credenciais',
        label: utils.lng('credenciais', "Credenciais"),
        itemLabel: 'nome',
        itemValue: 'id',
        cols: 12,
        rendered: listaCredenciais && listaCredenciais.length > 0,
        data: listaCredenciais,
        change: {
          action: 'onChangeCredencial'
        }
      },
    }
  };

  let [selectedFile, setSelectedFile] = useState(null);
  const selectImage = (files) => {
    selectedFile = files[0];
    setSelectedFile(files[0]);

    if (selectedFile) {
      executeMultipartUpload(selectedFile);
    }
  };

  const executeMultipartUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);


    const name = file.name;
    const extension = file.extension ? file.extension : name.indexOf('.') > 0 ? name.substring(name.lastIndexOf('.') + 1, name.length) : 'none';

    const fs = {};

    fs.preview = false;
    fs.name = name;
    fs.extension = extension;
    fs.type = file.type;
    fs.size = file.size;

    if (!file.extension) {
        file.extension = extension;
    }

    let actions = [];
    actions.push({ label: utils.lng('confirmar', 'Confirmar'), action: () => {} })
    try {
      let url = utils.getUrlApi()+"credencial/upload/avatar?token="+utils.getToken();
      url += "&id_credencial="+credencialSelecionada.id;
      url += "&preview="+false;
      url += "&name="+name;
      url += "&extension="+extension;
      url += "&type="+file.type;
      url += "&size="+file.size;

      const response = await fetch(url, {
        method: "POST",
        params: fs,
        body: formData,
        headers: {},
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("Resposta da API:", result);
        window.location.href = '/credencial_virtual/'+credencialSelecionada.id;
      } else {
        const error = await response.text();
        console.error("Erro:", error);
        if (window.dialog) {
          window.dialog.alert(utils.lng("ocorreu_erro_enviar_arquivo", "Ocorreu um erro ao enviar o arquivo.") + "\n\n" + error,null,actions);
        }
      }
    } catch (error) {
      console.error("Erro:", error);
      if (window.dialog) {
        window.dialog.alert(utils.lng("ocorreu_erro_enviar_arquivo", "Ocorreu um erro ao enviar o arquivo.") + "\n\n" + error,null,actions);
      }
    }
  };

  return (
    <View name="ui-credenciais" view={view}>
      <Element value={viewCfg} crud={crud} data={crud.data} view={view} ></Element>

      <div className="credencial-card" style={{ visibility: credencialSelecionada && credencialSelecionada.id ? 'visible' : 'hidden' }}>
        <img id="img-background-credencial" className="credencial-background-img" src={ backgroundImg }></img>
        
        <img className='credencial-avatar' src={ avatarPessoa } onClick={switchDialogAvatar}></img>
        <img className="credencial-qrcode" 
          src={ 
            urlApi+"/qrcode.png?access_token="+token+"&text="+(credencialSelecionada ? credencialSelecionada.qrCode : '')+"&width=450&height=450" 
          }
        ></img>

        <span className="credencial-field credencial-nome-pessoa"> {nomePessoacredencial} </span>
        <span className="credencial-field credencial-tipo-socio"> {tipoSocioCredencial} </span>
        <span className="credencial-field credencial-nome-di"> {nomeCliente} </span>
        <span className="credencial-field credencial-graduacao"> {graduacaoVigente} </span>
        <span className="credencial-field credencial-cod-filial"> {codigoFilial} </span>
        <span className="credencial-field credencial-codmun-di"> {codigoMundialDi} </span>
        <span className="credencial-field credencial-codmun-presidente"> {codigoMundialPresidente} </span>
        
      </div>
      <DropzoneDialog
          cancelButtonText={utils.lng('cancelar', 'Cancelar')}
          submitButtonText={utils.lng('enviar', 'Enviar')}
          dropzoneText={utils.lng("imagem","Imagem")}
          className="ui-dlg-upload"
          filesLimit={1}
          dialogTitle={utils.lng("imagem","Imagem")}
          // getFileAddedMessage={getFileAddedMessage}
          acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
          maxFileSize={5000000}
          open={showDialogAvatar}
          onClose={() => switchDialogAvatar()}
          onSave={(files) => {
            selectImage(files);
          }}
      />
    </View>
  );
  
}

export default CredencialVirtual;