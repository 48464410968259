import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import React, { useState, useRef, useEffect } from "react";
import InputMask from 'react-input-mask';
import Entity from './entity';
import Complete from './complete';
import Grid from './grid';
import Table from './table';
import utils from './utils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import JoditEditor from "jodit-react";
import { FormControl, InputLabel, NativeSelect, Select } from '@material-ui/core';

const Element = (props) => {
    let parentQuery = props.parentQuery;
    let clientId = props.clientId;
    let value = props.value;
    let disabled = props.disabled;
    let name = props.name;
    let onClick = props.onClick;
    let user = props.user;
    let parent = props.parent;
    let column = props.column;
    let readOnly = props.readOnly;
    let view = props.view ? props.view : window.view;

    const editor = useRef(null)

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    if (!value) {
        value = {};
    }

    let [crud, setCrud] = useState(props.crud);
    let data = props.data ? props.data : crud.data;
    let [inputValue, setInputValue] = useState('');
    let [inputValueHours, setInputValueHours] = useState('');
    let [inputValueMinutes, setInputValueMinutes] = useState('');
    let [dateStart, setDateStart] = useState(null);
    let [oldValue, setOldValue] = useState(null);
    let [dateEnd, setDateEnd] = useState(null);
    let [preview, setPreview] = useState('');
    let [index, setIndex] = useState(0);
    let [state, setState] = useState(0);
    let [checkValue, setCheckValue] = useState(value.nullValues ? null : false);
    let [popup, setPopup] = useState(false);
    let [hide, setHide] = useState(false);
    let [error, setError] = useState(false);
    let [loading, setLoading] = useState(false);
    let [ready, setReady] = useState(false);
    let [template, setTemplate] = useState('');
    let [showLabel, setShowLabel] = useState(true);
    let [itens, setItens] = useState([]);
    let [files, setFiles] = useState([]);
    let [customView, setCustomView] = useState(null);
    let [customCrud, setCustomCrud] = useState({ data: {}, changed: {} });
    let [mobile, setMobile] = useState(false);
    let disableOnLimit = value.disableOnLimit;
    let min = value.min;
    min = min && (min + '').indexOf('${') > -1 ? utils.decode({ value: min }, crud, data).value : min;
    let max = value.max;
    max = max && (max + '').indexOf('${') > -1 ? utils.decode({ value: max }, crud, data).value : max;
    var qtdValue;
    if (value.attrs) {
        for (const i in value.attrs) {
            value[i] = value.attrs[i];
        }
    }

    if (!crud.data) {
        crud.data = {};
    }

    if (!crud.selected) {
        crud.selected = crud.data;
    }

    if (props.type === 'custom') {
        data = crud.data;
    }

    let element = utils.decode(value, crud, data);
    let type = element.type ? element.type : 'span';

    useEffect(function () {
        let include = element.src;

        if (type == 'view') {
            let _call_ = {};
            let lv = { id: data.id };

            customCrud.data = lv;

            _call_.element = element;
            _call_.cfg = element.load;
            _call_.complete = function (res) {
                if (res.data) {
                    customView = res.data;
                    setCustomView(res.data);

                    if (customView.data) {
                        customCrud.data = customView.data;
                    }

                    value.refresh();
                }
            };
            _call_.data = lv;
            _call_.changed = {};
            _call_.crud = crud;
            _call_.view = view;


            utils.call(_call_);
        } else if (type == 'include') {
            fetch(include, {
                headers: {
                    'Content-Type': 'text/html',
                    'Accept': 'text/html'
                }
            }
            )
                .then(function (response) {
                    return response.text();
                })
                .then(function (text) {
                    let rs = { field: 'aux', value: text };
                    let ro = utils.decode(rs, crud, data);

                    template = ro.value;
                    inputValue = template;
                    setTemplate(template);
                    setInputValue(template);
                });
        }
    }, []);

    let id = 'root';
    let styleClass = element.styleClass ? element.styleClass : '';
    let format = element.format;
    let className = 'ui-el-item ui-el-type-' + type + ' ' + styleClass;
    let staticClassName = value.staticClassName && value.staticClassName !== '' ? value.staticClassName : null;
    let changed = crud ? crud.changed : {};
    let [local, setLocal] = useState({});
    let [open, setOpen] = React.useState(false);
    let [currencySymbol, setCurrencySymbol] = useState('R$');
    let [decimalCharacter, setDecimalCharacter] = useState(',');
    let [digitGroupSeparator, setDigitGroupSeparator] = useState('.');


    if (element.name) {
        name = element.name;
    }

    if (element.currencySymbol) {
        currencySymbol = element.currencySymbol;
    } else {
        currencySymbol = utils.currencySymbol();
    }

    if (element.decimalCharacter) {
        decimalCharacter = element.decimalCharacter;
    }

    if (element.digitGroupSeparator) {
        digitGroupSeparator = element.digitGroupSeparator;
    }


    if (!crud.requireds) {
        crud.requireds = {};
    }

    if (!disabled && (element.disabled || element.edit === false)) {
        disabled = true;
    }

    if (!data) {
        data = {};
    }

    id = element.id;

    if (!id) {
        id = name;
    }

    if (name) {
        className += ' ui-el-item-' + name;
    }

    if (value.gallery) {
        className += ' ui-el-gallery';
    }

    if (type === 'tabItem') {
        let currTab = crud.tabIndex;

        if (typeof currTab === 'undefined') {
            currTab = 0;
        }

        if (element.index !== currTab) {
            return ('');
        }
    }

    if (typeof element.rendered === 'string') {
        element.rendered = false;
    }

    if (element.rendered === false) {
        return ('');
    }
    if (element.visible === false && element.filter !== true) {
        return ('');
    }

    let isInput = '|text|tel|textarea|password|email|money|number|'.indexOf('|' + type + '|') >= 0;

    let target = element.target;
    let layout = element.layout;
    let cols = element.cols;
    let space = element.space;
    let width = element.width ? element.width : null;
    let rowCols = element.rowCols;
    let field = element.field ? element.field : name;
    let theme = element.theme;
    let buttonTheme = theme ? theme : 'primary';
    let title = element.title ? element.title : element.label;
    let label = element.label ? utils.lng(name, element.label) : element.label;
    let labelStart = element.labelStart ? utils.lng(name + '_start', element.labelStart) : 'Início';
    let labelEnd = element.labelEnd ? utils.lng(name + '_end', element.labelEnd) : 'Fim';
    let help = element.help;
    let helpFile = help ? help : utils.lng('drop_text', 'Arraste e solte seu arquivo aqui ou clique');
    let rows = element.rows ? element.rows : 5;
    let _value_ = element.value;
    let href = element.href ? element.href : '';
    let itemLabel = element.itemLabel;
    let itemValue = element.itemValue;
    let required = element.required;
    let isBotaoFechar = type === 'button' && (label ? label : "").toUpperCase() === utils.lng("fechar", "FECHAR").toUpperCase();
    if (isBotaoFechar) {
        disabled = false;
    }
    if (element.disabled !== true && (element.elEdit === true || element.edit === true) && !isBotaoFechar) {
        disabled = false;
    } else if (disabled !== true && (element.edit === false || element.elEdit == false) && !isBotaoFechar) {
        disabled = true;
    }

    if ((crud.data.disabled || crud.data.edit === false) && !isBotaoFechar) {
        disabled = true;
    }

    let center = element.center;
    let bold = element.bold;
    let mask = element.mask;
    let style = element.style;
    let multiline = false;
    let realType = type;

    if (value.filters) {
        for (const fi in value.filters) {
            const fv = value.filters[fi];


            if (fv.default && !fv.ready) {
                const df = fv.default;

                fv.ready = true;

                if ((fv.type === 'date' || fv.type === 'datetime') && fv.range) {
                    const a = df.start ? df.start.getTime() : '';
                    const b = df.end ? df.end.getTime() : '';

                    changed[fi] = a + '|' + b;
                } else if (typeof df !== 'object') {
                    changed[fi] = df;
                }
            }
        }
    }

    if (!window.view) {
        window.view = {};
        view = window.view;
    }

    if (!type) {
        type = 'span';
    }

    if (name === 'avatar') {
        type = 'avatar';
    }

    value.query = utils.resolve(element, data);

    const setFieldValue = function (val) {
        utils.set(field, data, val);
        utils.set(field, changed, val);

        let sp = field.split('.');

        utils.set(sp[sp.length - 1], local, val);
    }

    const setValue = function (val, event) {
        let str = '';

        if (typeof val != 'object') {
            str = val ? val + '' : '';
        }

        if (element.mask && element.mask.indexOf("***") < 0 && element.unmask !== false) {
            str = str.replace(/[^\d]+/g, '');
            val = str;
        }

        console.log(val, crud)


        if (element.type === 'select') {
            if (val) {
                if (element.object === false) {
                    //faz nda
                } else {
                    let id = parseInt(val);

                    if (element.itemValue) {
                        let cache = {};

                        for (const i in itens) {
                            let k = itens[i][element.itemValue];

                            cache[k] = itens[i];
                        }

                        if (!crud.objects) {
                            crud.objects = {};
                        }

                        delete crud.objects[name];

                        if (cache[val]) {
                            crud.objects[name] = cache[val];
                        }
                    }

                    val = { constante: val };

                    if (!isNaN(id)) {
                        val.id = id;
                    }
                }
            } else {
                val = null;
            }
        }

        if (type !== 'checklist') {
            setFieldValue(val);
        }

        if (val !== null && typeof val === 'object') {
            let x = utils.resolve(element, data);

            setInputValue(x);
            value.query = x;
        } else {
            value.query = val;
            setInputValue(val);
        }

        if (type === 'checklist') {
            val = getCheckList(val);

            setFieldValue(val);
        }

        error = false;
        setError(false);

        let min = element.min ? element.min : 0;

        if (min <= str.length) {
            action(value.change, 'change');
            action(value.blur, 'blur');
        }

        if (value.rowEdit === true) {
            //faz nada
        } else {
            refreshParent();
        }
    }

    if (!element.variant) {
        if (element.type === 'button') {
            element.variant = 'contained';
        } else {
            element.variant = 'outlined';
        }

        value.variant = element.variant;
    }
    let variant = element.variant;

    element.loading = false;
    element.clear = function () {
        value.message = '';
        element.message = '';

        error = false;
        setError(false);
    }
    element.setError = function (message) {
        element.clear();
        value.message = message;
        element.message = message;

        error = true;
        setError(error);
        return error;
    }

    element.setValue = function (value) {
        setValue(value);
    }

    value.clear = element.clear;
    value.setValue = element.setValue;

    value.show = function () {
        if (value.empty === false) {
            setHide(false);
        }
    }

    value.hide = function () {
        if (value.empty === false) {
            setHide(true);
        }
    }

    value.refresh = function () {
        setState(state + 1);
        setReady(false);

        if (parent) {
            parent.refresh();
        }
    };

    value.setIndex = function (i) {
        setIndex(i);
    }
    if (required) {
        crud.requireds[field] = element;
    }

    const getCheckList = function (val) {
        let checklist = [];

        if (val) {
            let x = val.replaceAll(',', ' ');

            x = x.trim().replaceAll(' ', ',');
            let list = x.split(',');

            for (const i in list) {
                checklist[i] = list[i];
            }
        }
        return checklist;
    }

    if (field) {
        if (field.indexOf('.') >= 0) {
            try {
                inputValue = eval('data.' + field);
            } catch (error) {
                inputValue = '';
            }
        } else if (typeof data[field] !== 'undefined') {
            inputValue = data[field];
        } else if (typeof data[field.toLowerCase()] !== 'undefined') {
            inputValue = data[field.toLowerCase()];
        } else if (typeof changed[field] !== 'undefined') {
            inputValue = changed[field];
        }

        if (inputValue != null && type === 'checklist') {
            if (typeof inputValue === 'object') {
                inputValue = ',' + inputValue.join(',') + ',';
            }
        }
    }

    if (element.format === 'money') {
        inputValue = utils.money(inputValue);
    }

    if (element.format === 'number') {
        inputValue = utils.number(inputValue);
    }

    if (type === 'date' || type === 'datetime' || format === 'date' || format === 'datetime') {
        if (!inputValue) {
            inputValue = null;
        } else {
            inputValue = utils.toDate(inputValue);
            if (inputValue) {
                inputValueHours = inputValue.getHours();
                inputValueMinutes = inputValue.getMinutes();
            }
        }
    } else if (typeof inputValue === 'object') {
        if (type === 'select') {
            if (inputValue != null) {
                if (inputValue.constante) {
                    inputValue = inputValue.constante;
                } else if (inputValue.id) {
                    inputValue = inputValue.id;
                }
            }
        } else {
            inputValue = utils.resolve(element, data);
        }
    }

    if (!inputValue && (type === 'span' || type === 'div')) {
        inputValue = _value_;
    }

    if (type === 'checkbox') {
        let sch = inputValue + '';

        if (sch === 'true') {
            checkValue = true;
        } else if (sch === 'false' || !element.nullValues) {
            checkValue = false;
        } else {
            checkValue = null;
        }
    }

    if (type === 'textarea') {
        multiline = true;
    }

    if (type === 'img' && !inputValue) {
        inputValue = element.value;
    }

    if (type) {
        className += ' ui-el-type-' + type;
    }

    if (variant) {
        className += ' ui-variant-' + variant;
    }


    if (theme) {
        className += ' ui-theme-' + theme;
    }

    if (disabled && (isInput || element.click)) {
        className += ' ui-disabled';
    }

    if (column) {
        className += ' ui-el-column ';

        if ((element.type === 'upload' || element.type === 'file' || element.upload) && !target) {
            target = '_blank';
        }
    }

    let tab = crud.tabIndex;

    if (typeof tab === 'undefined') {
        tab = 0;
    }

    if (type === 'item' && tab === value.index) {
        className += ' ui-tab-sel';
    }

    if (bold) {
        className += ' ui-text-bold';
    }

    if (center) {
        className += ' ui-text-center';
    }

    if (space) {
        className += ' ui-col ui-col-' + space;
    }

    if (cols) {
        className += ' ui-col ui-col-' + cols;
    }

    if (error) {
        className += ' ui-error';
    }

    if (isInput) {
        className += ' ui-el-input';


        if (mask) {
            className += ' ui-input-mask';
        }
    }

    if (layout) {
        className += ' ui-layout-' + layout;
    }

    let rowClassName = 'ui-grid-row';

    if (rowCols) {
        rowClassName += ' ui-col ui-col-' + rowCols;
    }

    if (!itemLabel) {
        itemLabel = 'label';
    }

    if (!itemValue) {
        itemValue = 'value';
    }

    if (element.data && !element.list) {
        itens = [];

        if (type === 'select') {
            itens.push({ label: utils.lng('', ''), value: '' });
        }

        for (const i in element.data) {
            const v = element.data[i];
            const b = {};

            b.label = v[itemLabel];
            b.value = v[itemValue];

            if (typeof b.label === 'undefined') {
                b.label = v;
            }

            if (typeof b.value === 'undefined') {
                b.value = i;
            }

            itens.push(b);
        }
    }

    const enter = function () {
        action(value.enter, 'enter');
    }

    const keyPressed = function (event) {
        if (event.key === "Enter") {
            enter(event);
        }
    }

    const refreshParent = function () {
        if (parent) {
            parent.refresh();
        }
    }

    const onChangeDate = function (val) {
        if (val && typeof val === 'object' && element.dateFormat === 'long') {
            val = val.getTime();
        }

        utils.set(field, data, val);
        utils.set(field, changed, val);

        try {
            val.setSeconds(0);
        } catch (e) { }
        setInputValue(val);
        error = false;
        setError(false);

        action(value.change, 'change');

        refreshParent();
    };

    const onChangeHours = (event) => {
        const valor = event.target.value;
        if (/^\d*$/.test(valor) && valor >= 0 && valor <= 23) {
            if (inputValue) {
                inputValue.setHours(valor);
                onChangeDate(inputValue);
            }
        }
    };

    const onChangeMinutes = (event) => {
        const valor = event.target.value;
        if (/^\d*$/.test(valor) && valor >= 0 && valor <= 59) {
            if (inputValue) {
                inputValue.setMinutes(valor);
                onChangeDate(inputValue);
            }
        }
    };

    const onChangeDateRange = function () {
        let start = '';
        if (dateStart) {
            if (!value.timeSelection) {
                dateStart.setHours(0);
                dateStart.setMinutes(0);
                dateStart.setSeconds(0);
            }
            start = dateStart.getTime();
        }
        //
        let end = '';
        if (dateEnd) {
            if (!value.timeSelection) {
                dateEnd.setHours(23);
                dateEnd.setMinutes(59);
                dateEnd.setSeconds(59);
            }
            end = dateEnd.getTime();
        }

        onChangeDate(start + '|' + end);
    }

    const onChangeDateStart = function (val) {
        setDateStart(val);
        dateStart = val;

        onChangeDateRange();
    }

    const onChangeDateEnd = function (val) {
        setDateEnd(val);
        dateEnd = val;

        onChangeDateRange();
    }

    const delFile = function (file, index) {
        let _list_ = [];


        for (const i in files) {
            if (i != index) {
                _list_.push(files[i]);
            }
        }

        utils.set(element.field, data, _list_);
        utils.set(element.field, changed, _list_);

        setValue(_list_);
        setFiles(_list_);
    }

    const onUpload = function (file, fileData) {
        const cfg = value.upload;
        const name = file.name;
        const extension = file.extension ? file.extension : name.indexOf('.') > 0 ? name.substring(name.lastIndexOf('.') + 1, name.length) : 'none';

        const fs = {};

        fs.preview = false;
        fs.name = name;
        fs.extension = extension;
        fs.type = file.type;
        fs.size = file.size;

        if (!file.extension) {
            file.extension = extension;
        }

        if (typeof cfg === 'object') {
            for (const i in cfg) {
                const v = cfg[i];

                if (file[i]) {
                    if (typeof v === 'object') {
                        for (const vi in v) {
                            const va = v[vi];

                            fs[va] = file[i];
                        }
                    } else {
                        fs[v] = file[i];
                    }
                }
            }
        }

        if (element.multiple) {
            fs.data = fileData;

            let _list_ = eval('data.' + value.field);

            if (!_list_) {
                _list_ = [];
            }

            if (!_list_.push) {
                _list_ = [_list_];
            }

            let dc = {};

            for (const fsi in fs) {
                dc[fsi] = fs[fsi];
            }

            if (element.mapping) {
                for (const pi in element.mapping) {
                    const mi = element.mapping[pi];

                    dc[mi] = fs[pi];
                }
            }

            if (element.params) {
                for (const pi in element.params) {
                    dc[pi] = element.params[pi];
                }
            }

            _list_.push(dc);

            utils.set(element.field, data, _list_);
            utils.set(element.field, changed, _list_);

            setValue(_list_);
            setFiles(_list_);
        } else {
            for (const fi in fs) {
                utils.set(fi, data, fs[fi]);
                utils.set(fi, changed, fs[fi]);
            }
            setValue(fileData);
        }
        itens.push(fs);
    };

    const fileUploadDelete = function (file) {
        console.log(file);
    }


    const onSaveFiles = function (files) {
        fileUploadDrop(files);
        setOpen(false);
    }

    const fileUploadDrop = function (files) {

        for (const i in files) {
            const file = files[i];

            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                onUpload(file, reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }


    const isChecked = function (o) {
        const x = ',' + o + ',';

        if (inputValue.indexOf(x) >= 0) {
            return true;
        }
        return false;
    }

    const handleToggleCheckList = function (o) {
        if (disabled) {
            return;
        }

        const n = ',' + o + ',';
        let v = inputValue ? inputValue : '';

        if (v.indexOf(n) >= 0) {
            v = v.replace(n, ',');
        } else {
            v = v + n;
        }
        v = v.replace(',,', ',');

        setValue(v);
    }

    const onChange = function (event) {
        let _ov_ = null;

        try {
            _ov_ = eval('data.' + value.field);

            if (typeof _ov_ === 'undefined') {
                _ov_ = eval('changed.' + value.field);
            }
        } catch (error) {
        }

        oldValue = _ov_;
        setOldValue(_ov_);

        let v = event.target.value;
        let el = element;
        let prefix = el.prefix ? el.prefix : utils.currencySymbol();

        if (el.numeric) {
            v = utils.onlyNumber(v);
        } else if (el.mask) {
            v = utils.appyMask(v, el.mask);
        } else if (type === 'date' || type === 'datetime') {
            if (type === 'date') {
                v = utils.appyMask(v, '39/19/9999');
            } else if (type === 'datetime') {
                v = utils.appyMask(v, '39/19/9999 29:59');
            }
        }

        setValue(v, event.type);
    }

    const onBlur = function (event) {
        action(value.blur, 'blur');
    }

    const onCheckNulls = function (val) {
        utils.set(field, data, val);
        utils.set(field, changed, val);

        setInputValue(val);
        error = false;
        setError(false);

        action(value.change, 'change');

        refreshParent();
    }

    const onCheck = function (event) {
        const val = event.target.checked;

        utils.set(field, data, val);
        utils.set(field, changed, val);

        setInputValue(val);
        error = false;
        setError(false);

        action(value.change, 'change');
        if (value.isRefreshParent === false) {
            view.refresh();
        } else {
            refreshParent();
        }
    }

    const action = function (cfg, event) {
        let changed = crud ? crud.changed : {};

        if (!cfg) {
            return;
        }
        if (cfg.requestWhen) {
            let exprReq = true;
            try {
                exprReq = !eval(cfg.requestWhen)
            } catch (e) {
                console.log("REQUEST WHEN:");
                console.log(e);
            }
            if (exprReq) {
                return;
            }
        }
        const act = cfg.action;
        const doUpdate = function (res) {
            if (cfg.update) {
                for (const i in cfg.update) {
                    let v = cfg.update[i];

                    if (crud.elements[v]) {
                        crud.elements[v].refresh();
                    }
                }
            }
        };

        console.log(element.disabled)

        let res = { data: data };

        if (typeof act === 'function') {
            act.call(this, value, changed, crud);
            doUpdate(res);
        } else if (crud[act]) {
            crud[act].call(this, value, changed, crud);
            doUpdate(res);
        } else if (crud.owner && crud.owner[act]) {
            crud.owner[act].call(this, value, changed, crud);
            doUpdate(res);
        } else if (typeof act === 'string' && (act.indexOf('function') > -1 || act.indexOf(') =>') > -1)) {
            try {
                eval(act);
                view.refresh();
            } catch (e) {
                console.log("***********");
                console.log("ACTION CONTENT: "+act);
                console.log("ERROR: "+e);
                console.log("***********");
            }
        } else {
            const complete = function (res) {
                setLoading(false);

                if (res.error && cfg.rollback) {
                    setFieldValue(oldValue);
                }

                if (!res.error) {
                    doUpdate(res);
                    const cmpt = cfg.complete;

                    if (typeof cmpt === 'function') {
                        cmpt.call(this, res);
                    } else if (crud[cmpt]) {
                        crud[cmpt].call(this, res);
                    }

                    const _data_ = res.data;
                    const target = cfg.target;

                    if (_data_) {
                        if (target) {
                            utils.set(target, crud.data, _data_, true);
                            utils.set(target, crud.changed, _data_, true);
                        } else {
                            for (const k in _data_) {
                                const v = _data_[k];
                                const e = crud.elements[k];

                                if (e && e.field !== field) {
                                    e.setValue(v);
                                }
                                data[k] = v;
                            }
                        }
                        refreshParent();
                    }

                    if ((cfg.back || cfg.close) && crud.close) {
                        crud.close();
                    }
                }
            };

            const onerror = function (res) {
                setLoading(false);
            };
            setLoading(true);

            const _call_ = {};

            if (event === 'change' || event === 'blur' || event === 'enter') {
                const cv = {};

                for (const ci in changed) {
                    cv[ci] = changed[ci];
                }

                for (const ci in local) {
                    cv[ci] = local[ci];
                }
                _call_.changed = cv;
            } else {
                _call_.changed = changed;
            }

            _call_.event = event;
            _call_.element = element;
            _call_.cfg = cfg;
            _call_.complete = complete;
            _call_.onerror = onerror;
            _call_.data = data;
            _call_.crud = crud;
            _call_.view = view;
            _call_.alert = true;
            _call_.validate = event === 'click';

            if (typeof cfg.validate != 'undefined') {
                _call_.validate = cfg.validate;
            }

            utils.call(_call_);
        }
    }

    const execute = function (click) {
        const args = {};

        args.data = data;
        args.parent = parent;

        if (onClick) {
            onClick(click, args);
        } else if (typeof click === 'function') {
            click.call(this, click, args);
        } else if (typeof click === 'object') {
            const ac = click.action;

            if (crud[ac]) {
                crud[ac].call(this, value, args);
            } else if (view[ac]) {
                view[ac].call(this, value, args);
            } else {
                action(click, 'click');
            }
        }
    };

    value.execute = execute;
    element.execute = execute;

    const _onClick_ = function (event) {
        const click = value.click;

        execute(click);
    }

    const getFileAddedMessage = function (e) {
        return element.successMessage ? element.successMessage : "Arquivo " + e + " adicionado com sucesso.";
    }

    const itemClick = function (event) {
        let skip = '|link|button|list|grid|'.indexOf(element.type) < 0;

        if (column) {
            //quem dispara o click é a linha
        } else if (type === 'item') {
            crud.setTab(element.index);
        } else if (value.click && skip) {
            _onClick_(null);
        }
    }

    if (!crud.elements) {
        crud.elements = {};
    }

    if (isInput || value.allowRefresh || type === 'checklist' || type === 'checkbox' || type === 'select' || type === 'entity' || type === 'date' || type === 'datetime' || type === 'time') {
        crud.elements[name] = value;
    }


    if (!clientId) {
        clientId = id ? id : element.id;
    }

    element.isRequired = function () {
        const el = document.getElementById(clientId);

        if (el == null || el.offsetParent === null) {
            return false;
        }
        let obrigatorio = required;

        if (value.elRequired) {
            let rr = utils.decode({ required: value.elRequired }, crud, data);

            obrigatorio = rr.required !== false;
        }

        element.clear();

        if (!obrigatorio) {
            return false;
        }

        if (disabled === true) {
            return false;
        }

        if (typeof element.rendered === 'string') {
            return false;
        }

        if (element.rendered === false) {
            return false;
        }

        if (type === 'checkbox' && obrigatorio) {
            if (element.nullValues && (inputValue === true || inputValue == false)) {
                //faz nda
            } else if (!inputValue) {
                setError(true);
                return true;
            }
        }

        if (typeof inputValue === 'number' || typeof inputValue === 'boolean') {
            return false;
        }

        error = obrigatorio && disabled !== true && !inputValue;
        setError(error);
        return error;
    }

    value.isRequired = element.isRequired;
    value.clientId = clientId;


    if (type == 'select' && element.list && !readOnly) {

        if (!ready) {
            setReady(true);

            itens = [];
            itens.push({ label: '', value: '' });

            const _call_ = {};

            _call_.element = value;
            _call_.cfg = value.list;
            _call_.complete = function (res) {
                itens = [];
                itens.push({ label: '', value: '' });

                if (res.data) {
                    for (const i in res.data) {
                        const v = res.data[i];

                        let itemLabel = element.itemLabel ? element.itemLabel : 'descricao';
                        let itemValue = element.itemValue;
                        let val = '';

                        if (!itemValue) {
                            val = v.constante ? v.constante : v.id;
                        } else {
                            val = v[itemValue];
                        }

                        v.label = v[itemLabel];
                        v.value = val + '';

                        itens.push(v);
                    }

                    setItens(itens);
                }
            };
            _call_.data = data;
            _call_.changed = crud.changed;
            _call_.size = -1;
            _call_.crud = crud;
            _call_.view = view;


            utils.call(_call_);
        }
    }

    if (!style) {
        style = {};
    }

    if (element.background) {
        style.background = element.background;
    }

    const spinnerAdd = function (event) {
        const v = parseInt(inputValue) + 1;

        onChange({ target: { value: v } });

        return true;
    }

    const spinnerMod = function (event) {
        onChange({ target: { value: event.target.value } });

    }

    const spinnerDel = function () {
        const v = parseInt(inputValue) - 1;

        onChange({ target: { value: v } });

        return false;
    }

    const timeToDate = function (timeValue) {
        let d = null;
        if (timeValue) {
            d = new Date();
            d.setTime(timeValue);
        }
        return d;
    }

    if (type === 'icon' && element.icon && !inputValue) {
        inputValue = element.icon;
    }

    if (isInput) {
        showLabel = false;
    }

    if (readOnly) {
        isInput = false;

        if ('|action|'.indexOf('|' + type + '|') >= 0 && column) {
            showLabel = true;
        } else if ('|file|link|upload|'.indexOf('|' + type + '|') >= 0) {
            if (inputValue && inputValue.indexOf('data:image') >= 0) {
                type = 'img';
            } else {
                type = 'link';
            }
            showLabel = false;
        } else if ('|icon|include|button|link|file|avatar|img|'.indexOf('|' + type + '|') < 0) {
            type = 'span';
            showLabel = false;
        } else if (element.upload) {
            type = 'link';
        }

        if (type === 'link') {
            value.click = false;
            element.click = false;
        }
    }

    if ('|file|link|upload|'.indexOf('|' + type + '|') >= 0 && element.preview !== false) {
        const prev = inputValue + '';

        if (prev.indexOf('.png') > 0 || prev.indexOf('.jpg') > 0 || prev.indexOf('.jpeg') > 0) {
            preview = prev;
        }
    }

    if ('|complete|icon|button|link|select|date|datetime|entity|spinner|spinner2|checkbox|crud|'.indexOf('|' + type + '|') >= 0) {
        showLabel = false;
    } else if (element.showLabel === false) {
        showLabel = false;
    }

    if (typeof element.value !== 'undefined') {
        inputValue = _value_;
    } else if (readOnly || element.readOnly) {
        const rtype = element.type;

        if (inputValue !== null && typeof inputValue === 'object' && inputValue.getYear) {
            let dtype = rtype === 'date' || format === 'date' ? 'date' : 'datetime';

            inputValue = inputValue.getDateString(dtype);
        } else {
            inputValue = utils.resolve(element, data);
        }
    }

    let _href_ = href ? href : inputValue;

    if (!_href_) {
        _href_ = 'javascript:void(0)';
    } else if (typeof _href_ === 'string' && element.googlePreview) {
        if (_href_.indexOf('.pdf') > 0 || _href_.indexOf('.doc') > 0 || _href_.indexOf('.xls') > 0) {
            _href_ = 'https://docs.google.com/viewerng/viewer?url=' + _href_;
        }
    }

    if (type === 'link' || type === 'button') {
        if (_value_) {
            _value_ = utils.lng(name, _value_);
        }
        if (inputValue) {
            inputValue = utils.lng(name, inputValue);
        }
    }


    if (typeof inputValue === 'undefined') {
        inputValue = '';
    }

    if (value.filter) {
        const df = value.default;

        if (df) {
            if (value.type === 'date' || value.type === 'datetime') {
                if (dateStart === null) {
                    dateStart = df.start ? df.start : null;
                    if (dateStart === null && crud.changed[value.name] && crud.changed[value.name].split("|")[0]) {
                        dateStart = timeToDate(crud.changed[value.name].split("|")[0]);
                    }
                }

                if (dateEnd === null) {
                    dateEnd = df.end ? df.end : null;
                    if (dateEnd === null && crud.changed[value.name] && crud.changed[value.name].split("|")[1]) {
                        dateEnd = timeToDate(crud.changed[value.name].split("|")[1]);
                    }
                }
            } else {
                inputValue = df;
            }
        }
    }

    if (name === 'avatar' || type === 'avatar') {
        value.acceptedFiles = ['image/png', 'image/jpeg', 'application/pdf'];
        element.acceptedFiles = ['image/png', 'image/jpeg', 'application/pdf'];
    }

    if (typeof inputValue === 'string') {
        let spl = inputValue.split('\n');

        if (spl.length > rows) {
            rows = spl.length;
        }
    }


    if (element.icon) {
        className += ' ui-button-icon ';
    }

    if (type === 'button') {
        if (label) {
            className += ' ui-button-label';
        } else if (element.icon) {
            className += ' ui-icon-only';
        }
    }

    let buttonLabel = element.buttonLabel;

    if (!buttonLabel && (type === 'avatar' || name === 'avatar')) {
        buttonLabel = utils.lng('label.avatar', utils.lng('selecione_foto_perfil', 'Selecione foto perfil'));
    }

    if (typeof inputValue === 'string' && inputValue.indexOf('${') > 0 && column) {
        let ive = utils.decode({ value: inputValue }, crud, data);

        inputValue = ive.value;
    }

    if (element.mask) {
        inputValue = utils.appyMask(inputValue, element.mask);
    }

    if (staticClassName != null) {
        className = staticClassName;
    }

    const resize = function () {
        mobile = window.innerWidth <= 990;
        setMobile(mobile);
        window.mobile = mobile;
    }


    const printHtml = function () {
        utils.print(clientId);
    };

    element.printHtml = printHtml;

    if (crud.readOnly && isInput) {
        disabled = true;
    }

    const isShowLabel = function () {
        if (value.actions) {
            return true;
        }
        return showLabel && !value.dialog && label;
    }

    const isShowElement = function () {
        if (type === 'link' && !element.click && window.innerWidth < 1000) {
            if (_href_ && _href_.indexOf('http') > 0) {
                //return false; 
            }
        }
        return value && !hide;
    }

    const isDisableDel = () => {
        return disableOnLimit === true && min && parseInt(inputValue) === parseInt(min);
    }

    const isDisableAdd = () => {
        return disableOnLimit === true && max && parseInt(inputValue) === parseInt(max);
    }

    return (
        <>
            {isShowElement() &&
                <div id={clientId} style={value.rootStyle} className={className} onClick={itemClick} >
                    {element.loading &&
                        <div className="ui-loading">
                            Carregando
                        </div>
                    }
                    {isShowLabel() &&
                        <div className="ui-el-label">
                            {label &&
                                <span dangerouslySetInnerHTML={{ __html: label }}></span>
                            }
                            {value.actions &&
                                <div className="ui-el-item-actions">
                                    {Object.entries(value.actions).map((el, index) => (
                                        <Element as={type} parent={value} onClick={onClick} view={view} crud={crud} name={el[0]}
                                            clientId={id + '_' + el[0] + '_' + index} value={el[1]} disabled={disabled} readOnly={readOnly}
                                            key={id + '_' + el[0] + '_' + index} value={el[1]} index={index} data={data} changed={changed} />
                                    ))}
                                </div>
                            }
                        </div>
                    }

                    <div className="ui-el-item-inner" style={type !== 'button' ? style : {}}>
                        {(type === 'youtube' && inputValue) &&
                            <iframe width="560" height="315" src={inputValue} frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                            </iframe>

                        }
                        {type === 'span' && inputValue &&
                            <>
                                {label &&
                                    <p className="mobile-Label-m">{label}</p>
                                }

                                <span dangerouslySetInnerHTML={{ __html: inputValue }}>
                                </span>
                            </>
                        }
                        {type === 'include' &&
                            <>
                                <span dangerouslySetInnerHTML={{ __html: template }}>
                                </span>
                            </>
                        }
                        {(type === 'view' && customView) &&
                            <>
                                <Element parent={value} type="custom" value={customView} crud={customCrud} view={view} ></Element>
                            </>
                        }
                        {value.elements && type !== 'list' &&
                            <>
                                {Object.entries(value.elements).map((el, index) => (
                                    <Element as={type} parent={value} onClick={onClick} view={view} crud={crud} name={el[0]}
                                        clientId={id + '_' + el[0] + '_' + index} value={el[1]} disabled={disabled} readOnly={readOnly}
                                        key={id + '_' + el[0] + '_' + index} value={el[1]} index={index} data={data} changed={changed} />
                                ))}
                            </>
                        }

                        {!value.elements &&
                            <>
                                {!isInput &&
                                    <>
                                        {type === 'editor' &&
                                            <>
                                                {!disabled &&
                                                    <JoditEditor
                                                        value={inputValue}
                                                        onChange={(value) => {
                                                            onChange({ target: { value: value } });
                                                        }}
                                                        label={label}
                                                    />
                                                }
                                                {disabled &&
                                                    <span dangerouslySetInnerHTML={{ __html: inputValue }}>
                                                    </span>
                                                }
                                            </>
                                        }
                                        {type === 'select' &&
                                            <FormControl variant={variant} label={label} >
                                                <InputLabel >{label}</InputLabel>
                                                <Select native autoComplete="new-password" defaultValue='' variant={variant} required={required} select label={label} disabled={disabled} value={inputValue} onChange={onChange} >
                                                    {itens.map((i, io) => (
                                                        <option key={i.value} value={i.value} index={io} >{i.label}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        }
                                        {type === 'checkbox' &&
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={
                                                    <>
                                                        {element.nullValues &&
                                                            <>
                                                                <div className="ui-checkbox-no">
                                                                    <Checkbox label={label} disabled={disabled} checked={checkValue === false} onChange={() => onCheckNulls(false)} > </Checkbox>
                                                                    <span>{utils.lng('nao', 'Não')}</span>
                                                                </div>
                                                                <div className="ui-checkbox-yes">
                                                                    <Checkbox label={label} disabled={disabled} checked={checkValue === true} onClick={() => onCheckNulls(true)} > </Checkbox>
                                                                    <span>{utils.lng('sim', 'Sim')}</span>
                                                                </div>
                                                            </>
                                                        }
                                                        {!element.nullValues &&
                                                            <Checkbox label={label} disabled={disabled} checked={checkValue} onChange={onCheck} > </Checkbox>
                                                        }
                                                    </>
                                                }
                                                label={label} />
                                        }
                                        {type === 'checklist' && element.data &&
                                            <List>
                                                {itens.map((li, ib) => (
                                                    <ListItem key={'li' + ib} onClick={() => {
                                                        handleToggleCheckList(li.value)
                                                    }}>
                                                        <i className={'far fa-' + (isChecked(li.value) ? 'check-circle' : 'circle')}></i>
                                                        <span style={{ paddingLeft: '15px' }}>
                                                            {li.label}
                                                        </span>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        }
                                        {type === 'spinner' &&
                                            <>
                                                <p className="mobile-Label-m">{label}</p>
                                                <div className="ui-spinner">
                                                    {loading &&
                                                        <div className="ui-spinner-loading">
                                                            <i className="fas fas fa-circle-notch fa-spin"></i>
                                                        </div>
                                                    }
                                                    {!loading &&
                                                        <>
                                                            {!disabled &&
                                                                <i className="fa fa-angle-left" onClick={spinnerDel}></i>
                                                            }
                                                            <input autoComplete="new-password" disabled={disabled} className="ui-spinner-value" value={inputValue} onChange={spinnerMod} onBlur={onBlur} onBlur={onChange} type="text"></input>
                                                            {!disabled &&
                                                                <i className="fa fa-angle-right" onClick={spinnerAdd}></i>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {type === 'spinner2' &&
                                            <>
                                                <p className="mobile-Label-m">{label}</p>
                                                <div className="ui-spinner qtd-classe">
                                                    <Button className="add-spinn-classe-e" onClick={spinnerDel} disabled={isDisableDel()}><i className="fas fa-angle-left"></i></Button>

                                                    <label autoComplete="new-password" disabled={disabled} className="ui-spinner-value label-qtd" value={inputValue} onBlur={onBlur} onChange={onChange} type="text">{inputValue}</label>

                                                    <Button className="add-spinn-classe-d" onClick={spinnerAdd} disabled={isDisableAdd()}><i className="fas fa-angle-right"></i></Button>
                                                </div>
                                            </>
                                        }
                                        {(type === 'date' || type === 'datetime') &&
                                            <>
                                                {!value.range &&
                                                    <>
                                                        {value.timeSelection &&
                                                            <>
                                                                <div style={{ width: "100%", display: "inline" }}>
                                                                    <div style={{ width: "50%", display: "inline" }}>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                            <KeyboardDatePicker
                                                                                disableToolbar
                                                                                inputVariant={variant}
                                                                                required={required}
                                                                                format="dd/MM/yyyy"
                                                                                label={label} disabled={disabled} value={inputValue} onChange={onChangeDate}
                                                                                style={{ width: "50%" }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                    <div style={{ width: "50%", display: "inline" }}>
                                                                        <div className="ui-el-input" style={{ display: "inline" }}>
                                                                            <span className="ui-grid-col"> - </span>
                                                                            <TextField variant={variant} required={required} label={utils.lng('horas', 'Horas')}
                                                                                disabled={disabled || !inputValue} value={inputValueHours}
                                                                                onChange={onChangeHours} type={"number"}
                                                                                maxLength={2}
                                                                                style={{ width: '9ch' }}
                                                                                inputProps={{ min: 0, max: 23 }}
                                                                            ></TextField>
                                                                        </div>
                                                                        <div className="ui-el-input" style={{ display: "inline" }}>
                                                                            <span className="ui-grid-col"> : </span>
                                                                            <TextField variant={variant} required={required} label={utils.lng('minutos', 'Minutos')}
                                                                                disabled={disabled || !inputValue} value={inputValueMinutes}
                                                                                onChange={onChangeMinutes} type={"number"}
                                                                                maxLength={2}
                                                                                style={{ width: '10ch' }}
                                                                                inputProps={{ min: 0, max: 59 }}
                                                                            ></TextField>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {!value.timeSelection &&
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                <KeyboardDatePicker
                                                                    disableToolbar
                                                                    inputVariant={variant}
                                                                    required={required}
                                                                    format="dd/MM/yyyy"
                                                                    label={label} disabled={disabled} value={inputValue} onChange={onChangeDate}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        }
                                                    </>
                                                }
                                                {value.range &&
                                                    <table className="ui-date-range">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <div className="ui-range-label">
                                                                        {label}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            inputVariant={variant}
                                                                            required={required}
                                                                            format="dd/MM/yyyy"
                                                                            label={labelStart} disabled={disabled} value={dateStart} onChange={onChangeDateStart}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </td>
                                                                <td>

                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                                        <KeyboardDatePicker
                                                                            disableToolbar
                                                                            inputVariant={variant}
                                                                            required={required}
                                                                            format="dd/MM/yyyy"
                                                                            label={labelEnd} disabled={disabled} value={dateEnd} onChange={onChangeDateEnd}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {isInput &&
                                    <div className="ui-el-input">
                                        {type === 'money' &&
                                            <>
                                                <CurrencyTextField autoComplete="new-password"
                                                    defaultValue='' variant={variant}
                                                    currencySymbol={currencySymbol}
                                                    decimalCharacter={decimalCharacter}
                                                    digitGroupSeparator={digitGroupSeparator}
                                                    required={required} label={label} disabled={disabled} value={inputValue}
                                                    onChange={(event, vl) => onChange({ target: { value: vl } })} >
                                                </CurrencyTextField>
                                            </>
                                        }
                                        {type !== 'money' &&
                                            <>
                                                <TextField autoComplete="new-password" variant={variant} required={required} label={label}
                                                    disabled={disabled} multiline={multiline} value={inputValue} onKeyPress={keyPressed}
                                                    onChange={onChange} onBlur={onBlur} type={type} rows={rows}
                                                >
                                                </TextField>
                                            </>
                                        }
                                    </div>
                                }

                                {(type === 'file' || type === 'avatar' || element.upload) &&
                                    <>
                                        {!preview && type === 'avatar' && inputValue &&
                                            <>
                                                {element.typeStyle === 'CardProduct' &&
                                                    <div className="ui-el-avatar-hover">
                                                        {element.primeiro_avatar === element.segundo_avatar &&
                                                            <img className="ui-el-avatar" src={inputValue}></img>
                                                        }
                                                        {element.primeiro_avatar !== element.segundo_avatar &&
                                                            <>
                                                                <img className="ui-el-avatar primeiroAvatar" src={element.primeiro_avatar}></img>
                                                                <img className="ui-el-avatar segundoAvatar" src={element.segundo_avatar}></img>
                                                            </>
                                                        }

                                                    </div>
                                                }
                                                {!element.typeStyle &&
                                                    <img className="ui-el-avatar" src={inputValue}></img>
                                                }

                                            </>
                                        }
                                        {(element.upload && type !== 'list') &&
                                            <>
                                                {element.dialog &&
                                                    <>
                                                        <Button disabled={disabled} variant="outlined" color="primary" onClick={() => setOpen(true)}>
                                                            {buttonLabel ? buttonLabel : label}
                                                        </Button>
                                                        <DropzoneDialog
                                                            cancelButtonText={utils.lng('cancelar', 'Cancelar')}
                                                            submitButtonText={utils.lng('enviar', 'Enviar')}
                                                            dropzoneText={title}
                                                            className="ui-dlg-upload"
                                                            filesLimit={element.filesLimit ? element.filesLimit : 1}
                                                            dialogTitle={buttonLabel ? buttonLabel : label}
                                                            getFileAddedMessage={getFileAddedMessage}
                                                            acceptedFiles={value.acceptedFiles ? value.acceptedFiles : ['image/png', 'image/jpeg', 'application/pdf']}
                                                            maxFileSize={value.maxFileSize ? value.maxFileSize : 100000000}
                                                            open={open}
                                                            onClose={() => setOpen(false)}
                                                            onSave={(files) => {
                                                                onSaveFiles(files);
                                                            }}
                                                        />
                                                        <table className="ui-files-preview">
                                                            {files.map((i, io) => (
                                                                <tr>
                                                                    <td className="ui-files-prev">
                                                                        {'|jpg|png|gif|jpeg|'.indexOf(i.extension) > 0 &&
                                                                            <img src={i.data}></img>
                                                                        }
                                                                        {!('|jpg|png|gif|jpeg|'.indexOf(i.extension) > 0) &&
                                                                            <i className="fa fa-file"></i>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {i.name}
                                                                    </td>
                                                                    <td className="ui-del-file" onClick={() => delFile(i, io)}>
                                                                        <i className="fa fa-times"></i>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </table>
                                                    </>
                                                }
                                                {(!element.dialog && !disabled) &&
                                                    <>
                                                        {inputValue &&
                                                            <>
                                                                <div className="ui-el-input" style={{ width: '100%', display: 'block', textAlign: 'right' }}>
                                                                    <>
                                                                        <span style={{ margin: '30px' }}>{utils.lng('visualizar', 'Visualizar') + ' ' + label}</span>
                                                                        {element.icon &&
                                                                            <i className={element.icon} onClick={() => {
                                                                                window.open(inputValue ? inputValue : _value_)
                                                                            }}></i>
                                                                        }
                                                                        {preview &&
                                                                            <img src={preview} className="ui-img-preview"></img>
                                                                        }
                                                                        {!preview &&
                                                                            <>
                                                                                {!element.href && !element.icon &&
                                                                                    <i className="fa fa-download fa-5x" onClick={() => {
                                                                                        window.open(inputValue ? inputValue : _value_)
                                                                                    }}></i>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                </div>
                                                            </>
                                                        }
                                                        <div style={{ marginTop: '20px' }}>
                                                            <DropzoneArea
                                                                cancelButtonText={utils.lng('cancelar', 'Cancelar')}
                                                                submitButtonText={utils.lng('enviar', 'Enviar')}
                                                                dropzoneText={helpFile}
                                                                className="ui-dlg-upload"
                                                                filesLimit={element.filesLimit ? element.filesLimit : 1}
                                                                dialogTitle={label}
                                                                getFileAddedMessage={getFileAddedMessage}
                                                                onChange={fileUploadDrop}
                                                                onDelete={fileUploadDelete}
                                                                acceptedFiles={value.acceptedFiles ? value.acceptedFiles : ['image/png', 'image/jpeg', 'application/pdf']}
                                                                maxFileSize={value.maxFileSize ? value.maxFileSize : 100000000}
                                                                open={open}
                                                                onClose={() => setOpen(false)}
                                                                onSave={(files) => {
                                                                    onSaveFiles(files);
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                        {(preview && element.preview !== false) &&
                                            <a className="ui-link-preview" href={preview} target="_blank">
                                                {type === 'avatar' &&
                                                    <img className="ui-el-avatar" src={preview}></img>
                                                }
                                                {type !== 'avatar' &&
                                                    <img className="ui-el-preview" src={preview}></img>
                                                }
                                            </a>
                                        }
                                    </>
                                }
                                {type === 'button' &&
                                    <>
                                        {!loading &&
                                            <Button style={style} disabled={disabled}
                                                onClick={_onClick_} variant={variant} color={buttonTheme} type="button">
                                                {element.icon &&
                                                    <i className={element.icon}></i>
                                                }
                                                {label}
                                            </Button>
                                        }
                                        {loading &&
                                            <Button style={style} disabled={true} className={label ? 'ui-loading-button' : ''} variant={variant} color={buttonTheme} type="button">
                                                <i className="fas fas fa-circle-notch fa-spin"></i> {label}
                                            </Button>
                                        }
                                    </>
                                }
                                {type === 'link' &&
                                    <>
                                        {!element.click &&
                                            <a href={_href_} target={target}>
                                                {element.icon &&
                                                    <i className={element.icon}></i>
                                                }
                                                {preview &&
                                                    <img src={preview} className="ui-img-preview"></img>
                                                }
                                                {!preview &&
                                                    <>
                                                        {!element.href && !element.icon &&
                                                            <i className="fa fa-download"></i>
                                                        }
                                                        {element.href &&
                                                            <>
                                                                {inputValue ? inputValue : _value_}
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </a>
                                        }
                                        {element.click &&
                                            <a href="javascript:void(0)" onClick={_onClick_}>
                                                {element.icon &&
                                                    <i className={element.icon}></i>
                                                }
                                                {preview &&
                                                    <img src={preview} className="ui-img-preview"></img>
                                                }
                                                {!preview &&
                                                    <>
                                                        {inputValue ? inputValue : _value_}
                                                    </>
                                                }
                                            </a>
                                        }
                                    </>
                                }

                                {type === 'icon' && (inputValue || element.icon) &&
                                    <i className={inputValue ? inputValue : element.icon}></i>
                                }
                                {type !== 'icon' && type !== 'link' && type !== 'button' && element.icon &&
                                    <i className={element.icon}></i>
                                }
                            </>
                        }
                        {type === 'list' &&
                            <>
                                {!element.table &&
                                    <Grid parentQuery={parentQuery} key={clientId + '_key'} id={clientId + '_gird'} disabled={disabled} view={view} crud={crud} value={value} data={data} changed={changed} onClick={onClick} user={user}></Grid>
                                }
                                {element.table &&
                                    <Table parentQuery={parentQuery} key={clientId + '_key'} id={clientId + '_gird'} disabled={disabled} view={view} crud={crud} value={value} data={data} changed={changed} onClick={onClick} user={user}></Table>
                                }
                            </>
                        }

                        {type === 'img' && inputValue &&
                            <div style={{ width: width }}>
                                <img style={value.elementStyle} src={inputValue} className={className}></img>
                            </div>
                        }

                        {type === 'entity' &&
                            <Entity disabled={disabled} view={view} crud={crud} value={value} required={required} data={data} changed={changed} ></Entity>
                        }

                        {type === 'complete' &&
                            <Complete disabled={disabled} view={view} crud={crud} value={value} required={required} data={data} changed={changed} ></Complete>
                        }
                        {popup &&
                            <div className="ui-el-options">
                                xxx
                            </div>
                        }
                        {element.help &&
                            <div className="ui-el-help">
                                {element.help}
                            </div>
                        }
                    </div>
                    {value.bottom &&
                        <div className="ui-el-item-bottom">
                            {Object.entries(value.bottom).map((el, index) => (
                                <Element as={type} parent={value} onClick={onClick} view={view} crud={crud} name={el[0]}
                                    clientId={id + '_' + el[0] + '_' + index} value={el[1]} disabled={disabled} readOnly={readOnly}
                                    key={id + '_' + el[0] + '_' + index} value={el[1]} index={index} data={data} changed={changed} />
                            ))}
                        </div>
                    }
                </div>
            }
        </>
    )
};

export default Element
