import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Element from './element';
import Button from '@material-ui/core/Button';

function Rankings() {
  
  let [listaCfgRanking, setListaCfgRanking] = useState(false);
  let [ranking, setRanking] = useState(false);
  let [informacoesRanking, setInformacoesRanking] = useState('');
  let [openDialogInformacao, setOpenDialogInformacao] = useState(false);
  let [view, setView] = useState({});
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({data: {}, changed: {} });
  let user = utils.getUser();

  view.refresh = function () {
    setIndex(index + 1);
  }

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.openRanking = function (el, args) {
    const cfgRnk = args.data;
    const t = {};

    t.view = '/report/relatorio_ranking_distribuidores/'+cfgRnk.id;
    t.path = '/report/relatorio_ranking_distribuidores/'+cfgRnk.id;

    utils.open(t);
  };

  crud.openDialog = function (el, args) {
    const cfgRnk = args.data;
    if (cfgRnk) {
      setInformacoesRanking(cfgRnk.informacao);
      setOpenDialogInformacao(true);
    }
  };

  const handleCloseDialog = function () {
    window.location.reload();
    setTimeout('setInformacoesRanking("")', 1000);
  };

  const configuracoes = {
    elements: {
      content: {
        styleClass: 'ui-lista-rankings',
        elements: {
          lista_cfg_ranking: {
            name: 'lista_rankings',
            header: false,
            layout: 'clear',
            add: false,
            type: 'list',
            list: {
              url: 'ConfiguracaoRanking?ativo=true&lazy=true'
            },
            columns: {
              card: {
                type: 'card',
                style: { height: '100px' },
                rowEdit: true,
                elements: {
                  img: { 
                    field: 'banner', 
                    type: 'img', 
                    space: 2,
                    width: '200px',
                    styleClass: 'ranking-list-banner',
                    click: {
                      action: 'openRanking'
                    }
                  },
                  detalhes: {
                    style: { fontSize: '30px' , marginLeft: '20px'},
                    space: 8,
                    type: 'span', 
                    field: 'titulo',
                    click: {
                      action: 'openRanking'
                    },
                    elements: {
                      descricao: { field: 'descricao', value: '${@this.descricao}', style: { fontSize: '14px' } }
                    }
                  },
                  sobre_o_ranking: {
                    style: { fontSize: '20px' , marginLeft: '20px', color: 'black', verticalAlign: 'middle'},
                    space: 2,
                    type: 'link', 
                    value: 'Sobre o Ranking',
                    click: {
                      action: 'openDialog'
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  window.crud = crud;

  return (
    <View name="cfg-ranking" view={view} >
      <div className="ui-rankings">
        <Element value={configuracoes} crud={crud} data={crud.data} view={view} ></Element>
        
        <Dialog maxWidth="lg" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialogInformacao}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {utils.lng('sobre_o_ranking', 'Sobre o Ranking')}
          </DialogTitle>
          <DialogContent>
            <div className="ui-element-span" dangerouslySetInnerHTML={{ __html: informacoesRanking }}></div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('fechar', 'FECHAR')}
            </Button>
          </DialogActions>
        </Dialog>
        
      </div>
    </View >
  );
}

export default Rankings;