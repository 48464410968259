import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from './utils';


function Swagger() {
    const [crud, setCrud] = useState({ changed: {}, data: {} });
    const [app, setApp] = useState({});
    const [view, setView] = useState({});

    const menu = {
        type: 'list',
        rowCols: 3,
        rows: 100,
        layout: 'card',
        styleClass: 'ui-main-menu',
        header: false,
        controls: false,
        add: false,
        feedback: false,
        columns: {
            label: {}
        },
        list: {
            url: 'view/menus'
        }
    };

    const path = window.location.pathname;

    const split = path.split('/');

    console.log(split.length)

    if (split.length >= 3) {
        view.id_processo = split[2];
    }

    const rowClick = function (cfg, args) {
        const b = args.data;

        delete b.id;

        utils.open(b);
    }


    return (
        <View view={view} >
            <Element crud={crud} view={view} value={menu} onClick={rowClick}></Element>
        </View>
    );
}

export default Swagger;