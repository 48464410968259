import React, { useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import View from './view';
import utils from './utils';
import Chart from './chart';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Element from './element';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ptLocale from "date-fns/locale/pt-BR";

function Dashboard() {
  const [view, setView] = useState({});
  const [comissao, setComissao] = useState({});
  const [data, setData] = useState({});
  const [resumo, setResumo] = useState({
    //cabeçalho
    qualificado: false,
    total_pontos: 0,
    valor_creditos: utils.currencySymbol()+' 0,00',
    graduacao: utils.lng('sem_graduacao','Sem graduação'),
    //quadro 1
    ponto_pessoal_acumulado: 0,
    ponto_rede_acumulado: 0,
    situacao: utils.lng('indefinido','Indefinido'),
    total_afiliados: 0,
    visualizar_spv_apurado: false
  });
  const [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  const [value, setValue] = useState({});
  const [ready, setReady] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogComissao, setOpenDialogComissao] = useState(false);
  const [index, setIndex] = useState(0);
  const [showSpv, setshowSpv] = useState(false);
  const [showSpv2, setshowSpv2] = useState(false);

  view.refresh = function () {
    setIndex(index + 1);
  }

  if (!ready) {
    setReady(true);

    let agora = new Date();
    let dataUtils = new DateFnsUtils(ptLocale);
    let dataInicial = dataUtils.startOfMonth(agora);
    let dataFinal = dataUtils.endOfMonth(agora);

    crud.changed.date_start = dataInicial.getTime();
    crud.changed.date_end = dataFinal.getTime();

    crud.selected.date_start = dataInicial.getTime();
    crud.selected.date_end = dataFinal.getTime();

    crud.data.date_start = dataInicial.getTime();
    crud.data.date_end = dataFinal.getTime();
    crud.sendChanged = true;

    resumo.imagem_graduacao = "https://cdn.conecteme.com.vc/files/utilizador_0000000001/58cfdfd769723568f812697fdba76923.png";

    utils.execute(
      crud, "dashboard_encontrarResumoDashboard?count=false", null,
      function (res) {
        if (res.data) {
          const a = {};

          for (const i in resumo) {
            a[i] = resumo[i];
          }

          for (const i in res.data) {
            const v = res.data[i];

            for (const vi in v) {
              a[vi] = v[vi];
            }
            setResumo(a);
          }
        }
      }
    );
  }

  let filtros = {
    dashboard_listarMeusPontos: "MEUS PONTOS",
    dashboard_listarPontosTime: "PONTOS EQUIPE",
    dashboard_listarRankingTime: "RANKING EQUIPE",
    dashboard_listarPontosNovosMembros: "NOVOS MEMBROS"
  };

  let periodos = resumo.visualizar_spv_apurado ? {
    mes_atual: "mês atual",
    mes_anterior: "mês anterior",
    trimestre: "trimestre",
    outros: "outros"
  } : {
    mes_atual: "mês atual"
  };

  let comissoes = {
    dashboard_listarComissaoGeral: "GERAL",
    dashboard_listarComissaoCredito: "CRÉDITO",
    dashboard_listarComissaoDebito: "DEBITO",
    dashboard_listarComissaoSPVPago: "SPV PAGO"
  };

  let eventos = {
    mes_atual: "Mês atual",
    outros: "Outros"
  };

  let gridComissao = {
    type: 'list',
    header: false,
    controls: false,
    search: false,
    list: {
      url: 'dashboard_listarComissaoGeral'
    },
    columns: {
      descricao: {},
      valor: { bold: true, format: 'money', style: { float: 'right' } },
      ver: {
        type: 'button',
        label: 'ver',
        click: {
          action: 'detalheComissao'
        }
      }
    }
  };

  let gridDetalheComissao = {
    type: 'list',
    add: false,
    rows: 5,
    list: {
      url: 'dashboard_listarComissaoDetalhePedidoPorCategoria'
    },
    columns: {
      numero_pedido: { label: utils.lng('pedido','Pedido') },
      data_movto: { label: utils.lng('data','Data'), type: 'date' },
      nome_cliente: { label: utils.lng('cliente','Cliente') },
      nome_filial: { label: utils.lng('filial','Filial') },
      valor_total: { label: utils.lng('vlr_total','Vlr. Total'), bold: true, format: 'money', style: { float: 'right' } },
      valor: { label: utils.lng('vlr_spv','Vlr. SPV'), bold: true, format: 'money', style: { float: 'right' } }
    }
  };

  let gridNoticias = {
    type: 'list',
    add: false, header: false, search: false, controls: false,
    layout: 'card-row',
    style: { textAlign: 'left', paddingLeft: '20px' },
    size: 3,
    list: {
      url: 'feed?count=false'
    },
    elements: {
      cardEvt: {
        space: 12,
        style: { cursor: 'pointer' },
        click: { redirect: '{link}' },
        layout: 'grid',
        elements: {
          image: {
            type: 'img', rootStyle: { width: '40px' },
            style: { borderRadius: '0px', marginRight: '20px', width: '30px' },
            value: 'https://icons.iconarchive.com/icons/designcontest/ecommerce-business/96/news-icon.png'
          },
          info: {
            style: { verticalAlign: 'middle' },
            elements: {
              title: { space: 12, bold: true, style: { cursor: 'pointer' } },
              description: { space: 12, style: { cursor: 'pointer' } }
            }
          }
        }
      }
    }
  };

  let gridPontos = {
    type: 'list',
    header: false,
    controls: true,
    search: false,
    list: {
      url: 'dashboard_listarMeusPontos'
    },
    columns: {
      posicao: {},
      avatar: {},
      nome: {},
      pontuacao: {
        value: '${@this.pontos +" / "+@this.pontos_rede}',
        bold: true,
        style: {
          float: 'right'
        }
      }
    }
  };

  let gridCalendario = {
    type: 'list',
    header: false,
    controls: false,
    search: false,
    rowStyleClass: "${'ui-evt-' + ('' + @this.periodo).toLowerCase()}",
    list: {
      url: 'listarEventos?count=false'
    },
    columns: {
      titulo: {},
      data_inicial: { type: 'datetime' }
    },
    click: {
      redirect: '/crud/evento/{id}'
    }
  };

  let series = {
    key: 'ano_mes',
    value: 'total',
    group: 'grupo'
  };

  let labels = {
    time: utils.lng('equipe','Equipe'),
    pessoal: utils.lng('pessoal','Pessoal')
  };

  crud.showCalendarDialog = function (onChangeDate) {
    crud.onChangeDate = onChangeDate;
    setOpenDialog(true);
  };

  crud.detalheComissao = function (el, args) {
    setComissao(args.data);
    crud.changed.id_categoria_bonus = args.data.id;
    setOpenDialogComissao(true);
  };

  const handleCloseDialog = function () {
    if (crud.changed.date_start && crud.changed.date_end) {
      if (crud.onChangeDate) {
        crud.onChangeDate.call(this);
      }
      setOpenDialog(false);
    }
  };

  const cancelPeriodDialog = function () {
    setOpenDialog(false);
  };

  const handleCloseDialogComissao = function () {
    setOpenDialogComissao(false);
  };

  const dates = {
    name: 'dates',
    elements: {
      date_start: {
        type: 'date',
        dateFormat: 'long',
        label: utils.lng('data_inicial','Data inicial')
      }, date_end: {
        type: 'date',
        dateFormat: 'long',
        label: utils.lng("data_final","Data final")
      }
    }
  };


  const showItem = function (o) {
    return o.pontos > 0;
  }



  return (
    <View view={view}>
      <>
        <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {utils.lng('select_period', 'Selecione um periodo')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={dates} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={cancelPeriodDialog} color="primary">
              {utils.lng('cancelar', 'Cancelar')}
            </Button>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="lg" onClose={handleCloseDialogComissao} aria-labelledby="customized-dialog-title" open={openDialogComissao}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialogComissao}>
            {utils.lng('dashboard_detail_comissao', 'Detalhamento de comissão')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={gridDetalheComissao} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialogComissao} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="ui-grid ui-dashboard ui-dashboard-class">
          <div className="ui-col ui-col-12">
            <div className="ui-chart ui-chart-header">
              <div className="ui-grid ui-chart-inner">
                {resumo.exibirPontos &&
                  <>
                    <div id="qualificacao_dash" className="ui-col ui-col-2 ui-text-center">
                      {resumo.qualificado &&
                        <>
                          <span className="ui-chart-destaque">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="ui-chart-label">
                            {utils.lng('qualificado_para_spv', 'Qualificado para SPV')}
                          </span>
                        </>
                      }
                      {!resumo.qualificado &&
                        <>
                          <span className="ui-chart-destaque">
                            <i className="fa fa-times"></i>
                          </span>
                          <span className="ui-chart-label">
                            {utils.lng('nao_qualificado_para_spv', 'Não qualificado para SPV')}
                          </span>
                        </>
                      }
                    </div>
                    <div id="total_pontos" className="ui-col ui-col-4 ui-text-center ui-chart-pontos">
                      <span className="ui-chart-destaque">
                        {resumo.total_pontos}
                      </span>
                      <span className="ui-chart-label">
                        {utils.lng('pontos_individuais_mais_equipe', 'Pontos individuais + equipe')}
                      </span>
                    </div>
                    <div id="valor_creditos" className="ui-col ui-col-4 ui-text-center">
                      <span className="ui-chart-valor">
                        {showSpv &&
                          <>
                            {utils.money(resumo.valor_creditos)}
                          </>
                        }
                        {!showSpv &&
                          <>
                            {utils.lng("currency_symbol","R$")+"-----------"}
                          </>
                        }
                      </span>
                      <span className="ui-chart-label">
                        {utils.lng('previa_spv_mes_atual', 'Prévia SPV mês atual')}
                      </span>
                      {showSpv &&
                        <button className="btn-eye" onClick={() => setshowSpv(false)}><i className="far fa-eye-slash" id="showspv"></i></button>
                      }
                      {!showSpv &&
                        <button className="btn-eye" onClick={() => setshowSpv(true)}><i className="far fa-eye" id="notshowspv"></i></button>
                      }
                    </div>
                  </>
                }

                <div id="graduacao_dash" className="ui-col ui-col-2 ui-text-center ui-chart-graduacao">
                  <span className="ui-chart-destaque">
                    <img src={resumo.imagem_graduacao}></img>
                  </span>
                  <span className="ui-chart-label">
                    {resumo.graduacao}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-col ui-col-12">
            <div className="ui-chart ui-chart-header">
              <div className="ui-grid ui-chart-inner">
                <div id="saldos_spv" className="ui-col ui-col-6 ui-text-center">
                  <span className="ui-chart-destaque" id="itemspvHide">

                    {showSpv2 &&
                      <>
                        {utils.money(resumo.saldo_spv)}
                      </>
                    }
                    {!showSpv2 &&
                      <>
                        {utils.lng("currency_symbol","R$")+"-----------"}
                      </>
                    }
                  </span>
                  <span className="ui-chart-label">
                    {utils.lng('saldo_spv', 'Saldo SPV')}
                  </span>

                  {showSpv2 &&
                    <button className="btn-eye" onClick={() => setshowSpv2(false)}><i className="far fa-eye-slash" id="showspv2"></i></button>
                  }
                  {!showSpv2 &&
                    <button className="btn-eye" onClick={() => setshowSpv2(true)}><i className="far fa-eye" id="notshowspv2"></i></button>
                  }

                </div>
                <div id="outros_saldos" className="ui-col ui-col-6 ui-text-center ui-chart-pontos">
                  <span className="ui-chart-destaque">
                    {utils.money(resumo.saldo_outros)}
                  </span>
                  <span className="ui-chart-label">
                    {utils.lng('outros_saldos', 'Outros Saldos')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Chart space="6" crud={crud}>
            <Chart crud={crud} id="ch8" height="170px" space="12" type="container">
              <div>
                <div className="ui-grid ui-bold ui-chart-totais">
                  {resumo.exibirPontos &&
                    <>
                      <div className="ui-grid-row">
                        <div id="ponto_pessoal_acumulado" className="ui-grid-col ui-col-total ui-bg-blue">
                          {resumo.ponto_pessoal_acumulado}
                        </div>
                        <div className="ui-grid-col">
                          <i className="fas fa-user"></i>
                          <span className="ui-totais-label">{utils.lng('pontos_individuais_acumulados_mes', 'Pontos individuais acumulados no mês')}</span>
                        </div>
                      </div>
                      <div className="ui-grid-row">
                        <div id="ponto_rede_acumulado" className="ui-grid-col ui-col-total ui-bg-blue-dark">
                          {resumo.ponto_rede_acumulado}
                        </div>
                        <div className="ui-grid-col">
                          <i className="fas fa-users"></i>
                          <span className="ui-totais-label">{utils.lng('pontos_equipe_acumulados_mes', 'Pontos de equipe acumulados no mês')}</span>
                        </div>
                      </div>
                    </>
                  }
                  <div className="ui-grid-row">
                    <div className="ui-grid-col ui-col-total  ui-bg-blue">
                      {resumo.total_afiliados}
                    </div>
                    <div className="ui-grid-col">
                      <i className="fas fa-globe-americas"></i>
                      <span className="ui-totais-label">{utils.lng('filiados', 'Filiados')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Chart>
            <Chart crud={crud} id="ch1" height="265px" space="12" title={utils.lng('pontos_por_pais', "Pontos / Países")} type="bar" layout="horizontal" action="dashboard_listarPontoPorPais?count=false"></Chart>
          </Chart>
          {resumo.exibirPontos &&
            <>
              <Chart crud={crud} id="ch2" space="4" type="pie" title={utils.lng('pontos_linha_produto', "Pontos por linha de produto")} action="dashboard_listarPontoPorLinhaProduto?count=false" ></Chart>
              <Chart crud={crud} id="ch3" space="4" type="bar" title={utils.lng('vendas_pontos', "Vendas / Pontos")} series={series} labels={labels} period="quatro_meses" action="dashboard_listarPontoVendas"></Chart>
            </>
          }
          {resumo.exibirPontos &&
            <>
              <Chart crud={crud} id="ch4" space="6" type="grid" title={utils.lng('comissoes_estornos', "SPV e estornos")} source={gridComissao} period="mes_atual" periods={periodos} action="dashboard_listarComissaoGeral?count=false" filters={comissoes} ></Chart>
              <Chart crud={crud} id="ch5" space="6" type="grid" title={utils.lng('pontos_performance', "Pontos / Performance")} source={gridPontos} period="mes_atual" periods={periodos} action="dashboard_listarMeusPontos?count=false" filters={filtros} ></Chart>
            </>
          }
          <Chart crud={crud} space="6" title={utils.lng('geo_filiacao', "Geomarketing filiação - %")} height="460px" >
            <Chart crud={crud} id="ch6" space="6" type="map" action="dashboard_listarFiliacao?count=false"></Chart>
            <Chart crud={crud} id="ch7" space="6" type="bar" layout="horizontal" isPercentual="true" action="dashboard_listarFiliacao?count=false"></Chart>
          </Chart>
          <Chart crud={crud} id="ch8" space="12" type="grid" title={utils.lng('eventos', "Eventos")} source={gridCalendario} action="dashboard_listarEventosMes?count=false" period="mes_atual" periods={eventos} ></Chart>
          {resumo.exibirPontos &&
            <Chart itemLevel="recursion_level" size="-1" rendered={showItem} randomColors={true} crud={crud} id="ch10" space="12" type="sunburst"
              recursive={false} fieldKey="id" parentFieldKey="id_superior" height="800px"
              title={utils.lng('mapa_pontos', "Mapa de pontos")} action="listarMapaPontos?count=false" parent={{ id: 'superior' }}
              itemKey="id" itemLabel="nome" itemValue="pontos" itemParent="id_superior" tooltip="{nome}, Pontos: {pontos}" ></Chart>
          }
          <Chart crud={crud} id="ch9" space="12" height="440px" type="grid" title={utils.lng('noticias', "Noticias")} source={gridNoticias} styleClass="ui-chart-noticias" ></Chart>
        </div>
      </>
    </View>
  );
}

export default Dashboard;