import React, { useState, useEffect } from "react";
import { Button } from '@material-ui/core';
import Element from "./element";
import utils from './utils';
import logoImg from './img/logo_nippon.png';
import homeIcon from './img/homeIcon.png';

const MenuItem = function (props) {
  let crud = props.crud;
  let catalogo = props.catalogo;
  let descricao = props.descricao;
  let view = props.view;
  let nivel = props.nivel;
  let [ready, setReady] = useState(false);
  let item = props.item;
  let id = item ? item.id : 0;
  let hg = window.screen.height;
  let style = {};


  style.maxHeight = (hg - 350) + 'px';

  const onClick = function () {
    if(window.screen.width < 990){
      if(item.filhos <= 0){
        window.location.href = '/carrinho/' + item.id;
      }
    }else{
      window.location.href = '/carrinho/' + item.id;
    }
  }



  return (
    <div className={'ui-catalogo-item ui-catalogo-item-' + nivel + ' ui-catalogo-' + (nivel % 2 == 0 ? 'even' : 'odd')}>
      <div className="ui-catalogo-label" onClick={onClick}>
        {descricao}
      </div>
      {item.filhos.length > 0 &&
        <div className="ui-catalogo-itens" style={style}>
          <div className="ui-catalogo-sub">
            {item.filhos.map((i, index) => (
              <>
                <MenuItem crud={crud} nivel={nivel + 1} view={view} descricao={i.descricao} item={i} catalogo={catalogo} />
              </>
            ))}
          </div>
        </div>
      }
    </div>
  );
}

const MenuCarrinho = function (props) {
  let [menus, setMenus] = useState([]);
  let [ready, setReady] = useState(false);
  let crud = props.crud;
  let view = props.view;
  let data = props.data;
  let [mobile, setMobile] = useState(false);

  crud.onClick = function (c) {
    crud.changed.classificacao = c.id;
    crud.refresh();
  }


  useEffect(() => {
    if (!ready) {
      const _cd_ = {};

      _cd_.complete = function (res) {
        setMenus(res.data);
      };
      _cd_.element = {};
      _cd_.cfg = {
        url: 'cart/menu_top',
        method: 'GET'
      };

      _cd_.changed = { superior: 0 };
      _cd_.crud = crud;
      _cd_.view = view;

      utils.call(_cd_);
    }
  }, []);

  crud.selecionarProduto = function (el, args) {
    window.location.href = '/carrinho/produto/' + args.produto;
  }

  crud.onsearch = function (el, args) {
    crud.changed.query = args.query;
  }

  const search = {
    elements: {
      search_prd: {
        name: 'produto',
        size: 50,
        auto: false,
        type: 'complete',
        inputIcon: 'fa fa-search',
        styleClass: 'ui-search',
        itemLabel: 'pesquisa',
        itemValue: 'id_sku',
        label: utils.lng('pesquisar','Pesquisar'),
        fields: ["pesquisa"],
        columns: {
          avatar: { type: 'img' },
          descricao: {},
        },
        list: {
          url: 'listarBuscaRapidaCatalogo'
        },
        change: {
          action: 'selecionarProduto'
        },
        keypress: {
          action: 'onsearch'
        }
      }
    }
  };

  const acoes = {
    name: 'acoes',
    styleClass: 'ui-catalogo-actions',
    elements: {
      home: { cols: 6, name: "home", type: 'link', icon: 'fa fa-home', href: '/carrinho' },
      favorito: { cols: 6, name: "favorito", type: 'link', icon: 'fas fa-heart', href: '/carrinho/favoritos' }
    }
  };

  const resize = function () {
    mobile = window.innerWidth <= 989;
    setMobile(mobile);
    window.mobile = mobile;
  }

  useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize();
  }, []);

  const openMenu = function (){
    
      document.getElementById('menu-mobile-id').style.display = 'block';
      document.getElementById('openMenu').style.display = 'none';
      document.getElementById('closeMenu').style.display = 'block';
  }

  const closeMenu = function (){
      document.getElementById('menu-mobile-id').style.display = 'none';
      document.getElementById('openMenu').style.display = 'block';
      document.getElementById('closeMenu').style.display = 'none';
  }

  return (

    <div className="top-cabecalho">
    <div className="ui-catalogo-top catalogo-top-classe">
      <div id="catalogo_search" className="ui-catalogo-search pesquisa-classe col-4">
        <Element crud={crud} view={view} value={search} ></Element>
      </div>

        {mobile &&
        <>
          <div className= "logo-classe">
          <a href='/carrinho'>
            <div className="ui-logo-catalogo">
              <img src={logoImg} />
            </div>
          </a>
        </div>
        </>
        }
        {!mobile &&
        <>
          <div className="logo-classe">
          <a href='/carrinho'>
            <div className="ui-logo-catalogo">
              <img src={logoImg} />
            </div>
          </a>
        </div>
        </>
        }

      

      <div className="carrinho-classe col-4">
        <div>
          <a href="/carrinho">
            <img src={homeIcon} className="homeIcon"></img>
          </a>
          <a href="/carrinho/favoritos" className="fav-classe">
              <svg class="mt-2" id="Grupo_22" data-name="Grupo 22" xmlns="http://www.w3.org/2000/svg" width="26.92" height="22.796" viewBox="0 0 26.92 22.796">
                <path id="Caminho_80" data-name="Caminho 80" d="M19.665,39.221a7.038,7.038,0,0,0-5.677,2.888c-.2.252-.372.5-.527.748-.155-.244-.331-.5-.527-.748a7.038,7.038,0,0,0-5.677-2.888C3.026,39.221,0,42.763,0,47.013c0,4.86,3.983,9.44,12.915,14.851a1.052,1.052,0,0,0,1.09,0c8.932-5.411,12.915-9.991,12.915-14.851C26.92,42.765,23.9,39.221,19.665,39.221Zm2.374,13.844a39.145,39.145,0,0,1-8.579,6.668,39.147,39.147,0,0,1-8.579-6.668C3.012,50.979,2.1,49,2.1,47.013c0-3.06,2.068-5.689,5.153-5.689a4.928,4.928,0,0,1,3.981,2.03,7.811,7.811,0,0,1,1.219,2.2,1.052,1.052,0,0,0,2.008,0A7.8,7.8,0,0,1,15.645,43.4a4.941,4.941,0,0,1,4.019-2.079c3.088,0,5.153,2.632,5.153,5.689C24.817,49,23.908,50.979,22.039,53.065Z" transform="translate(0 -39.221)" fill="#ff002f"></path>
              </svg>
          </a>
            <button className="btn btn-danger" onClick={function(){window.location.href = '/carrinho/pedido';}}>
              <svg className="cart-ico-classe" xmlns="http://www.w3.org/2000/svg" height="23" viewBox="0 0 29.977 28.213">
                <path data-name="icon-carrinho" fill="#ffffff" d="M29.8,18.529a.961.961,0,0,0-.748-.411l-18.595-.8a.962.962,0,0,0-.083,1.922l17.336.747L24.306,30.621H9.137L6.4,15.7a.962.962,0,0,0-.594-.721L1.314,13.215a.962.962,0,1,0-.7,1.791L4.6,16.573,7.39,31.757a.962.962,0,0,0,.946.788H8.8L7.742,35.479A.8.8,0,0,0,8.5,36.552h.741a2.885,2.885,0,1,0,4.289,0h6.291a2.885,2.885,0,1,0,4.289,0h.9a.8.8,0,1,0,0-1.6H9.637l.866-2.4H25.007a.961.961,0,0,0,.916-.668l4.008-12.5A.96.96,0,0,0,29.8,18.529ZM11.382,39.758a1.282,1.282,0,1,1,1.282-1.282A1.284,1.284,0,0,1,11.382,39.758Zm10.58,0a1.282,1.282,0,1,1,1.282-1.282A1.284,1.284,0,0,1,21.961,39.758Z" transform="translate(0 -13.148)"></path>
              </svg>

              <font className="cart-label-classe">{utils.lng('meu_carrinho','Meu carrinho')}</font>
            </button>
        </div>
      </div>
    </div>



    <div className="ui-catalogo-nav" >
      {mobile &&
      <>
        <Button variant="outlined" onClick={openMenu} id="openMenu" className="menu-mobile-dropdown">Abrir Menu</Button>
        <Button variant="outlined" onClick={closeMenu} id="closeMenu" className="menu-mobile-dropdown">Fechar Menu</Button>
        <div id="menu-mobile-id">
          {menus.map((i, index) => (
            <>
              <MenuItem crud={crud} nivel={0} view={view} descricao={i.descricao} item={i} />
            </>
          ))}
        </div>
      </>
      }
      {!mobile &&
      <>
        {menus.map((i, index) => (
            <>
              <MenuItem crud={crud} nivel={0} view={view} descricao={i.descricao} item={i} />
            </>
          ))}
      </>
      }
      </div>

    </div>
  );
};

export default MenuCarrinho;