import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioPontosVinculados() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('pontos_vinculados', 'Pontos Vinculados'),
    name: 'reportCfg',
    add: false,
    type: 'list',
    print:true,
    auto: false,
    list: {
      url: 'report_pontos_vinculados'
    },
    filters: {
      date: {
        type: 'datetime',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data_vinculacao', "Data Vinculação"),
        default: "|"
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      }
    },
    columns: {
      dataVinculacao: { field: 'data_vinculacao', type: 'date', label: utils.lng('data_vinculacao', 'Data Vinculação'), filter: false, sort: false },
      descricaoSku: { field: 'descricao_sku', label: utils.lng('descricao_produto', "Descrição Produto"), filter: false, sort: false },
      quantidade: {field:'quantidade', label: utils.lng('quantidade', 'Quantidade'), filter: false, sort:false},
      pontos: { field: 'pontos', label: utils.lng('pontos', 'Pontos'), filter: false, sort: false, format: 'number', decimalCount: 4, aggregate: 'sum' },
      nomeClienteOrigem: { field: 'nome_cliente_origem', label: utils.lng('origem', 'Origem'), filter: false, sort: false },
      nome_usuario: {field:'nome_usuario', label: utils.lng('usuario', 'Usuário'), filter: false, sort:false},
    }
  };

  return (
    <View name="rel-eventos" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}
export default RelatorioPontosVinculados;