import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from "./utils";
import Button from '@material-ui/core/Button';

const VerEntrevistaCliente = () => {
    let x = {};
    let [element, setElement] = useState({});
    let [root, setRoot] = useState({ elements: {} });
    const [crud, setCrud] = useState({ data: x, changed: x,readOnly:true });
    const [data, setData] = useState(x);
    const [view, setView] = useState({});
    const [name, setName] = useState('none');
    let [ready, setReady] = useState(false);
    let [cliente, setCliente] = useState(0);

    let split = window.location.pathname.substring(1).split('/');

    if (split.length > 1) {
        cliente = parseInt(split[1]);
    }

    const carregar = function (entrevista) {
        utils.execute(crud, 'formulario_entrevista/nova_entrevista_consultor?id_entrevista=' + entrevista, null, function (res) {
            element = res.data;


            if (element.data) {
                for (const i in element.data) {
                    crud.data[i] = element.data[i];
                    crud.changed[i] = element.data[i];
                }
            }


            let x = { elements: {} };

            element.disabled = true;

            x.elements.view = element;
            x.elements.actions = {
                style: { 'textAlign': 'center' },
                space: 12,
                elements: {
                    print: {
                        icon: 'fa fa-print',
                        label: 'Imprimir',
                        type: 'button',
                        click: {
                            url: 'component/nova_entrevista_consultor/print.pdf?reference=' + entrevista,
                            method: 'GET',
                            fileName: 'entrevista_' + entrevista + '.pdf',
                            send: false,
                            download: true
                        }
                    }
                }
            };
            root = x;
            setRoot(x);

            crud.changed.reference = entrevista;
            crud.changed.id_entrevista = entrevista;

            crud.data.reference = entrevista;
            crud.data.id_entrevista = entrevista;

        }, true);
    }

    useEffect(() => {
        if (!ready) {
            utils.execute(crud, 'encontrar_entrevista_cliente?single=true&id_cliente=' + cliente, null, function (res) {
                let en = res.data;
                let entrevista = en.id_entrevista;

                carregar(entrevista);
            }, true);
            ready = true;
            setReady(true);
        }
    }, []);


    const onClickFormulario = function () {
        const _call_ = {};
        const btn = {
            click: {
                url: 'salvarEntrevista',
                redirect: '/entrevistas',
                method: 'POST'
            }
        };

        _call_.event = 'click';
        _call_.element = btn;
        _call_.cfg = btn.click;
        _call_.data = crud.data;
        _call_.changed = crud.changed;
        _call_.crud = crud;
        _call_.view = view;
        _call_.validate = true;

        utils.call(_call_);
    };

    window.crud = crud;

    return (
        <View name="entrevista">
            {ready &&
                <>
                    <Element view={view}
                        value={root}
                        data={data}
                        crud={crud}
                    />
                </>
            }
            {!ready &&
                <div className="ui-loading">
                    <i className="fas fas fa-circle-notch fa-spin"></i>
                </div>
            }
        </View>
    )
};

export default VerEntrevistaCliente;