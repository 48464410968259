import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioRankingDistribuidoresResumoMesPais() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('ranking_distribuidores_resumo_mes_pais', 'Ranking Distribuidores Resumo por Mês e País'),
    name: 'reportCfg',
    add: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_rankingDistribuidoresResumoMesPais'
    },
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data', "Data")
      }
    },
    columns: {
      mes: { field: 'mes', label: utils.lng('mes', "Mês"), filter: false, sort: false },
      sigla_pais: { field: 'sigla_pais', label: utils.lng('pais', 'País'), filter: false, sort: false },
      primeiro_lugar: { field: 'primeiro_lugar', label: utils.lng('primeiro_lugar', '1º Lugar')+" (+10 UP)", filter: false, sort: false },
      segundo_lugar: { field: 'segundo_lugar', label: utils.lng('segundo_lugar', '2º Lugar')+" (+5 UP)", filter: false, sort: false },
      terceiro_lugar: { field: 'terceiro_lugar', label: utils.lng('terceiro_lugar', '3º Lugar')+" (+2 UP)", filter: false, sort: false }
    }
  };

  return (
    <View className="ui-view-report" name="rel-ranking-distribuidores">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioRankingDistribuidoresResumoMesPais;