import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

const RelatorioRankingDistribuidores = function(props) {
  let data = {};
  let [view, setView] = useState({});
  let [model, setModel] = useState({});
  let [crud, setCrud] = useState({ data: data, changed: data, filters: data, requireds: {} });
  let [bannerRanking, setBannerRanking] = useState('');
  // let [tituloRanking, setTituloRanking] = useState('');
  let urlValues = window.location.pathname.substring(1).split('/');
  let id_config_ranking = urlValues[2];

  useEffect(() => {
    if (id_config_ranking) {
      utils.execute(crud, 'ConfiguracaoRanking/'+id_config_ranking, null, function (res) {
        if (res.data && res.data.id) {
          setFilters(res.data);
        }
      });
    } else {
      setFilters();
    }
  }, []);

  const setFilters = (cfgRanking) => {
    let reportCfg = {
      label: utils.lng('ranking_distribuidores', 'Ranking de Distribuidores'),
      name: 'reportCfg',
      add: false,
      print: true,
      type: 'list',
      auto: true,
      filter: id_config_ranking ? false : true,
      list: {
        url: id_config_ranking ? 'report_rankingDistribuidores_cache' : 'report_rankingDistribuidores'
      },
      filters: {
        date: {
          type: 'date',
          range: true,
          dateFormat: 'long',
          label: utils.lng('data', "Data")
        },
        nivel_ranking: {
          type: 'number',
          label: utils.lng('nivel_ranking', "Nível no ranking"),
        },
        pontuacao_minima_periodo: {
          type: 'number',
          label: utils.lng('pontuacao_minima_periodo', "Pontuação Mínima"),
        },
        id_filial: {
          type: 'select',
          label: utils.lng('filial', "Filial"),
          list: { url: 'endpoint.listar.filial' },
          itemLabel: 'nome',
          itemValue: 'id'
        },
        id_plano_marketing: {
          type: 'select',
          label: utils.lng('plano_marketing', "Plano de Marketing"),
          list: { url: 'PlanoMarketing' },
          itemLabel: 'descricao',
          itemValue: 'id'
        },
        id_graduacao: {
          type: 'select',
          label: utils.lng('graduacao', "Graduação"),
          list: { url: 'Graduacao' },
          itemLabel: 'descricao',
          itemValue: 'id'
        },
        status_penn: {
          type: 'select',
          label: utils.lng('status_penn', "Status Penn"),
          list: { url: 'StatusPenn' },
          itemLabel: 'descricao',
          itemValue: 'id'
        },
        novo_no_time: {
          type: 'select',
          label: utils.lng('com_rede_nova', "Com Rede Nova"),
          data: [
            { label: utils.lng('sim', "Sim"), value: true },
            { label: utils.lng('nao', "Não"), value: false }
          ]
        }
      },
      columns: {
        nivelRanking: { field: 'nivel_no_ranking', label: utils.lng('nivel_ranking', "Nível no ranking"), filter: false, sort: false },
        codigo: { field: 'codigo_mundial', label: utils.lng('codigo', 'Código'), filter: false, sort: false },
        nome: { field: 'nome', label: utils.lng('nome', 'Nome'), filter: false, sort: false },
        graduacao: { field: 'graduacao', label: utils.lng('graduacao', 'Graduação'), filter: false, sort: false },
        filial: { field: 'filial', label: utils.lng('filial', 'Filial'), filter: false, sort: false },
        cidade: { field: 'cidade', label: utils.lng('cidade', 'Cidade'), filter: false, sort: false },
        estado: { field: 'estado', label: utils.lng('estado', 'Estado'), filter: false, sort: false },
        pontosIndividuais: { field: 'pontos_individuais',  format: 'number', decimalCount: 4, label: utils.lng('pontos_individuais', 'Pontos Individuais'), filter: false, sort: false, visible: (!cfgRanking || (cfgRanking && !cfgRanking.novoNoTime)) },
        pontosIndividuaisNovoNoTime: { field: 'pontos_individuais', format: 'number', decimalCount: 4, label: utils.lng('pontos_rede', 'Pontos Com Rede Nova'), filter: false, sort: false, visible: cfgRanking && cfgRanking.novoNoTime },
        bonusCampeao: { field: 'bonus_campeao', format: 'number', decimalCount: 4, label: utils.lng('bonus_campeao', 'Bônus Campeão'), filter: false, sort: false, visible: id_config_ranking ? false : true },
        pontosTotais: { field: 'pontos_totais', format: 'number', decimalCount: 4, label: utils.lng('pontos_totais', 'Pontos Totais'), filter: false, sort: false, visible: id_config_ranking ? false : true }
      }
    };

    if (cfgRanking) {
      crud.changed.id_config_ranking = id_config_ranking;
      setBannerRanking(cfgRanking.banner);
    }
    setModel(reportCfg)
    setCrud(crud);
    
  }

  return (
    <View className="ui-view-report" name="rel-ranking-distribuidores">
      {bannerRanking && bannerRanking !== '' &&
        <>
          <div className="cabecalho-ranking">
            <div className="banner-ranking">
                <img src={bannerRanking} style={{ height: '250px'}}/>
            </div>
            {/* <div className="logo-classe" style={{ fontSize: '30px' }}>{tituloRanking}</div> */}
          </div>
        </>
      }

      <Element value={model} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioRankingDistribuidores;