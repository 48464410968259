import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';
import CustomCarousel from './carousel';
import MenuCarrinho from './menu_carrinho';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Card_produto from "./card_produto";

function Carrinho() {
  const path = window.location.pathname;
  const split = path.split('/');
  let idClassificacao = 0;

  if (split.length >= 3) {
    idClassificacao = parseInt(split[2]);
  }

  window.processo_atual = 73;

  let [view, setView] = useState({});
  let [pedido, setPedido] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: { classificacao: idClassificacao } });
  let [homes, setHomes] = useState([]);
  let [classificacao, setClassificacao] = useState({});
  let [ready, setReady] = useState(false);
  let [showDialog, setShowDialog] = useState(false);
  let [index, setIndex] = useState(0);
  let user = utils.getUser();
  let [access, setAccess] = useState(window.access ? window.access : {});
  let [banners, setBanners] = useState({});

  crud.allow = function (key) {
    if (user.administrativo) {
      return true;
    }

    return window.access[key];
  }
  const carregarImagens = function (hp) {
    const _hc_ = {};

    _hc_.complete = function (res) {
      if (res.data) {
        hp.imagens = [];

        for (const i in res.data) {
          let im = res.data[i];
          let ps = im.posicao.toLowerCase();

          if (ps !== 'principal') {
            banners[ps] = im;
          } else {
            hp.imagens.push(im);
          }
        }
      }
    };
    _hc_.element = {};
    _hc_.cfg = {
      url: 'listarBannerCatalogo?catalogo=' + hp.id + (idClassificacao && idClassificacao > 0 ? '&classificacao=' + idClassificacao : ''),
      method: 'GET'
    };

    _hc_.changed = { superior: 0 };
    _hc_.crud = crud;
    _hc_.view = view;

    utils.call(_hc_);
  }
  useEffect(() => {
    if (!ready) {

      utils.execute(crud, 'encontrarPedido?id_pedido=0', null, function (res) {
        if (res.data && res.data.length > 0) {
          pedido = res.data[0];
          setPedido(res.data[0]);
        } else if (crud.allow('EDITAR_FILIAL') !== false) {
          showDialog = true;
          setShowDialog(true);
        }
      });

      setReady(true);

      const _hc_ = {};

      _hc_.complete = function (res) {
        if (res.data) {
          homes = res.data;
          setHomes(homes);

          for (const i in homes) {
            const hp = homes[i];

            carregarImagens(hp);
          }
        }
      };
      _hc_.element = {};
      _hc_.cfg = {
        url: 'listarHomeCatalogo',
        method: 'GET'
      };

      _hc_.changed = { superior: 0 };
      _hc_.crud = crud;
      _hc_.view = view;

      utils.call(_hc_);
    }

  }, []);

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  crud.onClick = function (c) {
    crud.changed.classificacao = c.id;
    setClassificacao(c);
    setCrud(crud);
  }



  const filial = {

    elements: {
      detail: {
        center: true,
        elements: {
          title: { value: '<i class="fas fa-store"></i>'+utils.lng('label_pergunta_retirar_produto_filial','Em qual filial você deseja retirar seu produto?') },
          info: { value: utils.lng('label_aviso_retirar_produto_filial','Dessa forma você terá acesso aos produtos e ofertas da Filial selecionada.') }
        }
      },
      filialRet: {
        name: 'filialRetirada',
        label: utils.lng('filial_retirada','Filial retirada'),
        type: 'select',
        itemValue: 'id',
        itemLabel: 'nome',
        list: {
          url: 'Filial'
        }
      }
    }
  };

  const closeDialog = function () {
    showDialog = false;
    setShowDialog(false);
  };

  const confirmDialog = function () {
    let _ks_ = {};

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = pedido;
    _ks_.element = {};

    _ks_.complete = function (res) {
      closeDialog();
    };

    _ks_.cfg = {
      redirect: true,
      url: 'cart/changeDeliveryLocal',
      method: 'POST',
      global: true
    };
    utils.call(_ks_);
  };

  return (
    <View name="carrinho">
      <div className="ui-catalogo">
        <Dialog
          open={showDialog}
          className="ui-dlg-escolha-filial"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Element disabled={false} value={filial} crud={crud} data={pedido} view={view} ></Element>
          </DialogContent>
          <DialogActions>
            <Button id="btnConfirmarFilial" onClick={confirmDialog} variant="outlined" color="primary">{utils.lng('confirmar','Confirmar')}</Button>
          </DialogActions>
        </Dialog>
        <MenuCarrinho crud={crud} view={view} ></MenuCarrinho>

        {banners.esquerda &&
          <div className="ui-banner-lateral ui-banner-esquerda">
            <a href={banners.esquerda.link_redirecionamento}>
              <img src={banners.esquerda.link_banner}></img>
            </a>
          </div>
        }
        <div className="ui-catalogo-content" >
          <div className="ui-catalogo-home" >
            {homes.map((i, index) => (
              <div className="ui-catalogo-bloco">
                <div className="ui-catalogo-banner" >
                  {i.imagens &&
                    <Carousel infiniteLoop={false} autoPlay={true} showStatus={false} showArrows={true}>
                      {i.imagens.map((li, ib) => (
                        <>
                          {li.posicao === 'PRINCIPAL' &&
                            <div className="ui-banner-principal">
                              <a href={li.link_redirecionamento}>
                                <div>
                                  <img src={li.link_banner} />
                                </div>
                              </a>
                            </div>
                          }
                        </>
                      ))}
                    </Carousel>
                  }
                </div>
                <CustomCarousel classificacao={idClassificacao} data={i} crud={crud} view={view} />

              </div>
            ))}
          </div>

          <div className="ui-catalogo-data" >
            <Card_produto classificacao={idClassificacao} crud={crud} />
          </div>
        </div>
        {banners.direita &&
          <div className="ui-banner-lateral ui-banner-direita">
            <a href={banners.direita.link_redirecionamento}>
              <img src={banners.direita.link_banner}></img>
            </a>
          </div>
        }
      </div>
    </View>
  );
}

export default Carrinho;