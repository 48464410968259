import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from "./utils";
import Button from '@material-ui/core/Button';

const Component = () => {
    const [element, setElement] = useState({});
    const [crud, setCrud] = useState({ data: {}, changed: {} });
    const [data, setData] = useState({});
    const [view, setView] = useState({});
    const [name, setName] = useState('none');
    let [ready, setReady] = useState(false);

    let split = window.location.pathname.substring(1).split('/');

    useEffect(() => {

        if (!ready) {
            if (split.length > 1) {
                setName(split[1]);

                utils.execute(crud, 'component/' + split[1], null, function (res) {
                    setElement(res.data);
                    setReady(true);
                }, true);
            } else {
                setReady(true);
            }
        }
    }, []);

    window.crud = crud;

    return (
        <View name={'component-' + name}>
            {ready &&
                <Element view={view}
                    value={element}
                    data={data}
                    crud={crud}
                />
            }
            {!ready &&
                <div className="ui-loading">
                    <i className="fas fas fa-circle-notch fa-spin"></i>
                </div>
            }
        </View>
    )
};

export default Component;