import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioEventos() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('eventos', 'Eventos'),
    name: 'reportCfg',
    add: false,
    print: true,
    field: 'rel_eventos',
    main: true,
    auto: false,
    type: 'list',
    list: {
      url: 'report_eventos'
    },
    filters: {
      date: {
        type: 'datetime',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data_inicial_evento', "Data Inicial Evento"),
        default: "|"//default: { start: new Date(), end: new Date() } [Willian] Não listava todos os eventos ao abrir o relatório
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_tipo_evento: {
        type: 'select',
        label: utils.lng('tipo_evento', "Tipo de Evento"),
        list: { url: 'EventoTipo' },
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      finalizado: {
        type: 'select',
        label: utils.lng('finalizado', "Finalizado"),
        data: [
          { label: utils.lng('sim', "Sim"), value: true },
          { label: utils.lng('nao', "Não"), value: false }
        ]
      }
    },
    columns: {
      edit: {
        icon: 'fa fa-edit',
        click: {
          redirect: '/crud/evento/${@this.id}'
        }
      },
      descricaoTipoEvt: { field: 'descricao_tipo_evento', label: utils.lng('tipo_evento', "Tipo de Evento"), filter: false, sort: false },
      titulo: { field: 'titulo', label: utils.lng('titulo', 'Título'), filter: false, sort: false },
      nomeCliente: { field: 'nome_cliente', label: utils.lng('cliente', 'Cliente'), filter: false, sort: false },
      dataInicial: { field: 'data_inicial', type: 'datetime', label: utils.lng('data_inicial', 'Data Inicial'), filter: false, sort: false },
      dataFinal: { field: 'data_final', type: 'datetime', label: utils.lng('data_final', 'Data Final'), filter: false, sort: false },
      finalizado: { field: 'finalizado', label: utils.lng('finalizado', 'Finalizado'), filter: false, sort: false }
    }
  };

  return (
    <View name="rel-eventos" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioEventos;