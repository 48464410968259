import React, { useState, useEffect } from "react";
import Utils from './utils';
import Crud from './crud';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import utils from "./utils";
import Element from './element';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Checkbox from '@material-ui/core/Checkbox';

import InputMask from 'react-input-mask';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const GridInner = ({ parentQuery, parent, id, value, element, disabled, data, changed, onClick, crud, user }) => {
    let saved = utils.get(value.field, data);
    let bkp = [];

    if (!saved) {
        saved = [];
    }

    if (saved.push) {
        for (const i in saved) {
            const v = saved[i];

            if (!v.id) {
                bkp.push(v);
            }
        }
    }

    let changedFilter = crud.filters ? crud.filters : {};

    let [news, setNews] = useState(bkp);
    let [list, setList] = useState([]);
    let [groups, setGroups] = useState([]);
    let [totalizer, setTotalizer] = useState([]);
    let [page, setPage] = useState(1);
    let [index, setIndex] = useState(0);
    let [selectedIndex, setSelectedIndex] = useState(value.selectedIndex >= 0 ? value.selectedIndex : -1);
    let [total, setTotal] = useState(0);
    let [query, setQuery] = useState(parentQuery ? parentQuery : '');
    let [scrollLeft, setScrollLeft] = useState(0);
    let [scrollTop, setScrollTop] = useState(0);
    let [sortBy, setSortBy] = useState({});
    let [selectedItem, setSelectedItem] = useState({});
    let [crudFilter, setCrudFilter] = useState({ filter: true, data: changedFilter, changed: changedFilter });
    let [dialog, setDialog] = useState(false);
    let [scrolling, setScrolling] = useState(false);
    let [filter, setFilter] = useState(false);
    let [left, setLeft] = useState(value.left);
    let [loading, setLoading] = useState(false);
    let [ready, setReady] = useState(false);
    let [model, setModel] = useState({ edit: false, dialog: true });
    let [all, setAll] = useState([]);
    let [aggList, setAggList] = useState([]);
    let view = window.view;
    let [detail, setDetail] = useState('');
    let [src, setSrc] = useState('');
    let [size, setSize] = useState(value.size ? value.size : 10);
    let [open, setOpen] = React.useState(false);
    let [parentId, setParentId] = useState(0);
    let [attachmentTypeField, setAttachmentTypeField] = useState(null);
    let [attachmentTypeObj, setAttachmentTypeObj] = useState(null);
    let [allChecked,setAllChecked] = React.useState(false);

    let field = value.field;
    let acceptedFiles = value.acceptedFiles ? value.acceptedFiles : ['image/*'];
    let maxFileSize = value.maxFileSize ? value.maxFileSize : 100000000;
    let b = [];
    
    if (typeof field !== 'undefined' && field.indexOf('.') > 0) {
        const s = field.split('.');

        for (let i = 0; i < s.length - 1; i++) {
            b.push(s[i]);
        }
    }

    b.push('id');

    let getKeyCache = function () {
        if (element.clientId && value.main === true) {
            let tab = utils.getTab();

            if (tab && tab.key) {
                return tab.key + '-' + element.clientId;
            }
        }
        return null;
    }
    const aux = b.join('.');

    try {
        parentId = eval('data.' + aux);
    } catch (error) {
    }

    if (!parentId) {
        parentId = 0;
    }


    if (value.list) {
        model.parent = value.list.mappedBy;

        if (value.search) {
            model.entity = value.search.entity;
        } else if (value.entity) {
            model.entity = value.entity;
        }
        all = [];
    }

    crudFilter.owner = crud;

    model.crud = value.crud;

    const close = () => {
        setModel(model);
        setDialog(false);

        search();
    }

    model.close = () => {
        close();
    }
    model.refresh = function () {
        search();
    };

    value.refresh = function () {
        search();
    };

    model.confirm = () => {
        const sel = model.selected;

        if (value.type === 'list') {
            if (!model.edit || model.create) {
                let _list_ = [];

                try {
                    _list_ = eval('data.' + value.field);
                } catch (error) {
                }

                if (!_list_) {
                    _list_ = [];
                }
                _list_.push(sel);

                Utils.set(value.field, data, _list_);
                Utils.set(value.field, changed, _list_);

                setNews(_list_);
                news = _list_;
            }
            model.close();
        }
    }

    const select = (cfg, args) => {
        const sel = args.data;
        const click = args.click;

        if (typeof click.set === 'object') {
            for (const fi in click.set) {
                const fe = click.set[fi];

                Utils.set(fi, data, sel[fe]);
                Utils.set(fi, changed, sel[fe]);
            }
        } else {
            Utils.set(value.field, data, sel);
            Utils.set(value.field, changed, sel);
        }

        if (crud.refresh) {
            crud.refresh();
        }

        if (view.refresh) {
            view.refresh();
        }
    }

    const remove = (cfg, args) => {
        const sel = args.data;
        const indx = args.index;

        if (sel.id > 0 && crud.id > 0) {
            const complete = function () {
                setLoading(false);
                value.refresh();
            }
            const _call_ = {};
            const put = {};

            put.method = 'DELETE';

            if (value.list.mappedBy === 'parent') {
                put.url = crud.entity + '/' + crud.id + '/' + value.field + '/' + sel.id;
            } else {
                put.url = value.entity + '/' + sel.id;
            }

            _call_.element = value;
            _call_.cfg = put;
            _call_.complete = complete;
            _call_.global = false;
            _call_.data = sel;
            _call_.changed = null;
            _call_.crud = crud;
            _call_.view = view;
            _call_.onstart = function () {
                setLoading(true);
            }

            Utils.call(_call_);
        } else {
            let _list_ = [];

            for (let i in all) {
                i = parseInt(i);
                const v = all[i];

                if (i !== indx) {
                    _list_.push(v);
                }
            }

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            news = _list_;
            all = _list_;
            setNews(_list_);
            setAll(_list_);

            model.close();
        }
    }
    model.rowSelect = (cfg, args) => {
        const sel = args.data;

        if (data.id > 0 || !value.field) {
            if (value.search) {
                const complete = function () {
                    setLoading(false);


                    delete model.id;
                    delete model.data;
                    delete model.selected;

                    model.edit = false;
                    value.refresh();
                }

                const _call_ = {};
                const put = {};

                put.method = 'POST';
                put.alert = true;
                put.url = value.entity;

                model.changed[value.target] = sel.id;

                if (value.parent && !model.changed[value.parent]) {
                    model.changed[value.parent] = data[value.parent];
                }

                _call_.element = value;
                _call_.cfg = put;
                _call_.complete = complete;
                _call_.data = sel;
                _call_.changed = model.changed;
                _call_.crud = model;
                _call_.view = view;
                _call_.onstart = function () {
                    setLoading(true);
                }
                Utils.call(_call_);
            } else {
                const complete = function () {
                    setLoading(false);


                    delete model.id;
                    delete model.data;
                    delete model.selected;

                    model.edit = false;
                    value.refresh();
                    model.close();
                }
                const _call_ = {};
                const put = {};

                put.method = 'PUT';
                put.alert = false;
                put.url = crud.entity + '/' + crud.id + '/' + value.field + '/' + sel.id;

                _call_.element = value;
                _call_.cfg = put;
                _call_.complete = complete;
                _call_.data = sel;
                _call_.changed = null;
                _call_.crud = crud;
                _call_.view = view;
                _call_.onstart = function () {
                    setLoading(true);
                }

                Utils.call(_call_);
            }
        } else {
            let _list_ = eval('data.' + value.field);

            if (!_list_) {
                _list_ = [];
            }
            _list_.push(sel);

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            news.push(sel);
            model.close();
        }
        return false;
    }

    const rowEdit = function (element, args) {
        const _data_ = args.data;

        model.edit = true;
        model.create = false;
        model.new = !(_data_.id > 0);
        model.id = _data_.id > 0 ? _data_.id : 0;
        model.entity = element.entity;
        model.init = _data_.id > 0;
        model.data = _data_;
        model.selected = _data_;
        model.changed = {};
        model.changed.entity = value.entity;

        setModel(model);
        setDialog(true);
    };

    const moveUp = function (element, args) {
        move(args, -1);
    };

    const moveDown = function (element, args) {
        move(args, 1);
    };

    const move = function (args, pos) {
        let oldIndx = args.index;
        let newIndx = oldIndx + pos;
        let aux = [];

        if (newIndx < 0) {
            newIndx = list.length - 1;
        }

        if (newIndx >= list.length) {
            newIndx = 0;
        }

        if (newIndx >= list.length) {
            var k = newIndx - list.length + 1;
            while (k--) {
                list.push(undefined);
            }
        }
        list.splice(newIndx, 0, list.splice(oldIndx, 1)[0]);

        setList(list);

        all = [];

        for (const i in news) {
            all.push(news[i]);
        }
        for (const i in list) {
            const o = list[i];

            o.ordem = i * 10;

            if (o.id > 0) {
                aux.push({ id: o.id, ordem: o.ordem });
            } else {
                aux.push(o);
            }

            all.push(o);
        }
        setAll(all);

        Utils.set(value.field, changed, aux);
    };

    const clearData = function () {
        setList([]);
        setGroups([]);
        setTotalizer([]);
    }

    async function limpar(cfg, args) {
        let keyCache = getKeyCache();

        if (keyCache) {
            localStorage.removeItem(keyCache);
            window.location.reload();
        }
    }

    async function search(cfg, args) {
        if (!cfg) {
            cfg = value.list;
        }

        if (crud.list) {
            cfg = crud.list;
        }

        let keyCache = getKeyCache();

        if (keyCache) {
            let save = crudFilter.changed;

            save.query = query;

            localStorage.setItem(keyCache, JSON.stringify(save));
        }

        setReady(true);

        if (cfg) {
            if (!data) {
                data = {};
            }

            const complete = function (res) {
                setLoading(false);

                if (!cfg.download) {
                    const t = res.total;

                    if (res.data) {
                        for (const i in res.data) {
                            res.data[i].rowIndex = parseInt(i);
                        }
                        setList(res.data);

                        if (value.forceReference) {
                            Utils.set(value.field, data, res.data);
                            Utils.set(value.field, changed, res.data);
                        }

                        if (!res.groups) {
                            res.groups = [];
                        }

                        if (!res.totalizer) {
                            res.totalizer = [];
                        }
                        aggList = [];

                        if (res.aggregate) {
                            aggList = res.aggregate;
                        }

                        setAggList(aggList);

                        if (res.totalizer.length == 0) {
                            res.totalizer.push({ total: res.total });
                        }

                        for (const ti in res.totalizer) {
                            const tv = res.totalizer[ti];

                            if (typeof tv.count === 'undefined') {
                                tv.count = res.total;
                            }
                        }

                        setGroups(res.groups);
                        setTotalizer(res.totalizer);
                        setTotal(res.total);
                    } else {
                        setList([]);
                        setTotal(0);
                    }

                    if (t > 0) {
                        value.show();
                    } else {
                        value.hide();
                    }

                    let x = size * page;
                    let y = 0;
                    let z = x;

                    if (z > t) {
                        z = t;
                    }

                    if (page > 1) {
                        y = x - size;
                    }

                    detail = (y + 1) + '-' + z;

                    setDetail(detail);

                    refreshFileTypeButtons(res.data);
                }
            };

            let a = {};

            a.query = query;
            a.size = size;

            if (value.groupValue) {
                a.group = value.groupLabel + ',' + value.groupValue;
            }

            a.page = page;
            a.field = value.field;

            if (value.fields) {
                const fil = [];

                for (const i in value.fields) {
                    fil.push(value.fields[i]);
                }
                a.fields = fil.join(',');
            }

            a.filters = {};

            for (const i in filters) {
                let fil = filters[i];
                let name = fil.field ? fil.field : fil.name;
                let aux = name;

                if (typeof fil.filter === 'string') {
                    aux = fil.filter;
                }

                let val = crudFilter.changed[name];
                let dataType = fil.dataType ? fil.dataType : 'string';

                if (typeof val != 'undefined' && val !== null) {
                    if (typeof val === 'object') {
                        if (val.id) {
                            val = val.id;
                        } else if (val.constante) {
                            val = val.constante;
                        } else if (val.value) {
                            val = val.value;
                        }
                    }
                    a[aux] = val;
                }
            }

            a.parentClass = crud.entity;

            if (sortBy.name) {
                a.sortBy = sortBy.name;
                a.sortType = sortBy.sortType;
            }

            const mappedBy = value.mappedBy;

            if (mappedBy) {
                a[mappedBy] = parentId;
            } else {
                a.id = data.id;
            }


            if (args) {
                for (const i in args) {
                    a[i] = args[i];
                }
            }

            const _call_ = {};


            if (element.main && !query) {
                let qs = window.location.search;

                if (qs) {
                    qs = qs.substr(1, qs.length);

                    let aqs = qs.split('&');

                    for (let iq in aqs) {
                        let vs = aqs[iq];

                        let bqs = vs.split('=');

                        a[bqs[0]] = bqs[1];
                    }

                    if (a.query) {
                        setQuery(a.query);
                    }
                }
            }
            const aggregate = [];

            for (const ci in columns) {
                const cv = columns[ci];

                if (cv.aggregate) {
                    aggregate.push(cv.field + ':' + cv.aggregate);
                }
            }
            if (aggregate.length > 0) {
                a.aggregate = aggregate.join(',');
            }
            _call_.element = value;
            _call_.cfg = cfg;
            _call_.body = cfg.body;
            _call_.complete = complete;
            _call_.data = data;
            _call_.changed = crud.changed;
            _call_.search = a;
            _call_.crud = crud;
            _call_.view = view;
            _call_.onstart = function () {
                setLoading(true);
            }

            Utils.call(_call_);
        } else if (element.data) {
            setAll(element.data);
        }
    };

    value.refresh = search;

    const columns = [];
    const filters = [];
    const temp = [];

    let hasFilters = false;

    if (value.filters) {
        for (const i in value.filters) {
            const col = value.filters[i];

            if (!col.name) {
                col.name = i;
            }

            if (!col.field) {
                col.name = i;
            }

            if (!col.id) {
                col.name = col.name;
            }

            if (!col.type) {
                col.type = 'span';
            }
            if (!col.filter) {
                col.filter = true;
            }
            col.name = i;
            temp.push(col);
            hasFilters = true;
        }
    }

    if (value.columns) {
        for (const i in value.columns) {
            const col = value.columns[i];

            if (!col.name) {
                col.name = i;
            }

            if (!col.field) {
                col.name = i;
            }

            if (!col.id) {
                col.name = col.name;
            }

            if (!col.type) {
                col.type = 'span';
            }

            if (col.type === 'link' || col.format === 'link') {
                if (window.innerWidth < 1000) {
                    //continue;
                }
            }

            if (col.attrs) {
                for (const key in col.attrs) {
                    col[key] = col.attrs[key];
                }
            }

            if (typeof col.rendered === 'string') {
                let corv = utils.decode(col, crud, data, {});

                if (corv.rendered === false) {
                    continue;
                }
            }


            if (!col.field) {
                col.field = col.name ? col.name : i;
            }

            if (col.visible !== false) {
                col.name = i;
                columns.push(col);
            }

            if (col.filter && !hasFilters) {
                col.name = i;
                temp.push(col);
            }
        }
    }

    for (const i in temp) {
        const ft = temp[i];
        const fi = {};

        for (const ai in ft) {
            fi[ai] = ft[ai];
        }

        if (fi.type === 'span') {
            fi.type = 'text';
        }

        filters.push(fi);
    }

    useEffect(() => {
        if (!ready) {
            let keyCache = getKeyCache();

            if (keyCache) {
                let saved = localStorage.getItem(keyCache);

                if (saved) {
                    let json = JSON.parse(saved);

                    for (const i in json) {
                        crudFilter.data[i] = json[i];
                        crudFilter.changed[i] = json[i];
                    }
                    query = json.query;
                    setQuery(query);
                }
            }

            if (value.auto !== false) {
                search();
            } else {
                setReady(true);
            }
        }
    }, []);

    let rowCols = value.rowCols;

    let rowClassName = 'ui-grid-row';

    if (rowCols) {
        rowClassName += ' ui-grid-col-row ui-col ui-col-' + (12 / rowCols);
    }

    const keyPressed = function (event) {
        if (event.key === "Enter") {
            search(value.list);
        }
    }

    const onChangeQuery = function (event) {
        setQuery(event.target.value);
    }

    const onChangeSize = function (event) {
        size = parseInt(event.target.value);
        page = 1;

        setPage(page);
        setSize(size);
        search(value.list);
    }

    const paginate = function (p) {
        let x = total / size;

        if (p - x > 0 && p - x < 1) {
            x = p;
        }

        if (p > x || p <= 0) {
            return;
        }
        page = p;

        setPage(page);
        search(value.list);
    }
    const first = function () {
        paginate(1);
    }
    const previous = function () {
        paginate(page - 1);
    }
    const next = function () {
        paginate(page + 1);
    }
    const last = function () {
        let x = total / size;
        let y = parseInt(x);

        if (x > y) {
            y++;
        }
        paginate(y);
    }

    const add = function () {
        const mappedBy = value.list.mappedBy;

        if (typeof value.add === 'function') {
            value.add.call(this, {}, changed);
        } else if (typeof value.add === 'object') {
            const act = { click: value.add };

            if (typeof act.click.action === 'function') {
                act.click.action.call(this, act, changed);
            } else if (crud[act.click.action]) {
                crud[act.click.action].call(this, act, changed);
            } else if (act.click.redirect) {
                window.location.href = act.click.redirect;
            }
            return;
        } else if (mappedBy) {
            const isNew = !crud.selected.id > 0;

            if (mappedBy === 'parent') {
                model.edit = false;
                model.create = false;
                model.click = value.click;
                model.selected = {};
                model.id = -1;
            } else {
                let ent = value.entity;

                if (value.search) {
                    model.edit = false;
                    model.create = false;
                    model.selected = {};
                    model.changed = {};
                    model.click = {
                        action: 'rowSelect'
                    };
                } else {
                    model.edit = true;
                    model.create = true;
                    model.id = 0;
                    model.new = isNew;
                    model.selected = { id: 0 };
                }

                model.selected[mappedBy] = parentId;
                model.selected.entity = ent;
            }

            if (element.params) {
                const params = utils.decode(value.params, crud, data, {});

                if (params) {
                    for (const i in params) {
                        model.selected[i] = params[i];
                    }
                }
            }

            model.data = model.selected;
            model.changed = model.selected;

            setModel(model);
            setDialog(true);
        } else {
            crud.add(value);
        }
    }

    const getColumnClass = function (cl, data, header) {
        const res = Utils.decode(cl, crud, data);
        let cname = 'ui-grid-col ui-col-' + cl.name + (cl.click ? ' ui-col-act' : '');

        if (cl.columnStyleClass) {
            cname = cname + ' ' + res.columnStyleClass;
        }

        if (cl.cols) {
            cname = cname + ' ui-col ';

            if (cl.cols) {
                cname = cname + ' ui-col-' + cl.cols;
            }
        }

        if (cl.bold) {
            cname = cname + ' ui-bold ';
        }

        if (header) {
            cname = cname + ' ui-col-header ';
        }


        if (cl.type) {
            cname = cname + ' ui-col-type-' + cl.type + ' ';
        }

        if (res.bold) {
            cname = cname + ' ui-bold bold ';
        }

        if (res.disabled === true) {

            cname = cname + ' ui-disabled ';
        } else if (cl.click) {
            cname = cname + ' ui-click ';
        }

        return cname;
    }
    const getColumnStyle = function (cl, data) {
        const res = Utils.decode(cl, crud, data);

        if (res.columnStyle) {
            return res.columnStyle;
        }

        return {};
    }

    const getRowStyleClass = function (li, ib, header) {
        const res = Utils.decode(value, crud, li);

        let cname = rowClassName + ' ui-row-' + (ib % 2 == 0 ? 'even' : 'odd')

        if (res.rowStyleClass) {
            cname = res.rowStyleClass + ' ' + cname;
        }

        if (element.groups) {
            cname = cname + ' row ';
        }

        cname = cname + ' ui-row-' + ib + ' ';

        if (header) {
            cname = cname + ' ui-row-header ';
        }

        if (ib === value.selectedIndex) {
            cname = cname + ' ui-selected-row ';
        }

        if (header) {

        } else if (value.click || onClick) {
            cname = cname + ' ui-click ';
        }

        return cname;
    }
    const getRowStyle = function (li, ib) {
        const res = Utils.decode(value, crud, li);

        if (res.rowStyle) {
            return res.rowStyle;
        }

        return {};
    }

    const getSortClass = function (_col_) {
        if (_col_.sort !== true) {
            return '';
        }
        if (sortBy.name == _col_.name) {
            if (_col_.sortType == 'asc') {
                return 'ui-col-sort fa fa-angle-up';
            } else if (_col_.sortType == 'desc') {
                return 'ui-col-sort fa fa-angle-down';
            }
        }
        return 'ui-col-sort fa fa-sort';
    }
    const sortColumn = function (_col_) {
        if (_col_.sort !== true) {
            return;
        }

        if (_col_.sortType == 'asc') {
            _col_.sortType = 'desc';
        } else {
            _col_.sortType = 'asc';
        }

        setSortBy(_col_);
        search();
    }

    const groupFilter = function (val) {
        let key = value.groupValue;

        crud.changed[key] = val;
        crud.changed.group = '';

        value.selectedIndex = -1;
        selectedIndex = -1;

        setSelectedIndex(selectedIndex);

        search();
    }

    crud.expansion = function (value, args) {
        if (args.click) {
            if (args.index === value.selectedIndex) {
                value.selectedIndex = -1;
            } else {
                value.selectedIndex = args.index;
            }
            setSelectedIndex(value.selectedIndex);
            setIndex(index + 1);
            setSelectedItem(args.data);

            if (args.click.refresh !== false) {
                value.refresh();
            }
        }
    };

    const rowClick = function (_data_, _col_, index) {
        if (crud.selector !== true) {
            if (value.edit === false) {
                return;
            }
            if (_col_ && _col_.click === false) {
                return;
            }
        }

        let click = crud.click ? crud.click : value.click;
        let args = {};

        if (_col_ && _col_.click) {
            click = _col_.click;
        }

        if (_col_.disabled) {
            let corv = utils.decode(_col_, crud, _data_, {});

            if (corv && corv.disabled === true) {
                return;
            }
        }
        if (click && (click.disabled === true || crud.data.disabled)) {
            return;
        }

        const _userComplete_ = function () {
            const ccomp = click.complete;
            const closeDialogOnClick = typeof click.closeDialog === 'undefined' ? true : click.closeDialog;

            if (ccomp) {
                const ccall = typeof ccomp === 'string' ? crud[ccomp] : ccomp;

                if (ccall) {
                    ccall.call(this, value, args);
                }
            }
            if (closeDialogOnClick && value.closeDialog) {
                value.closeDialog();
            }
        };

        args.column = _col_;
        args.click = click;
        args.parent = data.id;
        args.data = _data_;
        args.index = index;

        if (value.list) {
            args.entity = value.list.entity;
        }

        const _execute_ = function () {
            if (onClick) {
                onClick.call(this, click, args, value);
            } else if (typeof click === 'function') {
                click.call(this, click, args);
            } else if (typeof click === 'object') {
                const act = click.action;

                if (act) {
                    if (act === 'moveUp') {
                        moveUp(value, args);
                    } else if (act === 'select') {
                        select(value, args);
                    } else if (act === 'moveDown') {
                        moveDown(value, args);
                    } else if (act === 'rowEdit') {
                        rowEdit(value, args);
                    } else if (act === 'remove') {
                        remove(value, args);
                    } else if (crud[act]) {
                        crud[act].call(this, value, args);
                    } else if (view[act]) {
                        view[act].call(this, value, args);
                    }
                    _userComplete_();
                } else if (click.url) {
                    const complete = function (res) {
                        _userComplete_();
                        setLoading(false);
                        search();
                    }

                    const _call_ = {};

                    _call_.element = value;
                    _call_.cfg = click;
                    _call_.complete = complete;
                    _call_.data = _data_;
                    _call_.parent = data.id;
                    _call_.crud = crud;
                    _call_.view = view;
                    _call_.onstart = function () {
                        setLoading(true);
                    };


                    Utils.call(_call_);
                } else if (click.redirect) {
                    let merge = {};

                    for (const i in _data_) {
                        merge[i] = _data_[i];
                    }

                    for (const i in data) {
                        merge[i] = data[i];
                    }
                    let dc = utils.decode(click, crud, merge);
                    let rd = dc.redirect;

                    for (const i in merge) {
                        rd = rd.replace('{' + i + '}', merge[i]);
                    }
                    window.location.href = rd;
                }
            }
        }
        _execute_.call(this);
    }

    const showFilter = function () {
        setFilter(!filter);
    };

    const downloadPdf = function () {
        const down = getDownloadSearch(value);
        down.url = getReportUrl(value.list.url) + '/report/pdf';
        down.fileName = 'file.pdf';
        search(down);
    };

    const downloadXls = function () {
        const down = getDownloadSearch(value);
        down.url = getReportUrl(value.list.url) + '/report/xls';
        down.fileName = 'file.xlsx';
        search(down);
    };

    const getDownloadSearch = function (value) {
        const down = {};
        down.method = 'POST';
        down.params = { id_card: value.card };
        if (value.list.params) {
            for (const i in value.list.params) {
                down.params[i] = value.list.params[i];
            }
        } else if (value.list.url.indexOf('?') > -1) {
            putUrlParams(down, value.list.url);
        }
        down.download = true;
        down.body = { element: value };
        return down;
    };

    const getReportUrl = function (url) {
        return url.indexOf('?') > -1 ? url.split('?')[0] : url;
    };

    const putUrlParams = function (down, url) {
        let strParams = url.indexOf('?') > -1 ? url.split('?')[1] : null;
        if (strParams) {
            let urlParamsList = [];
            if (strParams.indexOf('&') > -1) {
                urlParamsList = strParams.split('&');
            } else {
                urlParamsList.push(strParams);
            }
            for (const i in urlParamsList) {
                down.params[urlParamsList[i].split('=')[0]] = urlParamsList[i].split('=')[1];
            }
        }
    };

    const onChangeDateFilter = function (col, pos, el) {
        let v = el.target.value

        if (pos === 'start') {
            col.filterStart = v;
        }

        if (pos === 'end') {
            col.filterEnd = v;
        }

        if (col.filterStart && col.filterEnd) {
            col.filterValue = col.filterStart + ' | ' + col.filterEnd;
            search();
        }
    };

    const onChangeFilter = function (col, el) {
        let v = el.target.value

        col.filterValue = v;

        search();
    };

    const onKeyDownFilter = function (col, el) {
        let v = el.target.value

        col.filterValue = v;

        if (v.length >= 3) {
            search();
        }
    };

    const listContains = function (list, obj) {
        let retorno = false;
        if (obj.id) {
            retorno = list.some((listItem) => {
                return listItem.id === obj.id;
            });
        }
        return retorno;
    };

    const itens = [];

    itens.push({ label: 'Todos', value: 0 });
    itens.push({ label: '5', value: 5 });
    itens.push({ label: '10', value: 10 });
    itens.push({ label: '15', value: 15 });
    itens.push({ label: '20', value: 20 });
    itens.push({ label: '25', value: 25 });
    itens.push({ label: '30', value: 30 });
    itens.push({ label: '40', value: 40 });
    itens.push({ label: '50', value: 50 });
    itens.push({ label: '100', value: 100 });

    for (const i in news) {
        if (!listContains(all, news[i])) {
            all.push(news[i]);
        }
    }
    for (const i in list) {
        if (!listContains(all, list[i])) {
            all.push(list[i]);
        }
    }

    const nav = function (pos) {
        const el = document.getElementById(value.clientId);

        if (!el) {
            alert('Element not found: ' + value.clientId);
            return;
        }
        const grid = el.getElementsByClassName('ui-grid')[0];
        const col = el.getElementsByClassName('ui-grid-row')[0];
        const wid = col.clientWidth;
        let npos = grid.scrollLeft;

        if (value.layout === 'carousel-vertical') {
            npos = scrollTop;
        }

        setScrolling(true);

        if (value.layout === 'carousel-vertical') {
            if (pos < 0) {
                npos = npos + wid;
            } else {
                npos = npos - wid;
            }

            if (npos > 0) {
                return;
            }
        } else {
            if (pos < 0) {
                npos = npos - wid;
            } else {
                npos = npos + wid;
            }
        }
        if (value.layout === 'carousel-vertical') {
            scrollTop = npos;
            setScrollTop(npos);
        } else {
            grid.scrollLeft = npos;

            setScrollLeft(npos);
        }
        setTimeout(function () { setScrolling(false); }, 500)
    }

    const navLeft = function () {
        nav(-1);
    }

    const navRight = function () {
        nav(1);
    }

    const getFileAddedMessage = function (e) {
        return value.successMessage ? value.successMessage : "Arquivo " + e + " adicionado com sucesso.";
    }

    const onUpload = function (file, result) {
        let _list_ = [];
        const sel = {};

        const extension = file.extension ? file.extension : file.name.indexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) : 'none';

        sel.preview = false;
        sel.name = file.name;
        sel.extension = extension;
        sel.type = file.type;
        sel.size = file.size;
        sel.id = 0;
        sel.entity = value.entity;

        if (!file.extension) {
            file.extension = extension;
        }

        try {
            _list_ = eval('data.' + value.field);
        } catch (error) {
        }

        const mappedBy = value.list.mappedBy;
        const parentId = data[mappedBy] ? data[mappedBy].id : data.id;
        const cfg = value.upload;

        for (const i in cfg) {
            const v = cfg[i];

            if (file[i]) {
                if (typeof v === 'object') {
                    for (const vi in v) {
                        const va = v[vi];

                        sel[va] = file[i];
                    }
                } else {
                    sel[v] = file[i];
                }
            }
        }

        sel[mappedBy] = parentId;
        sel[cfg.value] = result;

        if (value.defaults) {
            for (const i in value.defaults) {
                sel[i] = value.defaults[i];
            }
        }

        const complete = function (res) {
            setLoading(false);
            search();
        }

        if (attachmentTypeField && attachmentTypeObj && !sel[attachmentTypeField]) {
            sel[attachmentTypeField] = attachmentTypeObj;
        }

        if (parentId > 0) {
            const _call_ = {};
            const put = {};

            put.method = 'POST';
            put.alert = false;
            put.url = value.entity + '/';

            _call_.element = value;
            _call_.cfg = put;
            _call_.complete = complete;
            _call_.data = sel;
            _call_.changed = sel;
            _call_.crud = crud;
            _call_.view = view;
            _call_.onstart = function () {
                setLoading(true);
            }
            Utils.call(_call_);
        } else {
            if (!_list_) {
                _list_ = [];
            }

            _list_.push(sel);

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            setNews(_list_);
            news = _list_;
        }
        search();
    }

    const onSaveFiles = function (files) {
        fileUploadDrop(files);
        setOpen(false);
        setAttachmentTypeField(null);
        setAttachmentTypeObj(null);
    }

    const fileUploadDrop = function (files) {

        for (const i in files) {
            const file = files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                onUpload(file, reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    if (value.widgetVar) {
        const w = {};

        w.element = value;
        w.search = search;
        w.clear = clearData;
        w.next = next;
        w.previous = previous;
        w.first = first;
        w.last = last;

        crud[value.widgetVar] = w;

        window[value.widgetVar] = w;
    }

    const gl = {
        type: 'list',
        styleClass: 'ui-grid-group',
        header: false, search: false, controls: false, size: 1000,
        columns: {
            label: {
                field: value.groupLabel
            },
            value: {
                field: 'total', bold: true
            }
        },
        click: {
            action: 'groupFilter'
        },
        data: groups
    };

    const getGridClass = function () {
        let cname = 'ui-search' + (groups.length > 0 || element.left ? ' ui-search-groups' : '');

        if (disabled) {
            cname += ' ui-grid-disabled ';
        }
        if (value.fixSelected && selectedIndex >= 0) {
            cname += ' ui-grid-fix-selected ';
        }
        return cname;
    }

    const getTotalizerRow = function (ib) {
        const x = [];

        for (const i in value.totalizer[ib]) {
            const v = value.totalizer[ib][i];

            if (!v.name) {
                v.name = i;
            }

            x.push(v);
        }
        return x;
    }
    const onClickItemGallery = function (i, a) {
        setSrc(a.props.children.props.src);
        setDialog(true);
    };

    gl.grid = value;

    const zoom = function (e) {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        var x = ((e.clientX - left) / width * 150) - 25;
        var y = ((e.clientY - top) / height * 150) - 25;

        document.getElementById('zoom-' + e.target.id).style.display = 'block';
        document.getElementById('imageZoom' + e.target.id).style.top = "-" + y + "%";
        document.getElementById('imageZoom' + e.target.id).style.left = "-" + x + "%";
    }

    const noZoom = function (e) {
        document.getElementById('zoom-' + e.target.id).style.display = 'none';
    }

    const isShowPrint = function () {
        if (window.innerWidth < 1000) {
            return false;
        }

        return !crud.selector && element.print === true;
    }

    const geClassFileTypeCheck = function (fileType, prmtList) {
        let retorno = "fas fa-times-circle text-danger fa-2x";
        let listaObjs = prmtList && prmtList.length > 0 ? prmtList : data[value.field];
        if (listaObjs && Array.isArray(listaObjs) && listaObjs.length > 0) {
            let containsItem = listaObjs.some((item) => {
                let typeObj = item[value.attrs.upload.fileType.field];
                return typeObj && typeObj.id === fileType.id;
            });
            if (containsItem) {
                retorno = "fas fa-check-circle text-success fa-2x";
            }
        }
        return retorno;
    }

    const refreshFileTypeButtons = function (prmtList) {
        if (value.fileTypeList) {
            value.fileTypeList.forEach((fileType) => {
                let icon = document.getElementById("i_" + fileType.id);
                icon.className = geClassFileTypeCheck(fileType, prmtList);
            });
        }
    }

    return (
        <>
            {value.gallery &&
                <>
                    {value.isZoom &&
                        <>
                            <Carousel onClickItem={onClickItemGallery} showStatus={value.showStatus === true} axis={value.axis} style={value.styleCarousel} showArrows={true}>
                                {all.map((li, ib) => (
                                    <div onMouseMove={zoom} onMouseOut={noZoom} id={ib}>
                                        <img src={li[value.itemValue]} className="imageFirst" />
                                    </div>
                                ))}
                            </Carousel>

                            {all.map((li, ib) => (
                                <div className="zoomMaster" id={"zoom-" + ib}>
                                    <img src={li[value.itemValue]} id={"imageZoom" + ib} />
                                </div>
                            ))}
                        </>
                    }
                    {!value.isZoom &&
                        <Carousel onClickItem={onClickItemGallery} showStatus={value.showStatus === true} axis={value.axis} style={value.styleCarousel} showArrows={true}>
                            {all.map((li, ib) => (
                                <div >
                                    <img src={li[value.itemValue]} />
                                </div>
                            ))}
                        </Carousel>
                    }
                </>
            }
            {!value.gallery &&

                <div id={id} className={getGridClass()}>

                    {value.search !== false && value.layout !== 'carousel' &&
                        <div className="ui-search-top">
                            <div className="ui-search-left">
                                <div id="fieldPesquisar" className="ui-search-box">
                                    <Form.Control value={query} onChange={onChangeQuery} onKeyPress={keyPressed} type="text" placeholder={utils.lng('query', 'Pesquisar')} />
                                    <a onClick={() => { search() }}>
                                        <i className="fa fa-search"></i>
                                    </a>
                                </div>
                                <div className="ui-search-top-controls">
                                    {crud && value.add && !disabled &&
                                        <Button id="btn_novo" onClick={add} variant="contained" color="secondary">
                                            <i className="fa fa-plus"></i>{Utils.lng('btn_novo', 'Novo')}
                                        </Button>
                                    }
                                    {typeof value.upload !== 'undefined' &&
                                        <>
                                            {value.fileTypeList &&
                                                <>
                                                    {value.fileTypeList.map((fileType, ib) => (
                                                        <>
                                                            <div className="ui-search-top-controls">
                                                                <div className="ui-search-top-controls ui-center" style={{ display: "block", paddingBottom: '5px' }}>
                                                                    <i id={'i_' + fileType.id} className={geClassFileTypeCheck(fileType)} style={{ display: "inline" }} ></i>
                                                                </div>
                                                                <Button disabled={disabled} variant="contained" color="primary" style={{ width: "40ch" }}
                                                                    onClick={() => {
                                                                        setAttachmentTypeField(value.attrs.upload.fileType.field);
                                                                        setAttachmentTypeObj(fileType);
                                                                        setOpen(true);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-upload"></i>
                                                                    {fileType.descricao ? fileType.descricao : value.selectLabel}
                                                                </Button>
                                                            </div>
                                                        </>
                                                    ))}
                                                </>
                                            }
                                            {!value.fileTypeList &&
                                                <>
                                                    <Button disabled={disabled} variant="contained" color="primary" onClick={() => setOpen(true)}>
                                                        <i className="fa fa-upload"></i>
                                                        {value.selectLabel ? value.selectLabel : value.label}
                                                    </Button>
                                                </>
                                            }
                                            <DropzoneDialog
                                                acceptedFiles={acceptedFiles}
                                                cancelButtonText={utils.lng('cancelar', 'Cancelar')}
                                                submitButtonText={Utils.lng('enviar', 'Enviar')}
                                                dropzoneText={value.dropLabel}
                                                filesLimit="10"
                                                dialogTitle={value.label}
                                                getFileAddedMessage={getFileAddedMessage}
                                                maxFileSize={maxFileSize}
                                                open={open}
                                                onClose={() => setOpen(false)}
                                                onSave={(files) => {
                                                    onSaveFiles(files);
                                                }}
                                            />
                                        </>
                                    }
                                    {filters.length > 0 && value.filter !== false &&
                                        <>
                                            <Button onClick={showFilter} variant="contained" color="primary">
                                                <i className="fa fa-filter" id="btn_filtro"></i>{Utils.lng('btn_filtro', 'Filtro')}
                                            </Button>
                                        </>
                                    }
                                    {isShowPrint() &&
                                        <>
                                            <Button onClick={downloadPdf} variant="contained" color="primary">
                                                <i className="fa fa-file-pdf"></i>
                                            </Button>
                                            <Button onClick={downloadXls} variant="contained" color="primary">
                                                <i className="fa fa-file-excel"></i>
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {filter &&
                        <>
                            {filters.length > 0 &&
                                <div className="ui-search-filters">
                                    {filters.map((fi, ib) => (
                                        <div id={'field_filter'} key={'filter-' + ib} className="ui-filter-item">
                                            <Element crud={crudFilter} data={changedFilter} value={fi}></Element>
                                        </div>
                                    ))}
                                    <div className="ui-filter-options">
                                        <div id='limpar_filtro' className="ui-filter-clear">
                                            <Button onClick={() => { limpar() }} variant="contained" color="primary">
                                                <i className="fa fa-trash"></i><span style={{ paddingLeft: '10px' }}>{Utils.lng('limpar_filtro', 'Limpar filtro')}</span>
                                            </Button>
                                        </div>
                                        <div id="aplicar_filtro" className="ui-filter-apply">
                                            <Button onClick={() => { search() }} variant="contained" color="primary">
                                                <i className="fa fa-search"></i><span style={{ paddingLeft: '10px' }}>{utils.lng('aplicar_filtro', 'Aplicar filtro')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {value.layout === 'carousel' &&
                        <div className="ui-search-nav-left" onClick={navLeft}>
                            <i className="fa fa-angle-left" />
                        </div>
                    }
                    {value.layout === 'carousel-vertical' &&
                        <div className="ui-search-nav-left" onClick={navLeft}>
                            <i className="fa fa-angle-up" />
                        </div>
                    }
                    <div className="ui-search-data">
                        {loading &&
                            <div>
                                <div className="ui-loading">
                                    <i className="fas fas fa-circle-notch fa-spin"></i>
                                </div>
                            </div>
                        }
                        {!loading &&
                            <>
                                {groups.length > 0 &&
                                    <div className="ui-search-data-left">
                                        <ul className="ui-group-list">
                                            <li className="ui-group-filter-item" onClick={() => { groupFilter(null) }}>
                                                <b>Todos  <span className="ui-group-filter-total">{total}</span></b>
                                            </li>
                                            {groups.map((li, ib) => (
                                                <li onClick={() => { groupFilter(li[value.groupValue]) }} className="ui-group-filter-item">
                                                    <b>{li[value.groupLabel]}
                                                        <span className="ui-group-filter-total">{li.total}
                                                        </span>
                                                    </b>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                {element.left &&
                                    <div className="ui-search-data-left">
                                        <Element parent={value} view={view} crud={crud} value={left} disabled={disabled} />
                                    </div>
                                }
                                <div className="ui-search-data-right">
                                    <div className={scrolling ? 'ui-grid ui-grid-scrolling' : 'ui-grid'} style={{ marginTop: scrollTop + 'px' }}
                                        scrollTop={scrollTop} scrollleft={scrollLeft}>
                                        {(all.length > 0 || all.length > 0) &&
                                            <>
                                                {value.groups &&
                                                    <>
                                                        {value.groups.map((li, ib) => (
                                                            <div className={getRowStyleClass(li, -1, true) + ' ui-row-group'}>
                                                                {value.groups[ib].map((cl, ia) => (
                                                                    <div onClick={() => { sortColumn(cl) }} key={ia} style={getColumnStyle(cl, li)}
                                                                        className={getColumnClass(cl, li, true)}>
                                                                        {utils.lng(cl.name, cl.label)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                                {value.totalizer &&
                                                    <>
                                                        {value.totalizer.map((tt, ia) => (
                                                            <div className={getRowStyleClass({}, -1, true) + ' ui-row-group ui-row-total'}>
                                                                {getTotalizerRow(ia).map((cl, ci) => (
                                                                    <>
                                                                        {totalizer.map((li, ib) => (
                                                                            <div onClick={() => { sortColumn(cl) }} key={ia} style={getColumnStyle(cl, li)}
                                                                                className={getColumnClass(cl, li, true)}>
                                                                                <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={true}
                                                                                    value={cl} disabled={disabled} index={ib + '_tot_' + ia} data={li} changed={li} />
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                                {value.header !== false &&
                                                    <div className={getRowStyleClass({}, -1, true)}>
                                                        {columns.map((cl, ia) => (
                                                            <div onClick={() => { sortColumn(cl) }} key={ia} className={getColumnClass(cl, ia, true)} >
                                                                {cl.headerLabel !== false &&
                                                                    <>
                                                                        {utils.lng(cl.name, cl.label)}<i className={getSortClass(cl)}></i>
                                                                    </>
                                                                }
                                                                {cl.type === 'checkbox' && value.checkAllFunction &&
                                                                    <>
                                                                        <Checkbox checked={allChecked} onChange={() => {
                                                                            setAllChecked(!allChecked);
                                                                            allChecked = !allChecked;
                                                                            setTimeout(() => value.checkAllFunction(allChecked, all), 1000);
                                                                        }}> </Checkbox>
                                                                    </>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                {all.map((li, ib) => (
                                                    <>
                                                        {(!value.fixSelected || (ib === value.selectedIndex || selectedIndex < 0)) &&
                                                            <>
                                                                <div key={ib} className={getRowStyleClass(li, ib)} style={getRowStyle(li, ib)}>
                                                                    {columns.map((cl, ibc) => (
                                                                        <div onClick={() => { rowClick(li, cl, ib) }} key={ibc} style={getColumnStyle(cl, li)} className={getColumnClass(cl, li)}>
                                                                            <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={typeof cl.rowEdit === 'undefined' && typeof cl.readOnly !== 'undefined' ? cl.readOnly : !cl.rowEdit}
                                                                                value={cl} disabled={disabled} index={ibc} data={li} changed={li} />
                                                                        </div>
                                                                    ))}
                                                                    {value.elements &&
                                                                        <div className="ui-col-element">
                                                                            {Object.entries(value.elements).map((el, index) => (
                                                                                <Element parent={value} view={view} crud={crud} name={el[0]} key={'col' + index}
                                                                                    value={el[1]} disabled={disabled} value={el[1]} index={index} data={li} changed={li} />
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {(!value.fixSelected && ib === value.selectedIndex) &&
                                                                    <div className="ui-grid-row ui-grid-sub">
                                                                        <div className="ui-grid-col ui-col-12">
                                                                            <Element parentQuery={query} id={'sub-' + ib} value={value.sub} crud={crud} data={li} view={view} ></Element>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                ))}
                                                {aggList.map((li, ib) => (
                                                    <>
                                                        {(!value.fixSelected || (ib === value.selectedIndex || selectedIndex < 0)) &&
                                                            <>
                                                                {li.pontos &&
                                                                    <div key={ib} className={getRowStyleClass(li, ib) + ' ui-row-agg agglistClass'} style={getRowStyle(li, ib)}>
                                                                        {columns.map((cl, ibc) => (
                                                                            <div key={ibc} style={getColumnStyle(cl, li)} className={getColumnClass(cl, li)}>
                                                                                {li[cl.field] &&
                                                                                    <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={!cl.rowEdit}
                                                                                        value={cl} disabled={disabled} index={ibc} data={li} changed={li} />
                                                                                }
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                                {!li.pontos &&
                                                                    <div key={ib} className={getRowStyleClass(li, ib) + ' ui-row-agg'} style={getRowStyle(li, ib)}>
                                                                        {columns.map((cl, ibc) => (
                                                                            <div onClick={() => { rowClick(li, cl, ib) }} key={ibc} style={getColumnStyle(cl, li)} className={getColumnClass(cl, li)}>
                                                                                {li[cl.field] &&
                                                                                    <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={!cl.rowEdit}
                                                                                        value={cl} disabled={disabled} index={ibc} data={li} changed={li} />
                                                                                }
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                        {all.length <= 0 &&
                                            <div className="ui-grid-row ui-col ui-col-12">
                                                <div className="ui-grid-col ui-col-empty">
                                                    {typeof value.empty !== 'undefined' ? value.empty : utils.lng('no_results', 'Nenhum registro encontrado')}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {(value.fixSelected && selectedIndex >= 0) &&
                                        <div className="ui-grid-sub">
                                            <Element parentQuery={query} id={value.clientId + 'sub'} value={value.sub} crud={crud} data={selectedItem} view={view} ></Element>
                                        </div>
                                    }
                                    {value.controls !== false && value.layout !== 'carousel' && list.length > 0 &&
                                        <div className="ui-grid-controls">
                                            <div className="ui-grid-controls-right">
                                                <span className="ui-search-feedback-rows">
                                                    {utils.lng('linhas', 'Linhas')}
                                                </span>
                                                <Form.Control value={size} onChange={onChangeSize} as="select">
                                                    {itens.map((i, io) => (
                                                        <option key={io} value={i.value} index={io} >{i.label}</option>
                                                    ))}
                                                </Form.Control>
                                                {value.feedback !== false &&
                                                    <div className="ui-search-feedback">
                                                        {detail} {utils.lng('linhas_de', 'de')}  {total}
                                                    </div>
                                                }
                                                <a onClick={first}>
                                                    <i className="fa fa-fast-backward"></i>
                                                </a>
                                                <a onClick={previous}>
                                                    <i className="fa fa-arrow-left"></i>
                                                </a>
                                                <a onClick={next}>
                                                    <i className="fa fa-arrow-right"></i>
                                                </a>
                                                <a onClick={last}>
                                                    <i className="fa fa-fast-forward"></i>
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {value.layout === 'carousel' &&
                        <div className="ui-search-nav-right" onClick={navRight}>
                            <i className="fa fa-angle-right" />
                        </div>
                    }
                    {value.layout === 'carousel-vertical' &&
                        <div className="ui-search-nav-right" onClick={navRight}>
                            <i className="fa fa-angle-down" />
                        </div>
                    }
                </div>
            }
            {dialog &&
                <>
                    <div className="ui-overlay" onClick={close}>
                    </div>
                    <div className="ui-dialog">
                        <div className="ui-dialog-header">
                            {value.label}
                            <div className="ui-dlg-close" onClick={close}>
                                <i className="fa fa-times"></i>
                            </div>
                        </div>
                        <div className="ui-dialog-data">
                            {!element.gallery &&
                                <Crud disabled={disabled} view={view} value={model} user={user} onClick={onClick} />
                            }
                            {element.gallery &&
                                <img src={src}></img>
                            }
                        </div>
                    </div>
                </>
            }

        </>
    )
};


const Grid = ({ parentQuery, parent, id, value, disabled, data, changed, onClick, crud, user }) => {
    let [openDialog, setOpenDialog] = useState(false);
    let element = Utils.decode(value, crud, data);
    let [actions, setActions] = useState(value.actions ? value.actions : []);

    const showDialog = function () {
        setOpenDialog(true);
    };


    const handleCloseDialog = function () {
        if (openDialog) {
            if (value.onCloseDialog) {
                value.onCloseDialog.call(this);
            }
            value.refresh();
            setOpenDialog(false);
        }
    };

    value.closeDialog = handleCloseDialog;
    element.closeDialog = handleCloseDialog;

    const actDlgClick = function (act) {
        if (act.action === 'close') {
            handleCloseDialog();
        } else {
            act.complete = handleCloseDialog;

            value.execute(act);
        }
    }


    if (value.autoOpen) {
        openDialog = true;
    }

    return (
        <>
            {!value.dialog &&
                <GridInner parent={parent} parentQuery={parentQuery} onClick={onClick} id={id} element={element} disabled={disabled} value={value} data={data} changed={changed} crud={crud} user={user}></GridInner>
            }
            {value.dialog &&
                <>
                    {!value.autoOpen &&
                        <Button disabled={disabled} variant="contained" color="primary" onClick={showDialog}>
                            {element.label}
                        </Button>

                    }
                    <Dialog maxWidth="lg" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                            {element.label}
                        </DialogTitle>
                        <DialogContent>
                            <GridInner onClick={onClick} id={id} element={element} disabled={disabled} value={value} data={data} changed={changed} crud={crud} user={user}></GridInner>
                        </DialogContent>
                        <DialogActions>
                            {actions.length <= 0 &&
                                <Button onClick={handleCloseDialog} color="primary"> {utils.lng('fechar', 'FECHAR')}</Button>
                            }
                            {actions.length > 0 &&
                                <>
                                    {actions.map((ac, i) => (
                                        <Button onClick={() => { actDlgClick(ac) }} color="primary">
                                            {ac.label}
                                        </Button>
                                    ))}
                                </>
                            }
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
};

export default Grid