import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import utils from './utils';

function TrocarPai() {
  let data = {};
  let [view, setView] = useState({});
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ data: data, changed: data, requireds: {} });

  view.refresh = function () {
    setIndex(index + 1);
  }

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  const cconsultor = {
    name: 'consultor',
    elements: {
      dados: {
        label: 'Alterar Pai',
        type: 'legend',
        elements: {
          card_geracao: {
            type: 'card',
            styleClass: 'nomargin-left',
            label: 'Informe o pai de origem e destino',
            elements: {
              pai_origem: {
                space: 6,
                field: 'origem',
                type: 'entity',
                label: utils.lng('origem','Origem'),
                entity: 'Cliente',
                required: true,
                list: {
                  url: 'listarClientesFluxo',
                  send: false
                }
              },
              pai_destino: {
                space: 6,
                field: 'destino',
                type: 'entity',
                label: utils.lng('destino','Destino'),
                entity: 'Cliente',
                required: true,
                list: {
                  url: 'listarClientesFluxo',
                  send: false
                }
              },
              transferir_consultores: { 
                field: 'transferir_consultores', 
                required: false, 
                label: utils.lng('transferir_consultores','Transferir Consultores?'), 
                type: 'checkbox', 
                space: 12
              }
            }
          },
          bloco_botoes: {
            type: 'card',
            styleClass: 'nomargin-left',
            elements: {
              botoes: {
                style: { marginTop: '0',textAlign:'right' },
                elements: {
                  salvar: {
                    label: utils.lng('salvar','Salvar'),
                    type: 'button',
                    theme: 'primary',
                    click: {
                      method: 'POST',
                      url: 'trocarPai',
                      send: {
                        id_origem: '${@this.origem.id}',
                        id_destino: '${@this.destino.id}',
                        transferir_consultores: '${@this.transferir_consultores}'
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <View name="troca-api" view={view} >
      <Element value={cconsultor} crud={crud} data={crud.data} view={view} ></Element>
    </View >
  );
}

export default TrocarPai;