import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';

function RelatorioConsultor() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const consultores = {
    label: 'Consultores',
    add: true,
    type: 'list',
    list: {
      url: 'Cliente',
      send: {
        tipo_constante: 'CONSULTOR'
      }
    },
    columns: {
      avatar: { field: 'avatar', styleClass: 'noppading' },
      nome: { field: 'nome', label: 'Nome', filter: true, sort: true }
    }
  };

  return (
    <View name="rel-consultor" className="ui-view-report">
      <Element value={consultores} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioConsultor;