import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioApuracaoSpv() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('apuracao_spv','Apuração de SPV'),
    name: 'reportCfg',
    add: false,
    print:true,
    auto: false,
    type: 'list',
    list: {
      url: 'report_apuracao_spv'
    },
    filters: {
      mes: {
        type: 'select',
        label: utils.lng('mes', "Mês"),
        data: [
          //{ label: utils.lng('selecione', "Selecione"), value: null },
          { label: utils.lng('janeiro', "Janeiro"), value: '0' },
          { label: utils.lng('fevereiro', "Fevereiro"), value: '1' },
          { label: utils.lng('marco', "Março") , value: '2' },
          { label: utils.lng('abril', "Abril"), value: '3' },
          { label: utils.lng('maio', "Maio"), value: '4' },
          { label: utils.lng('junho', "Junho"), value: '5' },
          { label: utils.lng('julho', "Julho"), value: '6' },
          { label: utils.lng('agosto', "Agosto"), value: '7' },
          { label: utils.lng('setembro', "Setembro"), value: '8' },
          { label: utils.lng('outubro', "Outubro"), value: '9' },
          { label: utils.lng('novembro', "Novembro"), value: '10' },
          { label: utils.lng('dezembro', "Dezembro"), value: '11' },
        ]
      },
      ano: {
        type: 'number',
        label: utils.lng('ano', "Ano"),
        mask: '9999',
        field:'ano'
      },
      id_plano_marketing: {
        type: 'select',
        list: { url: 'PlanoMarketing' },
        label: utils.lng('plano_marketing', "Plano Marketing"),
        itemLabel: 'descricao',
        itemValue: 'id'
      }
    },
    totalizer: [
      {
        valor_total_pedidos: { field: 'valor_total_pedidos', value: "Total Liquidado/Faturado: #{utils.number(@this.valor_total_pedidos)}" , style: {float: "left"} }
      },
      {
        spv_distribuido: { field: 'spv_distribuido', value: "SPV para Distribuição: #{utils.number(@this.spv_distribuido)}", style: {float: "left"} }
      }
    ],
    columns: {
      codigo_mundial: { field: 'codigo_mundial', label: utils.lng('cod_mundial', "Cód. Mundial"), filter: false, sort: false },
      codigo_referencia: { field: 'codigo_referencia', label: utils.lng('cod_sapiens', "Cód. Sapiens"), filter: false, sort: false },
      nome: { field: 'nome', label: utils.lng('nome', "Nome"), filter: false, sort: false },
      spv_direto: { aggregate: 'sum', field: 'spv_direto', format: 'number', label: utils.lng('spv_direto', "SPV Direto"), filter: false, sort: false },
      spv_graduacao: { aggregate: 'sum', field: 'spv_graduacao', format: 'number', label: utils.lng('spv_graduacao', "SPV Graduação"), filter: false, sort: false },
      total_spv: { aggregate: 'sum', field: 'total_spv', format: 'number', label: utils.lng('total_spv', "Total SPV"), filter: false, sort: false }
    }
  };

  return (
    <View className="ui-view-report" name="rel-apuracao_spv">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioApuracaoSpv;