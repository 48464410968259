import React, { useState } from "react";
import Element from './element';
import View from './view';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

function FluxoCliente() {
  let [view, setView] = useState({});
  let [cliente, setCliente] = useState({});
  let [status, setStatus] = useState([]);
  let [acoes, setAcoes] = useState([]);
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [classificacao, setClassificacao] = useState({});
  let [ready, setReady] = useState(false);
  let [opened, setOpened] = useState(false);
  let [index, setIndex] = useState(0);
  let [observacao, setObservacao] = useState('');
  let [itens, setItens] = useState({});
  let [access, setAccess] = useState(window.access ? window.access : {});

  let cfg = {
    name: 'itens',
    type: 'list',
    layout: 'list',
    label: utils.lng('fluxo_clientes', 'Fluxo de Clientes'),
    size: 50,
    controls: false,
    showLabel: true,
    styleClass: 'ui-fluxo-grid',
    header: false,
    rowStyleClass: "${'ui-fluxo-row ui-fluxo-row-' + @this.constante}",
    list: {
      url: '/listarFluxoCliente?qs=false'
    },
    columns: {
      total: { type: 'span', filter: false, field: 'total', columnStyle: { background: '#{@this.cor}' }, columnStyleClass: "${'ui-fluxo-total ui-fluxo' + @this.constante_status}" },
      descricao: { type: 'span', filter: false, columnStyleClass: 'ui-fluxo-descricao', bold: true },
      detalhar: {
        columnStyleClass: 'ui-fluxo-expansion', style: { columnStyle: '#{@this.cor}' },
        icon: "fa fa-arrow-right"
      }
    },
    click: {
      action: "expansion",
      loading: false
    },
    sub: {
      name: 'clientes',
      type: 'list',
      layout: 'table',
      label: 'Clientes',
      rows: 10,
      add: false,
      showLabel: true,
      header: false,
      list: {
        url: '/listarClientesFluxo?status={id_status}'
      },
      columns: {
        abrir: {
          icon: "fa fa-book",
          click: {
            action: "open",
            url: '/crud/cliente/{id_cliente}'
          }
        },
        codigo: { type: 'span' },
        nome: { type: 'span' },
        tipo_descricao: { type: 'span' },
        status_descricao: { type: 'span', filter: false, columnStyleClass: 'ui-fluxo-status', bold: true },
        filial: { type: 'span' },
        dias_ultima_alteracao: { type: 'span', filter: false, bold: true, value: '${@this.dias_ultima_alteracao + " " + utils.lng("dias","dias")}' },
        detalhar: {
          icon: "fa fa-cogs",
          click: {
            action: "popup"
          },
          options: {
            icone: { type: 'icon' },
            descricao: {}
          },
          list: {
            url: 'listarFluxoCliente'
          }
        }
      }
    }
  };

  if (!ready) {
    setReady(true);

    utils.execute(crud, 'listarFluxoCliente', null, function (res) {
      setStatus(res.data);
    });
  }


  crud.globalQuery = function (el, args) {
    const d = args.data;

    console.log()
  }

  crud.open = function (value, args) {
    const click = value.click ? value.click : args.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    utils.open(d);
  }

  crud.popup = function (v, args) {
    if (args.click) {
      const c = args.data;

      console.log(c)

      utils.execute(crud, 'listarAcoesCliente?status=' + c.status, null, function (res) {
        setCliente(c);
        setOpened(true);
        setAcoes(res.data);
        setObservacao('');
      });
    }
  };

  const selecionarAcao = function (a) {
    for (const i in acoes) {
      const s = acoes[i];

      s.selected = false;
    }
    crud.acao = a;
    crud.acao.selected = true;

    setIndex(index + 1);
  };

  const confirmar = function () {
    const _call_ = {};
    const put = {};
    const sel = {};

    put.method = 'PUT';
    put.alert = true;
    put.url = 'Cliente/' + cliente.id;

    sel.observacao = observacao;

    if (crud.acao) {
      sel.status = crud.acao.id_status;
    }

    _call_.global = true;
    _call_.element = {};
    _call_.cfg = put;
    _call_.complete = function () {
      setOpened(false);
      itens.refresh();
    };
    _call_.data = sel;
    _call_.changed = sel;
    _call_.crud = crud;
    _call_.view = view;

    utils.call(_call_);
  };

  const onChangeObservacao = function (event) {
    let val = event.target.value;

    setObservacao(val);
  }

  const closeDialog = function () {
    setOpened(false);
  }

  const opts = {
    elements: {
      ver_entrevista: {
        rendered: '${exists(@this,"id_entrevista")}',
        type: 'link',
        value: 'Ver entrevista',
        href: "${'/ver_entrevista/' + @this.id_entrevista}"
      },
      ver_consultor: {
        type: 'link',
        value: 'Ver consultor',
        href: "${'/consultores/' + @this.id_consultor}"
      }
    }
  };

  view.onCheck = function (a) {
    setCrud(crud);
    setItens(itens);

    access = a;

    if (access.CADASTRAR !== false) {
      cfg.add = {
        action: "open",
        url: '/crud/Cliente/0'
      };
    }
    setItens(cfg);

    setAccess(a);
    setIndex(index + 1);
  }

  const confirmaAtivacao = function () {
    let _ks_ = {};

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = {};
    _ks_.element = {};

    _ks_.cfg = {
      url: `confirma/ativacao/cliente/${cliente.id}`,
      method: 'POST'
    };
    _ks_.complete = function(res) {
      setOpened(false);
      itens.refresh();
    }
    utils.call(_ks_);
  }
  return (
    <View name="ui-fluxo" view={view}>
      <div className="ui-fluxo-data">
        <Dialog
          open={opened}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{utils.lng('escolha_uma_acao', 'Escolha uma ação')}</DialogTitle>
          <DialogContent>
            {cliente.exists_consultor && cliente.verificacao_ativacao_pendente !== true &&
              <Element crud={crud} value={opts} data={cliente} view={view} />
            }

            {cliente.status_constante == 'ATIVO' && cliente.verificacao_ativacao_pendente === true &&
              <Button onClick={confirmaAtivacao} style={{ paddingBottom: '20px', display: 'block' }} >
                {utils.lng('cadastro_validado', 'Cadastro Validado')}
              </Button>
            }

            <div className="ui-client-opts">
              {acoes.map((li, ib) => (
                <div className="ui-opt-item" onClick={() => { selecionarAcao(li) }}>
                  <div className="ui-opt-icon">
                    <i className={li.selected ? ' far fa-check-circle' : ' far fa-circle'} ></i>
                  </div>
                  <div className="ui-opt-description">
                    {li.descricao}
                  </div>
                </div>
              ))}
            </div>
            <div className="ui-opt-observacao" style={{ marginTop: '15px' }}>
              <TextField variant="outlined" label={utils.lng('observacao', 'Observação')} rows={3} multiline={true}
                value={observacao} onChange={onChangeObservacao}> </TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} variant="contained" color="primary">{utils.lng('fechar', 'Fechar')}</Button>
            <Button onClick={confirmar} variant="contained" color="primary">{utils.lng('confirmar', 'Confirmar')}</Button>
          </DialogActions>
        </Dialog>
        <Element value={itens} crud={crud} data={crud.data} view={view} ></Element>
      </div>
    </View >
  );
}


export default FluxoCliente;