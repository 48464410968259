import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';

function Informativos() {
  let [view, setView] = useState({});
  let [element, setElement] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [access, setAccess] = useState(window.access ? window.access : {});

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.setTipo = function (el, args) {
    crud.changed.tipoInformativo = args.data.constante;
    crud.data.tipoInformativo = args.data.constante;
    crud.tipoInformativo = args.data.constante;

    if (window.informativo) {
      window.informativo.search();
    }
  }


  view.onCheck = function (a) {
    access = a;

    element = {
      name: 'itens',
      type: 'list',
      layout: 'table',
      styleClass: "ui-component ui-grid-card",
      label: 'Informativos e Downloads',
      rows: 10,
      size: 20,
      widgetVar: 'informativo',
      showLabel: true,
      header: false,
      list: {
        url: 'listarInformativos'
      },
      rowStyleClass: "ui-border-${@this.ativo ? 'green' : 'red'} ui-border-left",
      groupValue: "tipo_constante",
      groupLabel: "tipo_descricao",
      columns: {
        icone: { type: 'icon', value: 'fa fa-file', columnStyle: { background: '#888', color: '#fff', fontSize: '20px', width: '80px', textAlign: 'center' } },
        detalhes: {
          elements: {
            titulo: { field: 'titulo', type: 'span' },
            data_informativo: { field: 'data_informativo', type: 'datetime', bold: true }
          }
        },
        descricao: { type: 'span' },
        link_conteudo: {
          columnStyleClass: 'WhiteColor',
          columnStyle: { background: '#013A7B', fontSize: '20px', width: '80px', textAlign: 'center' },
          link: 'icon',
          type: 'link',
          preview: false,
          target: '_blank',
          icon: 'fa fa-download'
        }
      }
    };


    if (access.CADASTRAR) {
      element.add = {
        redirect: 'crud/Informativo/0'
      };
      element.click = {
        redirect: 'crud/Informativo/{id}'
      };
    }
    setElement(element);
    setAccess(a);
    setIndex(index + 1);
  }

  return (
    <View name="informativos" view={view}>
      <Element id={'i' + index} value={element} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default Informativos;