import React, { useEffect, useState } from "react";
import View from './view';
import WebViewer from '@pdftron/webviewer';
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { useParams } from "react-router-dom";
import utils from "./utils";

function Assinatura() {
  let [url, setUrl] = useState(null);
  let [instance, setInstante] = useState(null);
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let { id_contrato, id_cliente, fase } = useParams();
  let [codigo, setCodigo] = useState(null);
  let [assinar, setAssinar] = useState(false);
  let [enviar, setEnviar] = useState(false);
  let [loading, setLoading] = useState(true);
  let [anManager, setAnManager] = useState(null);
  let [annotPosition, setAnnotPosition] = useState(0);
  let [view, setView] = useState({});


  view.loadingEnd = function () {
    loading = false;
    setLoading(false);
  };

  view.loadingStart = function () {
    loading = true;
    setLoading(true);
  };


  window.view = view;

  const onLoadDocument = async function (annotationManager, res) {
    loading = false;
    setLoading(false);

    annotationManager.on('annotationChanged', (annotations, action, { imported }) => {
      const { docViewer, annotManager, Annotations } = instance;

      const normalStyles = (widget) => {
        if (widget instanceof Annotations.TextWidgetAnnotation) {
          return {
            'background-color': '#a5c7ff',
            color: 'white',
          };
        } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
          return {
            border: '1px solid #a5c7ff',
          };
        }
      };

      if (imported && action === 'add') {
        annotations.forEach(function (annot) {
          if (annot instanceof Annotations.WidgetAnnotation) {
            Annotations.WidgetAnnotation.getCustomStyles = normalStyles;

            if (!annot.fieldName.startsWith('_' + fase)) {
              annot.Hidden = true;
              annot.Listable = false;
            }
          }
        });
      }
    });

    if (res.data.assinar) {
      await annotationManager.importAnnotations(res.data.assinar);
    }

    if (res.data.assinatura) {
      await annotationManager.importAnnotations(res.data.assinatura);
    }

    if (res.data.testemunha) {
      await annotationManager.importAnnotations(res.data.testemunha);
    }
  }

  useEffect(() => {
    if (fase == 'finish') {
      loading = false;
      setLoading(false);
    }

    if (!instance && fase !== 'finish') {
      const _ks_ = {};

      _ks_.global = true;

      WebViewer(
        {
          path: '/pdftron',
          fullAPI: true,
          disabledElements: [
            'ribbons',
            'toggleNotesButton',
            'contextMenuPopup',
          ]
        },
        document.getElementById('viewer')
      ).then(is => {
        const { docViewer, annotManager, PDFNet, Annotations } = is;

        PDFNet.initialize();

        instance = is;
        anManager = annotManager;
        setInstante(is);
        setAnManager(annotManager);

        window.PDFNet = PDFNet;

        if (fase !== 'prepare') {
          utils.execute(crud, `signing/contract/${id_contrato}/${id_cliente}`, null, function (res) {
            codigo = res.data.codigo;

            let ass = res.data.assinar;

            if (!ass) {
              assinar = true;
            }

            let down = utils.linkDownload('contrato/' + id_contrato + '/' + id_cliente + '.pdf');

            url = down;
            setUrl(url);
            docViewer.loadDocument(down);

            docViewer.addEventListener('documentLoaded', () => {
              onLoadDocument(annotManager, res);
            })
          });
        } else {
          let down = utils.linkDownload('contrato/' + id_contrato + '/' + id_cliente + '.pdf');

          url = down;
          docViewer.loadDocument(down);

          docViewer.addEventListener('documentLoaded', () => {
            onLoadDocument(annotManager, { data: {} });
          })
        }
      });
    }
  });

  const addField = (prefix, type, point = {}, value = '', flag = {}) => {
    const _name_ = `_${prefix}_${type}_`;
    const { docViewer, Annotations } = instance;
    const annotManager = docViewer.getAnnotationManager();
    const doc = docViewer.getDocument();
    const displayMode = docViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx =
      page.first !== null ? page.first : docViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = docViewer.getZoom();

    var textAnnot = new Annotations.FreeTextAnnotation();
    textAnnot.PageNumber = page_idx;
    const rotation = docViewer.getCompleteRotation(page_idx) * 90;
    textAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = 50.0 / zoom;
      textAnnot.Height = 250.0 / zoom;
    } else {
      textAnnot.Width = 250.0 / zoom;
      textAnnot.Height = 50.0 / zoom;
    }
    textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
    textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
    textAnnot.custom = {
      type,
      value,
      flag,
      name: _name_
    };

    // set the type of annot
    textAnnot.setContents(textAnnot.custom.name);
    textAnnot.FontSize = '' + 20.0 / zoom + 'px';
    textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    textAnnot.TextColor = new Annotations.Color(0, 165, 228);
    textAnnot.StrokeThickness = 1;
    textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
    textAnnot.TextAlign = 'center';

    textAnnot.Author = annotManager.getCurrentUser();

    annotManager.deselectAllAnnotations();
    annotManager.addAnnotation(textAnnot, true);
    annotManager.redrawAnnotation(textAnnot);
    annotManager.selectAnnotation(textAnnot);
  };

  const applyFields = async () => {
    const { Annotations, docViewer } = instance;
    const annotManager = docViewer.getAnnotationManager();
    const fieldManager = annotManager.getFieldManager();
    const annotationsList = annotManager.getAnnotationsList();
    const annotsToDelete = [];
    const annotsToDraw = [];

    if (!annotationsList || annotationsList.length <= 0) {
      alert('Nada para processar!');
      return;
    }

    await Promise.all(
      annotationsList.map(async (annot, index) => {
        let inputAnnot;
        let field;

        if (typeof annot.custom !== 'undefined') {
          // create a form field based on the type of annotation
          if (annot.custom.type === 'TEXT') {
            field = new Annotations.Forms.Field(
              annot.getContents() + Date.now() + index,
              {
                type: 'Tx',
                value: annot.custom.value,
              },
            );
            inputAnnot = new Annotations.TextWidgetAnnotation(field);
          } else if (annot.custom.type === 'SIGNATURE') {
            field = new Annotations.Forms.Field(
              annot.getContents() + Date.now() + index,
              {
                type: 'Sig',
              },
            );
            inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
              appearance: '_DEFAULT',
              appearances: {
                _DEFAULT: {
                  Normal: {
                    data:
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                    offset: {
                      x: 100,
                      y: 100,
                    },
                  },
                },
              },
            });
          } else if (annot.custom.type === 'DATE') {
            field = new Annotations.Forms.Field(
              annot.getContents() + Date.now() + index,
              {
                type: 'Tx',
                value: 'm-d-yyyy',
                // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
                actions: {
                  F: [
                    {
                      name: 'JavaScript',
                      // You can customize the date format here between the two double-quotation marks
                      // or leave this blank to use the default format
                      javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                    },
                  ],
                  K: [
                    {
                      name: 'JavaScript',
                      // You can customize the date format here between the two double-quotation marks
                      // or leave this blank to use the default format
                      javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                    },
                  ],
                },
              },
            );

            inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
          } else {
            // exit early for other annotations
            annotManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
            return;
          }
        } else {
          // exit early for other annotations
          return;
        }

        // set position
        inputAnnot.PageNumber = annot.getPageNumber();
        inputAnnot.X = annot.getX();
        inputAnnot.Y = annot.getY();
        inputAnnot.rotation = annot.Rotation;
        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth();
          inputAnnot.Height = annot.getHeight();
        } else {
          inputAnnot.Width = annot.getHeight();
          inputAnnot.Height = annot.getWidth();
        }

        // delete original annotation
        annotsToDelete.push(annot);

        // customize styles of the form field
        Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
          if (widget instanceof Annotations.SignatureWidgetAnnotation) {
            return {
              border: '1px solid #a5c7ff',
            };
          }
        };
        Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

        // draw the annotation the viewer
        annotManager.addAnnotation(inputAnnot);
        fieldManager.addField(field);
        annotsToDraw.push(inputAnnot);
      }),
    );

    // delete old annotations
    annotManager.deleteAnnotations(annotsToDelete, null, true);

    // refresh viewer
    await annotManager.drawAnnotationsFromList(annotsToDraw);
    await uploadForSigning();
  };

  const uploadForSigning = async () => {
    // upload the PDF with fields as AcroForm
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({ widgets: true, fields: true });
    const data = await doc.getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: 'application/pdf' });

    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.changed = {
      assinar: xfdfString
    };
    _ks_.element = view;
    _ks_.global = true;
    _ks_.complete = function (res) {
      if (!res.error) {
        window.location.href = `/contratos/preparar/${id_cliente}`;
      }
    };

    _ks_.cfg = {
      url: `signing/prepare/${id_contrato}`,
      global: true,
      method: 'POST'
    };
    utils.call(_ks_);
  };

  let addAssinarTest = function () {
    addField('testemunha', 'SIGNATURE')
  }


  let addAssinarDI = function () {
    addField('di', 'SIGNATURE')
  }

  const assinnarContrato = async () => {
    completeSigning(fase);
  }

  const completeSigning = async (act) => {
    view.loadingStart();

    const { Annotations, docViewer } = instance;
    const annotManager = docViewer.getAnnotationManager();
    const xfdf = await annotManager.exportAnnotations({ widgets: false, links: false });
    const PDFNet = window.PDFNet;

    const doc = await PDFNet.PDFDoc.createFromURL(url);
    doc.initSecurityHandler();


    let i;
    // for (i = 0; i < xfdf.length; i++) {
    let fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdf);
    await doc.fdfMerge(fdfDoc);
    await doc.flattenAnnotations();
    // }

    const docbuf = await doc.saveMemoryBuffer(
      PDFNet.SDFDoc.SaveOptions.e_linearized,
    );

    const sigHandlerId = await doc.addStdSignatureHandlerFromURL('/nipponflex_com.p12', 'cYOHE@i77C0#');
    const certificationSigField = await doc.createDigitalSignatureField("nipponflex_global_system_SIGNATURE_");
    // Prepare the signature and signature handler for certification.
    await certificationSigField.certifyOnNextSaveWithCustomHandler(sigHandlerId);

    const blob = new Blob([docbuf], {
      type: 'application/pdf',
    });


    const formData = new FormData();
    const _ks_ = {};

    formData.append('hashcode', sigHandlerId);
    formData.append('documento', blob);
    formData.append('assinatura', xfdf);

    _ks_.crud = crud;
    _ks_.global = true;
    _ks_.element = view;

    _ks_.complete = function (res) {
      if (!res.error) {
        if (res.data?.finish) {
          completeSigning('send');
        } else {
          window.location.href = `/contratos/${id_contrato}/assinaturas/${id_cliente}/finish`;
        }
      }
    };

    _ks_.cfg = {
      url: `signing/contract/${id_contrato}/${id_cliente}/${act}`,
      form: formData,
      alert: true,
      global: true,
      method: 'POST'
    };
    utils.call(_ks_);
  }

  const nextField = () => {
    let annots = anManager.getAnnotationsList();
    if (annots[annotPosition]) {
      anManager.jumpToAnnotation(annots[annotPosition]);
      if (annots[annotPosition + 1]) {
        setAnnotPosition(annotPosition + 1);
      }
    }
  }

  const prevField = () => {
    let annots = anManager.getAnnotationsList();
    if (annots[annotPosition]) {
      anManager.jumpToAnnotation(annots[annotPosition]);
      if (annots[annotPosition - 1]) {
        setAnnotPosition(annotPosition - 1);
      }
    }
  }

  const voltar = function () {
    window.history.back();
  };
  return (
    <>
      <Dialog
        className="ui-dialog-loading"
        open={loading}>
        <DialogContent>
          <div className="ui-view-loading-data">
            <i className="fas fas fa-circle-notch fa-spin"></i>
          </div>
        </DialogContent>
      </Dialog>
      {fase === 'finish' &&
        <div className="ui-ass-finish">
          <i className="fa fa-check"></i> <br></br>
          Assinatura concluída com sucesso! <br></br>
          <a className="ui-ass-back" href="/"> Clique aqui para continuar</a>
        </div>
      }
      {fase !== 'finish' &&
        <>
          <div className="ui-ass-commands">
            {fase === 'prepare' &&
              <>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  CAMPOS
                </div>
                <Button onClick={addAssinarTest} >TESTEMUNHA</Button><br></br>
                <Button onClick={addAssinarDI} >D.I</Button><br></br>
                <Button onClick={applyFields}>Confirmar</Button><br></br><br></br>
                <Button className="ui-btn-ass-back" onClick={voltar}>SAIR</Button><br></br>
              </>
            }
            {(fase === 'di' || fase === 'testemunha') &&
              <>

                <Button
                  onClick={prevField}>
                  Anterior
                </Button><br></br>

                <Button
                  onClick={nextField}>
                  Próximo
                </Button><br></br>

                <Button className="ui-ass-complete" onClick={assinnarContrato}>Assinar</Button><br></br>
              </>
            }
          </div>
          <div id="viewer"></div>
        </>
      }
    </>
  );
}

export default Assinatura;