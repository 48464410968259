import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';
import MenuCarrinho from './menu_carrinho';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

function Produto() {
  let [view, setView] = useState({});
  let [sku, setSku] = useState({ infos: {}, zoom: false });
  let [crud, setCrud] = useState({ data: { redondo: false }, changed: {} });
  let [ready, setReady] = useState(false);
  let [showConfigurar, setShowConfigurar] = useState(false);
  let idSku = 0;
  let [index, setIndex] = useState(0);
  let [listaDerivacoes, setListaDerivacoes] = useState([]);
  let [valorCalculado, setValorCalculado] = useState(0);
  let user = utils.getUser();
  let [access, setAccess] = useState(window.access ? window.access : {});
  const path = window.location.pathname;
  const split = path.split('/');

  if (split.length >= 4) {
    idSku = parseInt(split[3]);
  }

  crud.allow = function (key) {
    if (user.administrativo) {
      return true;
    }

    return window.access[key];
  }

  window.processo_atual = 73;

  const load = function (url, complete) {
    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.view = view;
    _ks_.changed = {};
    _ks_.element = {};

    _ks_.complete = function (res) {
      if (complete) {
        complete.call(this, res);
      }
      crud.refresh();
    };

    _ks_.cfg = {
      url: url,
      method: 'GET'
    };
    utils.call(_ks_);
  }

  const constListarInfos = function (url, sku) {

    load(url, function (res) {
      if (res.data.length > 0) {
        for (const i in res.data) {
          const o = res.data[i];

          sku.infos[o.chave] = o.valor;
        }
        setSku(sku);
        crud.refresh();
      }
    });
  }

  const listarMedidasPadroesSPE = function () {
    load('listarMedidasPadroesSPE?id_produto=' + sku.id_produto, function (res) {
      listaDerivacoes = res.data;
      setListaDerivacoes(res.data);
    });
  }

  if (!ready) {
    setReady(true);

    load('listarProdutosCatalogo?id_sku=' + idSku, function (res) {
      if (res.data.length > 0) {
        sku = res.data[0];
        sku.infos = {};
        sku.zoom = false;

        if (!sku.quantidade) {
          sku.quantidade = 1;
        }

        constListarInfos('listarInformacoesSKU?id_sku=' + idSku, sku);

        crud.id_sku = sku.id_sku;
        crud.data = sku;

        window.crud = crud;

        setSku(sku);
        crud.refresh();
      }
    });
  }

  crud.refresh = function () {
    setCrud(crud);
    setIndex(index + 1);
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  crud.configurarProduto = function () {
    listarMedidasPadroesSPE();
    setShowConfigurar(true);
  };

  const fecharConfigurar = function () {
    window.location.reload();
  };

  const confirmarConfigurar = function () {
    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.view = view;
    _ks_.global = true;
    _ks_.changed = sku;
    _ks_.validate = true;
    _ks_.element = {};

    _ks_.complete = function (res) {
      if (!res.error) {
        setShowConfigurar(false);
        crud.refresh();
      }
    };

    _ks_.cfg = {
      url: 'configProduct/' + sku.id_sku,
      send: ['comprimento', 'redondo', 'diametro', 'largura', 'acessorios', 'tipo', 'sku_padrao', 'altura'],
      redirect: '/carrinho/pedido',
      validate: true,
      method: 'POST'
    };
    utils.call(_ks_);
  };

  crud.changeGrade = function (el, args) {
    const sku = args.data;

    window.location.href = '/carrinho/produto/' + sku.id_sku;
  }

  crud.setTab = function () {

  }

  crud.changeCor = function (el, args) {
    const data = args.data;

    if (data) {
      window.location.href = '/carrinho/produto/' + data.id_sku;
    }
  }

  crud.changeImage = function (el, args) {
    const data = args.data;

    if (data) {
      sku.avatar = data.url;

      setSku(sku);
      crud.refresh();
      setIndex(index + 1);
    }
  }

  crud.zoomImage = function (el, args) {

    sku.zoom = true;

    setSku(sku);
    setIndex(index + 1);
    crud.refresh();
  }


  crud.closeZoom = function (el, args) {
    sku.zoom = false;

    setSku(sku);
    setIndex(index + 1);
    crud.refresh();
  }

  crud.setarSku = function (el, val) {

    for (const di in listaDerivacoes) {
      let dv = listaDerivacoes[di];

      if (dv.id == val.sku_padrao) {
        crud.data.valor_preco_venda = dv.valor;
      }
    }
  };

  crud.verFrete = function (el, args) {
    const _ks_ = {};
    const ps = args.data;

    _ks_.crud = crud;
    _ks_.changed = {};
    _ks_.element = {};
    _ks_.alert = false;
    _ks_.global = true;

    _ks_.complete = function (res) {
      const fretes = res.data;
      let info_frete = '';

      if (fretes) {
        for (const i in fretes) {
          const ft = fretes[i];
          let unidadeTempo = ft.unidade_tempo ? ft.unidade_tempo : 'dias_uteis';
          info_frete += ' <b>' + ft.forma + '</b>: ' + utils.money(ft.preco) + ', Prazo:  ' + ft.prazo + ' ' + utils.lng(unidadeTempo, 'dias úteis') + '. <br>';
        }
        args.data.info_frete = info_frete;
      }
    };

    _ks_.cfg = {
      url: 'cart/calculateShipping/' + ps.id_sku + '/' + ps.id_filial,
      alert: false,
      global: true,
      method: 'POST'
    };
    utils.call(_ks_);
  }

  crud.calculaTotal = function () {
    let p = crud.data;
    let comprimento = p.comprimento ? p.comprimento : 1;
    let largura = p.largura ? p.largura : 1;
    let diametro = p.diametro ? p.diametro : 0;
    let total = p.valor_preco_venda * (comprimento * largura);

    if (p.tipo === 'REDONDO') {
      let raio = diametro / 2;

      total = p.valor_preco_venda * (Math.pow(raio, 2) * 3.14);
    }

    if (p.acessorios) {
      for (const i in p.acessorios) {
        let o = p.acessorios[i];

        if (o.quantidade > 0) {
          total = total + (o.quantidade * o.valor);
        }
        if (!o.observacao || o.observacao === '') {
          o.observacao = '';
        }
      }
    }

    if (p.valor_acrescimo_preco > 0) {
      total = total + p.valor_acrescimo_preco;
    }
    setValorCalculado(total);
    return utils.money(total);
  }

  if (sku.codigo_linha === 'TRV') {
    sku.tipo = 'TRAVESSEIRO';
    crud.changed.tipo = 'TRAVESSEIRO';
  }

  const dlg_config = {
    styleClass: 'ui-cfg-acessorios',
    elements: {
      especial: {
        label: utils.lng('produto_especial','Produto especial'),
        rendered: "${crud.data.codigo_grade === 'PE'}",
        elements: {
          im_dim: { type: 'img', value: '/static/img/dimencoes.jpeg' },
          dimensoes: {
            name: 'dimensoes',
            elements: {
              tipo: {
                required: true,
                object: false,
                rendered: "${@this.codigo_linha !== 'TRV'}",
                itemValue: 'value',
                itemLabel: 'label',
                type: 'select', label: utils.lng('qual_tipo',"Qual tipo?"), field: 'tipo', data: [
                  { label: utils.lng('medidas_especial','Medidas especiais'), value: 'ESPECIAL' },
                  { label: utils.lng('medidas_padrao','Medidas padrão'), value: 'PADRAO' },
                  { label: utils.lng('redondo','Redondo'), value: 'REDONDO' }
                ]
              },
              diametro: {
                rendered: "${@this.tipo === 'REDONDO'}", required: true,
                change: {
                  action: 'calculaTotal'
                }, type: 'number', label: utils.lng('diametro','Diâmetro')+': ', field: 'diametro', style: { paddingBottom: '10px' }
              },
              sku_padrao: {
                rendered: "${@this.tipo === 'PADRAO'}", required: true, object: false,
                change: {
                  action: 'setarSku'
                },
                data: listaDerivacoes, type: 'select', field: 'sku_padrao',
                label: utils.lng('derivacao','Derivação')+': ', itemLabel: 'legenda', itemValue: 'id', style: { paddingBottom: '10px' }
              },
              altura: {
                rendered: "${@this.tipo === 'TRAVESSEIRO'}",
                change: {
                  action: 'calculaTotal',
                  required: true
                }, type: 'number', label: utils.lng('altura','Altura')+': ', required: true, field: 'altura', style: { paddingBottom: '10px' }
              },
              nao_redondo: {
                rendered: "${@this.tipo === 'ESPECIAL'}",
                elements: {
                  largura: {
                    change: {
                      action: 'calculaTotal'
                    }, help: "Metros", required: true, type: 'number', label: utils.lng('largura','Largura')+': ', field: 'largura', style: { paddingBottom: '10px' }
                  },
                  comprimento: {
                    change: {
                      action: 'calculaTotal'
                    }, help: "Metros", required: true, type: 'number', label: utils.lng('comprimento','Comprimento')+': ', field: 'comprimento', style: { paddingBottom: '10px' }
                  }
                }
              }
            }
          }
        }
      },
      acessorios: {
        name: 'acessorios',
        field: 'acessorios',
        type: 'list',
        // layout: 'table',
        layout: 'grid',
        rendered: "${exists(@this, 'tipo')}",
        controls: false,
        search: false,
        forceReference: true,
        label: utils.lng('acessorios','Acessórios'),
        header: false,
        size: 500,
        add: false,
        list: {
          url: 'listaAcessorios',
          send: false
        },
        columns: {
          descricao: { label: utils.lng('descricao',"Descrição"), type: 'span', style: {width: '500px'} },
          quantidade: {
            field: 'quantidade',
            name: 'quantidade',
            style: { width: '150px'},
            label: utils.lng('quantidade','Quantidade'), rowEdit: true, type: 'spinner', numeric: true, click: false, change: {
              action: 'calculaTotal'
            }
          },
          valor: { format: 'money', style: { width: '100px'} },
          total: { value: "+ ${utils.money(@this.quantidade * @this.valor)}", style: { width: '100px'} },
          observacao: { 
            field: 'observacao', 
            nome: 'observacao', 
            label: utils.lng('observacao',"Observação"), 
            rowEdit: true, 
            rows: 3,
            style: { width: '100%' },
            type: 'textarea', 
            rendered: "${@this.quantidade > 0}" 
          }
        }
      },
      totalizador: {
        value: "${crud.calculaTotal()}",
      }
    }
  };

  const detalhe = {
    name: "detalhe",
    elements: {
      centro: {
        elements: {
          zoomarea: {
            rendered: '${@this.zoom}',
            elements: {
              zoom_close: {
                type: 'link',
                icon: 'fa fa-times',
                click: {
                  action: 'closeZoom'
                }
              },
              zoom_overlay: {

              },
              zoom_data: {
                upload: false,
                type: 'avatar',
                field: 'avatar'
              }
            }
          },
          left: {
            cols: 7,
            elements: {
              cores: {
                gallery: true,
                showStatus: false,
                type: 'list',
                itemValue: 'url',
                list: {
                  url: 'listarImagens?id_sku={id_sku}',
                  method: 'GET'
                },
                isZoom: true
              }
            }
          },
          right: {
            cols: 5,
            elements: {
              corpo: {
                elements: {
                  destaque: { type: 'span', field: 'destaque_descricao', rendered: '#{typeof @this.destaque_descricao !== "undefined"}', background: '#{@this.destaque_cor}' },
                  sobmedida: { type: 'span', field: 'sob_medida', rendered: '#{@this.sob_medida === true}', value: 'Sob encomenda' },
                  codigo_sproduto: { type: 'span', value: '${@this.departamento}', style: { paddingTop: '10px', color: '#888', fontSize: '14px' } },
                  descricao: { type: 'span', field: 'descricao', value: '${@this.codigo_produto + " - " + @this.descricao}', style: { paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '18px' } },
                  zyz: {
                    layout: 'grid',
                    type: 'div',
                    style: { paddingBottom: '10px', width: '100%' },
                    elements: {
                      id_hub_grade_item: {
                        type: 'list',
                        layout: 'button',
                        header: false,
                        top: false,
                        controls: false,
                        rowStyleClass: "${@this.id_sku === " + idSku + " ? 'ui-current-grade-item' : '' }",
                        search: false,
                        columns: {
                          infosp: { value: "${@this.legenda + ' (' + utils.money(@this.valor_preco_venda) + ')'}" }
                        },
                        empty: "",
                        list: {
                          url: 'listarProdutosCatalogo?id_produto={id_produto}',
                          method: 'GET', send: false
                        },
                        click: {
                          action: 'changeGrade'
                        }
                      }
                    }
                  },
                  cores: {
                    add: false,
                    empty: "",
                    header: false,
                    id: 'carousel_cores',
                    styleClass: 'ui-carousel-small',
                    size: 1000,
                    type: 'list', layout: 'carousel', label: utils.lng('modelos','Modelos')+' ', style: { paddingBottom: '10px' },
                    list: {
                      url: 'listarProdutosCatalogo?id_departamento={id_departamento}&id_hub_grade_item={id_hub_grade_item}',
                      method: 'GET', send: false
                    },
                    columns: {
                      avatar: { type: 'img' }
                    },
                    click: {
                      action: 'changeCor'
                    }
                  },
                  quantidade: { type: 'number', label: utils.lng('quantidade','Quantidade')+': ', field: 'quantidade', style: { paddingBottom: '10px' } },
                  indisponivel: { rendered: "${@this.saldo <= 0 && @this.valida_estoque}", value: utils.lng('produto_indisponivel', 'Produto indisponível') },
                  valores: {
                    layout: 'inline',
                    elements: {
                      valor_preco_venda: {
                        styleClass: 'ui-valor-original classe-preco-original-sup',
                        type: 'span', format: 'money', field: 'valor_preco_venda', value: '#{"de " + utils.money(@this.valor_preco_venda) + " por "}',
                        rendered: '${@this.percentual_desconto > 0}',
                        style: { color: '#555', fontSize: '20px', fontWeight: 'bold' }
                      },
                      valor: { type: 'span', format: 'money', field: 'valor', style: { paddingBottom: '10px', color: 'var(--theme)', fontSize: '25px' } },
                      valor_dolar: {
                        type: 'span',
                        rendered: '${@this.exibir_custo_dolar}',
                        field: 'valor',
                        style: { paddingBottom: '10px', color: 'var(--theme)', paddingTop: '0px', fontSize: '25px' },
                        value: "&nbsp;&nbsp;${utils.customMoney('US$',@this.valor_custo)}&nbsp;&nbsp;"
                      },
                      separar_spv: { rendered: '${@this.exibir_spv}', type: 'span', value: '&nbsp;/&nbsp;SPV&nbsp;', style: { paddingBottom: '10px', color: '#888', fontSize: '18px' } },
                      possui_spv: {
                        rendered: '${!@this.exibir_spv && @this.possui_spv}', type: 'span', value: '&nbsp;' + utils.lng('possui_spv', 'SPV incluso'),
                        style: { paddingTop: '-10px', color: '#888', fontSize: '16px' }
                      },
                      valor_spv: {
                        rendered: '${@this.exibir_spv}', type: 'span', format: 'money', field: 'valor_spv', style: { paddingBottom: '10px', color: '#888', fontSize: '18px' },
                      }
                    }
                  }
                }
              },
              avisar_chegada: {
                rendered: "${@this.saldo <= 0 && @this.valida_estoque}",
                type: 'button',
                styleClass: 'ui-aviso-chegada',
                click: {
                  url: 'cart/notifyArrival/{id_sku}',
                  method: 'POST'
                },
                label: utils.lng('aviseme_quando_chegar','Avise-me quando chegar')
              },
              comandos: {
                elements: {
                  comprar_pai: {
                    disabled: "${@this.saldo <= 0 && @this.valida_estoque}",
                    elements: {
                      comprar: {
                        rendered: "${@this.codigo_grade !== 'PE'}",
                        click: {
                          url: 'cart/changeProduct/{id_sku}',
                          send: {
                            "quantidade": "${@this.quantidade}"
                          },
                          redirect: '/carrinho/pedido'
                        },
                        variant: 'text',
                        type: 'button', label: utils.lng('comprar_upper','COMPRAR'), style: { width: '100%' }, icon: 'fa fa-shopping-cart', theme: 'default', cols: 10
                      },
                      configurar: {
                        variant: 'text',
                        type: 'button', label: utils.lng('configurar_produto_upper','CONFIGURAR PRODUTO'), style: { width: '100%' }, icon: 'fa fa-shopping-cart', theme: 'default', cols: 10,
                        rendered: "${@this.codigo_grade === 'PE'}",
                        click: {
                          action: 'configurarProduto'
                        }
                      }
                    }
                  },
                  favorito: {
                    click: {
                      url: 'cart/changeFavorite/{id_sku}',
                      refresh: ['produtos']
                    },
                    variant: 'text',
                    styleClass: "${favorito ? 'ui-selected' : ''}",
                    type: 'button', icon: "${favorito ? 'fas fa-heart' : 'far fa-heart'}", theme: 'default', cols: 2
                  }
                }
              }
            }
          }
        }
      },
      informacoes: {
        cols: 12,
        styleClass: 'info-tabs',
        elements: {
          info_caracteristica: {
            cols: 4,
            showLabel: true,
            type: 'card',
            label: utils.lng('caracteristicas',"Características"),
            elements: {
              tec: { value: "${@this.caracteristica}", type: 'span' }
            }
          },
          info_tecnicas: {
            cols: 4,
            type: 'card',
            showLabel: true,
            label: utils.lng('informacoes_tecnicas',"Informações tecnicas"),
            elements: {
              tec: { value: "${@this.infos.dados_tecnicos}", type: 'span' }
            }
          },
          info_garantia: {
            cols: 4,
            showLabel: true,
            type: 'card',
            label: utils.lng('garantia',"Garantia"),
            elements: {
              tec: { value: "${@this.infos.garantia}", type: 'span' }
            }
          }
        }
      },
      video: {
        type: 'youtube',
        field: 'link_video',
        empty: false
      },
      card_filiais: {
        type: 'card',
        rendered: "${crud.allow('EDITAR_FILIAL') !== false && !@this.sob_medida}",
        label: utils.lng('filiais_com_estoque',"Filiais com estoque"),
        elements: {
          filiais_estoques: {
            name: 'filiais_estoques',
            type: 'list',
            layout: 'table',
            search: false,
            controls: false,
            size: 500,
            add: false,
            list: {
              url: 'listarEstoqueSKU?id_sku={id_sku}',
              send: false
            },
            columns: {
              filial: { label: utils.lng('filial',"Filial"), type: 'span', sort: false },
              ver_frete: { label: "", type: 'span', sort: false, value: "${nvl(@this.info_frete,'Ver frete')}", click: { action: 'verFrete' } },
              escolher: {
                label: utils.lng('selecionar','Selecionar'), headerLabel: false, value: 'Selecionar', sort: false, click: {
                  url: 'cart/changeDeliveryLocal', redirect: true,
                  method: 'POST',
                  send: {
                    filialRetirada: { id: '${@this.id_filial}' }
                  },
                  global: true
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <View name="carrinho">
      <div className="ui-catalogo">
        <MenuCarrinho crud={crud} view={view} ></MenuCarrinho>

        <div className="ui-catalogo-content" >
          <Dialog
            open={showConfigurar}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Element crud={crud} view={view} value={dlg_config} data={crud.data} ></Element>
            </DialogContent>
            <DialogActions>
              <Button id="fechar_acessorios" onClick={fecharConfigurar} variant="outlined" color="primary">{utils.lng('fechar', 'FECHAR')}</Button>
              <Button id="confirmar_acessorios" onClick={confirmarConfigurar} variant="outlined" color="primary">{utils.lng('confirmar', 'Confirmar')}</Button>
            </DialogActions>
          </Dialog>
          <div className="product-detail">
            {sku.id_sku &&
              <Element crud={crud} view={view} value={detalhe} data={sku} ></Element>
            }
          </div>
        </div>
      </div>
    </View>
  );
}

export default Produto;