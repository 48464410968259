import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from "react";
import Element from './element';
import utils from "./utils";

const Anotacao = function (props) {
  let id = props.id;
  let color = props.color ? props.color : 'primary';
  let variant = props.variant ? props.variant : 'outlined';
  let crud = props.crud ? props.crud : { data: {}, changed: {} };
  let view = props.view;
  let entidade = props.entidade;
  let data = props.data ? props.data : {};
  let [observacao, setObservacao] = useState('');
  let [showDialog, setShowDialog] = useState(false);

  const addNew = function () {
    observacao = '';
    showDialog = true;

    setShowDialog(showDialog);
  };

  const anotacoes = {
    name: 'anotacoes',
    type: 'list',
    remove: false,
    label: props.label,
    widgetVar: 'anotacoes',
    filter: false,
    print: false,
    add: {
      action: addNew
    },
    list: {
      url: 'EntidadeAnotacao?idEntidade=' + id + '&entidade=' + entidade
    },
    columns: {
      observacao: { label: 'Observação' },
      usuario: { columnSyle: { width: '100px' }, label: 'Usuário' },
      dhregistro: { type: 'date', label: 'Data' }
    }
  }

  if (props.add === false) {
    anotacoes.add = false;
  }

  const closeDialog = function () {
    if (window.anotacoes) {
      window.anotacoes.search();
    }
    showDialog = false;
    setShowDialog(showDialog);
  }

  const confirmDialog = function () {
    if (observacao) {
      let _ks_ = {};


      _ks_.global = true;
      _ks_.crud = crud;
      _ks_.changed = { observacao: observacao, entidade: entidade, idEntidade: id };
      _ks_.element = {};

      _ks_.complete = function (res) {
        closeDialog();
      };

      _ks_.cfg = {
        url: 'EntidadeAnotacao',
        method: 'POST'
      };
      utils.call(_ks_);
    } else {
      closeDialog();
    }
  }

  const onChangeValor = function (event) {
    let val = event.target.value;

    observacao = val;

    setObservacao(val);
  }


  return (
    <>
      <Dialog
        open={showDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{utils.lng('nota','Anotar')}</DialogTitle>
        <DialogContent>
          <div className="ui-opt-observacao" style={{ marginTop: '15px' }}>
            <TextField style={{ width: '400px' }} variant="outlined" multiline={true} rows={5} required={true} label= {utils.lng('caixa_nota',"Digite algo aqui..")}
              value={observacao} onChange={onChangeValor}> </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="btnCancelarAnotacao" onClick={closeDialog} variant="outlined" color="primary">{utils.lng('cancelar','Cancelar')}</Button>
          <Button id="btnConfirmarAnotacao" onClick={confirmDialog} variant="outlined" color="primary">{utils.lng('confirmar','CONFIRMAR')}</Button>
        </DialogActions>
      </Dialog>
      {props.list !== false &&
        <Element value={anotacoes} crud={crud} data={data} view={view} ></Element>
      }
      {props.list === false &&
        <Button onClick={addNew} variant={variant} color={color}>{props.label}</Button>
      }
    </>
  );
}

export default Anotacao;