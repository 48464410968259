import React, { useState, useEffect } from "react";
import Utils from './utils';
import Crud from './crud';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const Entity = ({ value, required, disabled, user, data, crud }) => {
    const keyPressed = function (event) {
        if (event.key === "Enter") {

        } else {
            return onChange(event);
        }
    }

    let element = Utils.decode(value, crud, data);
    let view = window.view;
    let name = element.name ? element.name : element.field;
    let field = element.field ? element.field : element.name;

    crud.elements[name] = value;

    let x = Utils.resolve(element, data);

    if (!x) {
        x = '';
    }
    let [dialog, setDialog] = useState(false);
    let [model, setModel] = useState({});
    let [query, setQuery] = useState(value.query);

    let variant = value.variant;

    const setValue = value.setValue;


    value.setValue = function (v) {
        setValue(v);
        setQuery(value.query);
    }

    model.entity = value.entity;
    model.crud = value.crud;
    model.list = value.list;
    model.selector = true;
    model.dialog = true;
    model.add = false;

    const onChange = function (event) {

    }

    const search = function (event) {
        model.edit = false;
        model.create = false;
        model.id = -1;
        model.selected = {};

        model.changed = {};

        if (element.dataSource) {
            model.changed.data_source = element.dataSource;
        }
        setDialog(true);
    }

    const add = function (event) {

        model.edit = true;
        model.create = true;
        model.id = 0;
        model.new = false;
        model.selected = { id: 0 };
        model.data = { id: 0 };


        setModel(model);
        setDialog(true);
    }

    const onClick = function (event, args) {
        if (args) {
            const val = args.data;

            value.setValue(val);

            setQuery(value.query);
        }
        setDialog(false);
    }

    const clear = function (event) {
        value.setValue(null);
        setQuery('');
    }


    if (!query) {
        query = '';

        let o = crud.data[field];

        if (!o) {
            try {
                o = eval('crud.data.'+field);
            } catch(e) {}
        }
        if (o) {
            let fils = ['nome', 'descricao', 'id'];

            for (const i in fils) {
                let k = fils[i];

                if (o[k]) {
                    query = o[k];
                    break
                }
            }
        }
    }

    return (
        <div className="ui-entity">
            <div className="ui-entity-item">
                <TextField disabled={disabled} required={required} variant={variant} label={value.label} value={query}
                    onChange={onChange} onKeyPress={keyPressed} type="text" placeholder="Pesquisar" />
            </div>
            {disabled !== true &&
                <>
                    {!value.selectLabel &&
                        <div className="ui-entity-item ui-entity-act">
                            <a onClick={search}>
                                <i className="fa fa-search"></i>
                            </a>
                        </div>
                    }
                    {value.selectLabel &&
                        <div className="ui-entity-item">
                            <Button onClick={search} variant="contained" color="defaut">
                                {value.selectLabel}
                            </Button>
                        </div>
                    }
                </>
            }
            {((value.query && disabled !== true && value.trash !== false) || crud.filter) &&
                <div className="ui-entity-item ui-entity-act">
                    <a onClick={clear}>
                        <i className="fa fa-trash"></i>
                    </a>
                </div>
            }
            {dialog &&
                <>
                    <div className="ui-overlay">
                    </div>
                    <div className="ui-dialog">
                        <div className="ui-dialog-header">
                            {value.label}
                            <div className="ui-dialog-close" onClick={onClick}>
                                <i className="fa fa-times"></i>
                            </div>
                        </div>
                        <div className="ui-dialog-data">
                            <Crud view={view} value={model} user={user} onClick={onClick} />
                        </div>
                    </div>
                </>
            }
        </div>
    )
};

export default Entity