import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import utils from "./utils";

function OcSPV() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [idOC, setIdOC] = useState(-1);


  let split = window.location.pathname.substring(1).split('/');

  if (split.length > 1) {
    idOC = parseInt(split[1]);
  }


  useEffect(() => {
    if (!ready) {
      ready = true;

      let _ks_ = {};

      _ks_.global = true;
      _ks_.crud = crud;
      _ks_.changed = {};
      _ks_.element = {};

      _ks_.complete = function (res) {
        let list = res.data;

        if (list && list.length > 0) {
          crud.data = list[0];
          crud.changed = list[0];

          setIndex(index + 1);
          setCrud(crud);
        }
      };

      _ks_.cfg = {
        url: 'encontrar_ocspv?id_ocspv=' + idOC,
        method: 'GET'
      };
      utils.call(_ks_);
    }
  }, []);


  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  const itens = {
    name: 'itens',
    type: 'list',
    layout: 'table',
    label: "Minhas OC's",
    rows: 10,
    add: {
      action: 'open',
      url: '/ocspv/0'
    },
    showLabel: true,
    header: false,
    rowStyleClass: "${'ui-row-' + @this.status_constante}",
    list: {
      url: 'listar_minhas_ocs'
    },
    columns: {
      status_descricao: { label: 'Status do oc', type: 'span', bold: true },
      dh_registro: { label: 'Data do oc', type: 'date', bold: true },
      valor: { label: 'Valor total', type: 'span', format: 'money', bold: true, columnStyle: { width: '200px' } },
      detalhes: {
        type: 'button',
        label: "VER DETALHES", columnStyle: { width: '200px' },
        click: {
          action: "open",
          url: '/ocspv/{id_oc}'
        }
      }
    }
  };

  const oc = {
    name: 'oc',
    type: 'card',
    label: "Minhas OC",
    elements: {
      linkNota: {field: 'linkNota', label: 'SELECIONE A NOTA', required: true, type: 'file', upload: true, dialog: true , disabled: idOC > 0},
      saldo: { field: 'saldo', label: 'Saldo', type: 'money', disabled: true },
      mensagem: { label: 'Mensagem',rendered: '${!empty(@this.mensagem)}', disabled: true, type: 'text' },
      status_descricao: { label: 'Status', disabled: true, type: 'text' },
      valor: { label: 'Valor total', required: true, type: 'money', disabled: idOC > 0 },
      actions: {
        layout: 'inline',
        style: { textAlign: 'right' },
        elements: {
          fechar: {
            type: 'button',
            theme: 'secondary',
            label: "FECHAR",
            click: {
              action: "open",
              url: '/ocspv'
            }
          },
          confirmar: {
            type: 'button',
            disabled: idOC > 0,
            style: { marginLeft: '10px' },
            label: "CONFIRMAR",
            click: {
              url: 'OCSpv',
              method: 'POST'
            }
          }
        }
      }
    }
  };

  return (
    <View path="/ocspv" name="minhas-ocs">
      <div className="ui-minhas-ocs">
        {idOC < 0 &&
          <Element value={itens} crud={crud} data={crud.data} view={view} ></Element>
        }
        {idOC >= 0 &&
          <div>
            <div style={{ width: '500px', margin: 'auto' }}>
              <Element value={oc} crud={crud} data={crud.data} view={view} ></Element>
            </div>
          </div>
        }
      </div>
    </View>
  );
}

export default OcSPV;