import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import Anotacao from "./anotacao";
import utils from "./utils";

function PagamentosPendente() {
  let data = { constante_status: 'AGUARDANDO', id_status: 11 };
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: data });
  let [pgto, setPgto] = useState({});
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let idPgto = utils.getPath(2);
  let api = utils.getUrlApi();

  useEffect(() => {
    if (!ready) {
      if (idPgto) {
        const _ks_ = {};

        _ks_.crud = crud;
        _ks_.changed = {};
        _ks_.element = {};
        _ks_.alert = false;

        _ks_.complete = function (res) {
          if (!res.error) {
            pgto = res.data;
            crud.data = pgto;
            crud.changed = pgto;
            setPgto(res.data);
          }
        };

        _ks_.cfg = {
          url: 'listarPagamentosPendenteNew?single=true&id_pgto=' + idPgto,
          method: 'GET'
        };
        utils.call(_ks_);
      }

      ready = true;
      setReady(true);
    }
  }, []);

  crud.editarPgto = function (value, args) {
    const _ks_ = {};
    pgto = args.data;

    _ks_.crud = crud;
    _ks_.changed = {};
    _ks_.element = {};
    _ks_.alert = false;

    _ks_.complete = function (res) {
      if (!res.error) {
        crud.data = pgto;
        crud.changed = pgto;

        setPgto(args.data);
      }
    };

    _ks_.cfg = {
      url: 'lock/HubPedidoVendaPagamento/' + pgto.id_pgto,
      method: 'GET'
    };
    utils.call(_ks_);
  }

  crud.closePgto = function (value, args) {
    const _ks_ = {};

    _ks_.crud = crud;
    _ks_.changed = {};
    _ks_.element = {};
    _ks_.alert = false;

    _ks_.complete = function (res) {
      if (!res.error) {
        pgto = { constante_status: 'AGUARDANDO' };

        crud.data = pgto;
        crud.changed = pgto;

        setPgto(pgto);
      }
    };

    _ks_.cfg = {
      url: 'unlock/HubPedidoVendaPagamento/' + pgto.id_pgto,
      method: 'GET'
    };
    utils.call(_ks_);
  }

  crud.impimirComprovante = function (el, args) {
    if (args && args.data && args.data.caminho) {
      let url = args.data.caminho;
      if (url.indexOf(".pdf") > -1) {
        let pdfUrl = 'https://docs.google.com/viewer?url='+url+'&embedded=true'
        let win = window.open(pdfUrl);
      } else {
        let win = window.open('');
        let style = '<style> @media print { @page {margin: 0 ;}body {padding-top: 0 ;}}</style>';
        let body = '<img src="' + url + '" />';
        let html = '<html><head>'+style+'</head><body>'+body+'</body></html>';
        win.document.write(html);
        win.focus();
      }
    }
  };

  const itens = {
    name: 'itens',
    type: 'list',
    layout: 'table',
    label: 'Pagamentos aguardando aprovação',
    rows: 10,
    add: false,
    showLabel: true,
    header: true,
    main: true,
    list: {
      url: 'listarPagamentosPendenteNew'
    },
    filters: {
      dh_registro: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: "Data Pedido"
      },
      id_status: {
        type: 'select',
        label: "Status Pedido",
        list: { url: 'HubPedidoVendaStatus' },
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_condicao: {
        type: 'select',
        label: "Forma de pagamento",
        list: { url: 'HubCondicaoPagamento' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      constante_status: {
        type: 'select',
        label: "Status pagamento",
        data: [
          { label: 'APROVADO', value: 'APROVADO' },
          { label: 'REPROVADO', value: 'REPROVADO' },
          { label: 'AGUARDANDO', value: 'AGUARDANDO' }
        ],
        itemLabel: 'label',
        itemValue: 'value'
      }
    },
    columns: {
      numero_pedido: { type: 'span', label: 'Número', value: "${'Pedido ' + @this.numero_pedido}", columnStyleClass: "${'ui-col-status-ped ui-col-' + @this.constante_status}" },
      nome_cliente: { type: 'span', label: 'Cliente' },
      valor_pagamento: { label: 'Valor à pagar', type: 'span', format: 'money', bold: true },
      condicao: { label: 'Condição', type: 'span', bold: true },
      dh_pagamento: { label: 'Data do pagamento', type: 'datetime', bold: true },
      descricao_status: { label: 'Status', type: 'span', bold: true },
      usuario: { label: 'Usuário', type: 'span', bold: true },
      origem_usr: { label: 'Origem', type: 'span', bold: true },
      btDetalhe: {
        style: { textAlign: 'right', float: 'right' },
        columnStyleClass: "${exists(@this,'usuario_edicao') ? 'ui-disabled' : ''}",
        label: "VER DETALHES",
        headerLabel: false,
        type: 'action',
        click: {
          action: "editarPgto"
        }
      }
    }
  };

  const aprovacao = {
    type: 'card',
    label: 'Aprovação de pagamento',
    elements: {
      numero_pedido: { type: 'text', label: 'Número', disabled: true },
      codigo_mundial: { cols: 6, type: 'text', label: 'Código mundial', disabled: true },
      codigo_referencia: { cols: 6, type: 'text', label: 'Código ERP', disabled: true },
      nome_cliente: { type: 'text', label: 'Cliente', disabled: true },
      filial: { type: 'text', label: 'Filial', disabled: true },
      valor_pagamento: { label: 'Valor à pagar', type: 'text', format: 'money', disabled: true },
      condicao: { label: 'Condição', type: 'text', disabled: true },
      dh_pagamento: { label: 'Data do pagamento', type: 'datetime', disabled: true, cols: 12 },
      observacao: { label: 'Observação', type: 'textarea', rows: 5, cols: 12, disabled: "${@this.constante_status !== 'AGUARDANDO'}" },
      chave_operacao: { type: 'text', field: 'chave_operacao', label: 'Chave Operação' },
      valor_adiantamento: { label: 'Valor adiantamento', field: 'valor_adiantamento', type: 'text' },
      conta_corrente: { type: 'text', field: 'conta_corrente', label: 'Conta corrente' },
      comprovantes: {
        type: 'list', cols: 12, label: 'Comprovantes:', list: {
          url: 'Documento?entidade=HubPedidoVendaPagamento&id_entidade={id_pgto}', send: false
        },
        styleClass: 'ui-meus-comprovantes',
        search: false,
        header: false,
        controls: false,
        size: 100,
        columns: {
          nome: {},
          caminho: { 
            type: 'img', 
            preview: true,
            click: {
              action: "impimirComprovante"
            }
          }
        }
      },
      acoes: {
        space: 12,
        styleClass: 'ui-actions',
        elements: {
          voltar: {
            background: 'red',
            type: 'button',
            theme: 'secondary',
            label: "VOLTAR",
            click: {
              action: "closePgto"
            }
          },
          reprovar: {
            space: 1,
            background: 'red',
            type: 'button',
            theme: 'secondary',
            label: 'Reprovar',
            disabled: "${@this.constante_status !== 'AGUARDANDO'}",
            click: {
              url: 'cart/disapprove',
              method: 'POST',
              complete: 'closePgto'
            }
          },
          aprovar: {
            space: 1,
            type: 'button',
            label: 'Aprovar',
            disabled: "${@this.constante_status !== 'AGUARDANDO'}",
            click: {
              url: 'cart/approve',
              method: 'POST',
              complete: 'closePgto'
            }
          }
        }
      }
    }
  };

  return (
    <View name="meus-pedidos">
      <div className="ui-meus-pedidos ui-pagamentos-pendentes">
        {pgto.id_pgto > 0 &&
          <>
            <Element value={aprovacao} crud={crud} data={crud.data} view={view} ></Element>
            <Anotacao add={true} label='Anotações do pedido' id={pgto.id_pedido} entidade='HubPedidoVenda' view={view}></Anotacao>
          </>
        }

        {!pgto.id_pgto &&
          <Element value={itens} crud={crud} data={crud.data} view={view} ></Element>
        }
      </div>
    </View>
  );
}

export default PagamentosPendente;