import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';

function MinhasEntrevistas() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [classificacao, setClassificacao] = useState({});
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);


  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  const entrevistas = {
    name: 'itens',
    type: 'list',
    layout: 'card-row',
    label: 'Minhas entrevistas',
    rows: 10,
    add: false,
    showLabel: true,
    list: {
      url: 'listarEntrevistas'
    },
    columns: {
      agrupador: {
        layout: 'grid',
        elements: {
          nome: { field: 'nome', label: 'Consultor' },
          dh_registro: { field: 'dh_registro', format: 'datetime', label: 'Solicitado em', type: 'span' },
          abrir: {
            icon: 'fa fa-arrow-right'
          }
        }
      }
    },
    click: {
      action: "open",
      url: '/entrevistas/consultor/{id_entrevista}'
    }
  };

  return (
    <View name="entrevistas">
      <div className="ui-entrevistas">
        <Element value={entrevistas} crud={crud} data={crud.data} view={view} ></Element>
      </div>
    </View>
  );
}

export default MinhasEntrevistas;