import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Element from './element';
import CustomDialog from './custom_dialog';
import View from './view'; 

function EsqueciSenha() {
  const [crud, setCrud] = useState({ changed: {}, data: {} });
  const [data, setData] = useState({});
  const [view, setView] = useState({});

  window.view = view;

  let login = {
    styleClass: 'ui-login-panel',
    elements: {
      left: {
        cols: 3
      },
      center: {
        cols: 6,
        elements: {
          logar: {
            type: "form",
            elements: {
              logo: { type: 'img', value: 'static/img/logo.svg', center: true },
              voltar: { type: 'link', icon: 'fa fa-arrow-left', href: 'login' },
              login: { type: 'email', label: 'Informe o email para recuperação', name: 'login', title: 'Informe seu email'},
              btnLogar: {
                type: 'button', label: 'RECUPERAR ACESSO', icon: '', styleClass: 'ui-btn-login',
                click: {
                  url: 'recuperarSenha',
                  redirect: '/login',
                  method: 'POST'
                }
              }
            }
          }
        }
      }, right: {
        cols: 3
      }
    }
  };
  return (
    <View full={true}>
      <Element crud={crud} value={login} data={data} user={data} />
    </View>
  );
}

export default EsqueciSenha;