import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioQualificacao() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('qualificacao','Qualificação'),
    name: 'reportCfg',
    add: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_qualificacao'
    },
    filters: {
      mes_referencia: {
        type: 'select',
        label: utils.lng('mes', "Mês"),
        data: [
          //{ label: utils.lng('selecione', "Selecione"), value: null },
          { label: utils.lng('janeiro', "Janeiro"), value: '0' },
          { label: utils.lng('fevereiro', "Fevereiro"), value: '1' },
          { label: utils.lng('marco', "Março") , value: '2' },
          { label: utils.lng('abril', "Abril"), value: '3' },
          { label: utils.lng('maio', "Maio"), value: '4' },
          { label: utils.lng('junho', "Junho"), value: '5' },
          { label: utils.lng('julho', "Julho"), value: '6' },
          { label: utils.lng('agosto', "Agosto"), value: '7' },
          { label: utils.lng('setembro', "Setembro"), value: '8' },
          { label: utils.lng('outubro', "Outubro"), value: '9' },
          { label: utils.lng('novembro', "Novembro"), value: '10' },
          { label: utils.lng('dezembro', "Dezembro"), value: '11' },
        ]
      },
      ano_referencia: {
        type: 'number',
        label: utils.lng('ano', "Ano")
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_cidade: {
        type: 'entity',
        entity: 'Cidade',
        label: utils.lng('cidade', "Cidade"),
        list:{url: 'Cidade'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_estado: {
        type: 'complete',
        label: utils.lng('estado', "Estado"),
        list:{url: 'Estado'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_pais: {
        type: 'complete',
        label: utils.lng('pais', "País"),
        list:{url: 'Pais'},
        itemLabel: 'descricao',
        itemValue: 'id'
      }
    },
    columns: {
      dataQualificacao: { field: 'data_qualificacao', type: 'date', label: utils.lng('data_qualificacao', "Data Qualificação"), filter: false, sort: false },
      codigo: { field: 'codigo_cliente', label: utils.lng('codigo', "Código"), filter: false, sort: false },
      nome: { field: 'nome_cliente', label: utils.lng('nome', "Nome"), filter: false, sort: false },
      graduacao: { field: 'graduacao', label: utils.lng('graduacao', "Graduação"), filter: false, sort: false },
      filial: { field: 'nome_fantasia_filial', label: utils.lng('filial', "Filial"), filter: false, sort: false },
      email: { field: 'email', label: utils.lng('email', "E-mail"), filter: false, sort: false },
      socio: { field: 'nome_socio', label: utils.lng('socio', "Sócio"), filter: false, sort: false },
      pontos: { field: 'pontos', format: 'number', decimalCount: 4, label: utils.lng('pontos', "Pontos"), filter: false, sort: false }
    }
  };

  return (
    <View className="ui-view-report" name="rel-qualificacao">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioQualificacao;