import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";

function Toggle({ value, disabled, label, onChange }) {

  const onClick = function () {
    if (disabled) {
      return;
    }
    if (onChange) {
      const args = {};

      args.value = !value;

      onChange.call(this, args);
    }
  }

  const className = 'ui-toggle ' + (disabled === true ? ' ui-disabled' : '');

  return (
    <span className={className} onClick={onClick}>
      <i className={'fas fa-toggle-' + (value ? 'on' : 'off')}></i>
      {label !== false &&
        <>
          {label &&
            <span className="ui-toggle-label">{label}</span>
          }
          {!label &&
            <span className="ui-toggle-label">{value ? 'Sim' : 'Não'}</span>
          }
        </>
      }
    </span>
  );
}

export default Toggle;