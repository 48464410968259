import React, { useState } from "react";
import View from './view';
import MmnItem from "./mmn_item";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Utils from './utils';
import Element from './element';
import utils from "./utils";

const NetTreeItem = function (props) {
  let [tree, setTree] = useState([]);
  let [ready, setReady] = useState(false);

  let crud = props.crud ? props.crud : {};
  let parent = crud;
  let view = props.view;
  let superior = props.superior;
  let idClienteFiltro = props.clienteFiltro ? props.clienteFiltro : null;
  
  if (!ready || parent.reload) {
    setReady(true);
    parent.reload = false;

    const _call_ = {};

    _call_.global = false;
    _call_.element = {};
    _call_.cfg = {
      url: "listarRede?size=0&superior=" + superior +"&id_cliente_filtro="+idClienteFiltro,
      type: 'GET'
    };

    _call_.complete = function (res) {
      setTree(res.data);
    };

    if (!crud.data) {
      crud.data = [];
    }

    _call_.data = { 
      superior: superior ? superior : 0 , 
      id_cliente_filtro: idClienteFiltro ? idClienteFiltro : null
    };
    _call_.changed = { 
      superior: superior ? superior : 0 , 
      id_cliente_filtro: idClienteFiltro ? idClienteFiltro : null
    };
    _call_.crud = crud;
    _call_.view = view;

    Utils.call(_call_);
  }

  return (
    <>
      {tree.map((cliente, ib) => (
        <TreeItem key={ib+'_'+cliente.id} nodeId={ib+'_'+cliente.id} superior={cliente.id} 
           label={cliente.nome+" - "+cliente.tipo_cliente+"/"+cliente.status_penn+' ('+cliente.filhos+')'}
        >
          <NetTreeItem crud={parent} view={view} superior={cliente.id} clienteFiltro={idClienteFiltro} label={cliente.nome} ></NetTreeItem>
        </TreeItem>
      ))}
    </>
  );
}

function MinhaRede() {
  let [view, setView] = useState({});
  let [showDetail, setShowDetail] = useState(false);
  let [detail, setDetail] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  //const user = utils.getUser();
  let [idSuperior, setIdSuperior] = useState(0);
  let [idClienteFiltro, setIdClienteFiltro] = useState(null);
  let [model, setModel] = useState('');
  let [index, setIndex] = useState(0);
  let tree = [];

  crud.openDetail = function (d) {
    setDetail(d);
    setShowDetail(true);
  };

  const closeDialog = function () {
    setShowDetail(false);
  };

  crud.selecionarCliente = function (cfg, args) {
    tree = [];
    setIdClienteFiltro(args.descendente ? args.descendente : null);
    crud.reload = true;
    index++;
    setIndex(index);
    setCrud(crud);
  };

  crud.selecionarModelo = function (cfg, args) {
    tree = [];
    setModel(args.viewModel ? args.viewModel.constante : '');
    crud.reload = true;
    index++;
    setIndex(index);
    setCrud(crud);
  };

  const descendente = {
    type: Utils.getUser().administrativo === true || (Utils.getUser().tipo+'').indexOf('ADMINISTRATIVO') > -1 ? 'entity' : 'complete',
    entity: 'Cliente',
    name: 'descendente',
    label: Utils.lng('descendente', "Descendente"),
    change: {
      action: 'selecionarCliente'
    },
    list: {
      url: Utils.getUser().administrativo === true || (Utils.getUser().tipo+'').indexOf('ADMINISTRATIVO') > -1 ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede_consultores',
      method: 'GET'
    },
    itemLabel: 'nome',
    itemValue: 'id'
  };

  const viewModel = {
    type: 'select',
    name: 'viewModel',
    label: Utils.lng('modelo_relatorio', "Modelo Relatório"),
    change: {
      action: 'selecionarModelo'
    },
    data: [
      { label: Utils.lng('visualizacao_estruturada', "Visualização Estruturada"), value: 'cards' },
      { label: Utils.lng('visualizacao_simples', "Visualização Simples"), value: 'treeView' }
    ]
  };
  
  const downloadPdf = function () {
    downloadFileReport('pdf');
  };

  const downloadXls = function () {
    downloadFileReport('xlsx');
  };

  const downloadFileReport = function (fileExtension) {
    let args = {};
    args.body = {
      id_cliente_filtro: idClienteFiltro,
      element: {
        columns: {
          tipo: { field: 'tipo_cliente', label: Utils.lng('tipo', "Tipo"), filter: false, sort: false },
          hierarquia: { field: 'arvore_geracao', type: 'date', label: Utils.lng('hierarquia', "Hierarquia"), filter: false, sort: false },
          codigo: { field: 'codigo', label: Utils.lng('codigo', "Cód."), filter: false, sort: false },
          nome: { field: 'nome', label: Utils.lng('nome', "Nome"), filter: false, sort: false },
          titular: { field: 'titular', label: Utils.lng('titular', "Titular"), filter: false, sort: false },
          cidade: { field: 'cidade', label: Utils.lng('cidade', "Cidade"), filter: false, sort: false },
          uf: { field: 'uf', label: Utils.lng('uf', "UF"), filter: false, sort: false },
          filial: { field: 'filial', label: Utils.lng('filial', "Filial"), filter: false, sort: false },
          graduacao: { field: 'graduacao', label: Utils.lng('graduacao', "Graduação"), filter: false, sort: false },
          dataAtivacao: { field: 'data_ativacao', label: Utils.lng('data_ativacao', "Data Ativação"), filter: false, sort: false },
          statusPenn: { field: 'status_penn', label: Utils.lng('status_penn', "Status Penn"), filter: false, sort: false },
          dataUltimaCompra: { field: 'data_ultima_compra', label: Utils.lng('data_ultima_compra', "Última Compra"), filter: false, sort: false },
          email: { field: 'email', label: Utils.lng('email', "E-mail"), filter: false, sort: false },
          telefone: { type: 'tel', mask:'(99)999999999', field: 'telefone', label: Utils.lng('telefone', "Telefone"), filter: false, sort: false }
        }
      }
    };
    const down = {};
    down.method = 'POST';
    down.url = 'report_treeView/report/'+fileExtension;
    down.download = true;
    down.fileName = 'file.'+fileExtension;
    // down.complete = function(res) {
    //   console.log(res);
    // }
    args.cfg = down;
    args.crud = crud;
    Utils.call(args);
  };

  return (
    <View name="ui-mmn">
      <div>
        <Dialog
          className="ui-mmn-dialog"
          open={showDetail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className="ui-dlg-info-header">
              <img src={detail.avatar ? detail.avatar : 'https://cdn.conecteme.com.vc/files/utilizador_0000000001/cb3392bbcb3bdbc7fd24d23d9c7fe7a6.jpeg'}></img>
              <div id="nome" className="ui-mmn-detail-info">
                <b>{detail.nome}</b><br />
                <div className="ui-secondary-detail">{detail.graduacao ? detail.graduacao : 'Não graduado'}</div>
              </div>
            </div>
            <div id="filhos_diretos" className="ui-dlg-info-item">
              <b>{detail.filhos ? detail.filhos : 0}</b> filhos diretos
            </div>
            <div id="pontos_rede" className="ui-dlg-info-item">
              <b>{detail.pontos ? detail.pontos : 0}</b> pontos
            </div>
            <div id="dh_graduacao" className="ui-dlg-info-item">
              Graduado desde <b>{detail.dh_graduacao ? detail.dh_graduacao : 'Não graduado'}</b>
            </div>
          </DialogContent>
          <DialogActions>
            <Button id="btn_fechar" onClick={closeDialog} color="primary">{utils.lng('fechar','Fechar')}</Button>
          </DialogActions>
        </Dialog>

        <div>
          <div className="ui-col ui-col-4">
            <Element crud={crud} view={view} value={descendente}></Element>
          </div>

          <div className="ui-col ui-col-2">
            <Element crud={crud} view={view} value={viewModel}></Element>
          </div>

          {model === 'treeView' &&
            <div className="ui-grid-controls-right ui-grid-controls-middle">
              <Button onClick={downloadPdf} variant="contained" color="primary" style={{marginRight: '15px', height: '45px'}}>
                  <i className="fa fa-file-pdf"></i>
              </Button>
              <Button onClick={downloadXls} variant="contained" color="primary" style={{marginRight: '15px', height: '45px'}}>
                  <i className="fa fa-file-excel"></i>
              </Button>
            </div>
          }
        </div>

        {model === 'cards' &&
          <div id="card" className="ui-mmn-data">
              <>
                <MmnItem id={index+'mmn'} key={index+'mmn'} crud={view} crud={crud} parent={0} clienteFiltro={idClienteFiltro} level={0} data={tree}></MmnItem>
              </>
          </div>
        }
        
        {model === 'treeView' &&
          <div className="ui-layout-group">
            <div className="ui-tree-left">
              <div className="ui-tree-data">
                  <TreeView id={index+'tree'} key={index+'tree'} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                    <NetTreeItem crud={crud} view={view} superior={idSuperior} clienteFiltro={idClienteFiltro}></NetTreeItem>
                  </TreeView>
              </div>
            </div>
          </div>
        }
      </div>
    </View >
  );
}
export default MinhaRede;