import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioAnaliseRiscoInatividade() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('analise_risco_inatividade', 'Análise de Risco de Inatividade'),
    name: 'reportCfg',
    add: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_analiseRiscoInatividadeRede'
    },
    filters: {
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      codigo_filial: { field: 'codigo_filial', label: utils.lng("codigo_filial","Código Filial"), filter: false },
      status_risco: {
        type: 'select',
        label: utils.lng('nivel_risco', 'Nível de risco'),
        data: [
          //{ label: utils.lng('selecione','Selecione'), value: null },
          { label: '1-7 '+utils.lng('dias', 'dias'), value: '1-7 '+utils.lng('dias', 'dias') },
          { label: '8-15 '+utils.lng('dias', 'dias'), value: '8-15 '+utils.lng('dias', 'dias') },
          { label: '16-30 '+utils.lng('dias', 'dias'), value: '16-30 '+utils.lng('dias', 'dias') },
          { label: 'OK', value: 'OK' }
        ]
      },
      id_cidade: {
        type: 'entity',
        entity: 'Cidade',
        label: utils.lng('cidade', "Cidade"),
        list:{url: 'Cidade'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_estado: {
        type: 'complete',
        label: utils.lng('estado', "Estado"),
        list:{url: 'Estado'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_pais: {
        type: 'complete',
        label: utils.lng('pais', "País"),
        list:{url: 'Pais'},
        itemLabel: 'descricao',
        itemValue: 'id'
      }
    },
    columns: {
      codigo: { field: 'codigo_mundial', label: utils.lng('codigo','Código'), filter: false, sort: false },
      nome: { field: 'nome_cliente', label: utils.lng('nome','Nome'), filter: false, sort: false },
      graduacao: { field: 'graduacao_vigente', label: utils.lng('graduacao','Graduação'), filter: false, sort: false },
      statusPenn: { field: 'status_penn', label: utils.lng('status_penn','Status Penn'), filter: false, sort: false },
      dataAtivacaoPenn: { field: 'data_registro_penn', type:'date' , label: utils.lng('data_ativacao_penn','Data Ativação Penn'), filter: false, sort: false },
      codigoFilial: { field: 'codigo_filial', label: utils.lng('codigo_filial','Código da Filial'), filter: false, sort: false },
      pontos: { field: 'saldo_pontos', format: 'number', decimalCount: 4, label: utils.lng('pontos','Pontos'), filter: false, sort: false },
      statusRisco: { field: 'status_risco', label: utils.lng('status_risco','Status de Risco'), filter: false, sort: false },
      pai: { field: 'nome_pai_empresarial', label: utils.lng('pai_empresarial','Pai Empresarial'), filter: false, sort: false }
    }
  };
  return (
    <View className="ui-view-report" name="rel-risco-inatividade">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioAnaliseRiscoInatividade;