import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from "./utils";
import Button from '@material-ui/core/Button';

const Formulario = () => {
    const [element, setElement] = useState({});
    const [crud, setCrud] = useState({ data: {}, changed: {} });
    const [data, setData] = useState({});
    const [view, setView] = useState({});
    const [name, setName] = useState('none');
    let [ready, setReady] = useState(false);

    let split = window.location.pathname.substring(1).split('/');

    useEffect(() => {

        if (!ready) {
            if (split.length > 1) {
                setName(split[1]);

                utils.execute(crud, 'component/' + split[1], null, function (res) {
                    setElement(res.data);
                    setReady(true);
                }, true);
            } else {
                setReady(true);
            }
        }
    }, []);


    const onClickFormulario = function () {
        const _call_ = {};
        const btn = {
            click: {
                url: 'form/' + name + '/save',
                method: 'POST'
            }
        };

        _call_.event = 'click';
        _call_.element = btn;
        _call_.cfg = btn.click;
        _call_.data = crud.data;
        _call_.changed = crud.changed;
        _call_.crud = crud;
        _call_.view = view;
        _call_.validate = true;

        utils.call(_call_);
    };

    window.crud = crud;

    return (
        <View name="entrevista">
            {ready &&
                <>
                    <Element view={view}
                        value={element}
                        data={data}
                        crud={crud}
                    />
                    <div className="ui-acoes-entrevista">
                        <center>
                            <Button onClick={onClickFormulario} variant="contained">
                                {utils.lng('enviar_formulario', 'Enviar formulario')}
                            </Button>
                        </center>
                    </div>
                </>
            }
            {!ready &&
                <div className="ui-loading">
                    <i className="fas fas fa-circle-notch fa-spin"></i>
                </div>
            }
        </View>
    )
};

export default Formulario;