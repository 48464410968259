import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import MenuCarrinho from './menu_carrinho';
import Promocao from './promocao';

function Promocoes() {
  const path = window.location.pathname;
  const split = path.split('/');
  let idClassificacao = 0;

  if (split.length >= 3) {
    idClassificacao = parseInt(split[2]);
  }

  window.processo_atual = 73;

  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: { classificacao: idClassificacao } });
  let [promocoes, setPromocoes] = useState([]);
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  useEffect(() => {
    if (!ready) {
      setReady(true);

      utils.execute(crud, 'listarPromocoes', null, function (res) {
        setPromocoes(res.data);
      });
    }

  }, []);

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }


  return (
    <View name="carrinho">
      <div className="ui-catalogo ui-promocoes">
        <MenuCarrinho crud={crud} view={view} ></MenuCarrinho>

        <div className="ui-catalogo-content" >
          <div className="ui-catalogo-data" >

            {promocoes.length <= 0 &&
              <div className="ui-promocao-label ui-promocao-empty">
                {utils.lng('sem_promocoes','Sem promoções')}
            </div>
            }
            {promocoes.length > 0 &&
              <div className="ui-promocao-label">
                Promoções
            </div>
            }
            {promocoes.map((p, index) => (
              <>
                <Promocao data={p} crud={crud} view={view} />
              </>
            ))}
          </div>
        </div>
      </div>
    </View>
  );
}

export default Promocoes;