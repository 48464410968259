import React, { useState, useEffect } from "react";
import Utils from './utils';
import Crud from './crud';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import utils from "./utils";
import Element from './element';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import InputMask from 'react-input-mask';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const TableInner = ({ parentQuery, id, value, element, disabled, data, changed, onClick, crud, user }) => {
    let saved = utils.get(value.field, data);
    let bkp = [];

    if (!saved) {
        saved = [];
    }

    for (const i in saved) {
        const v = saved[i];

        if (!v.id) {
            bkp.push(v);
        }
    }

    let changedFilter = {};

    let [news, setNews] = useState(bkp);
    let [list, setList] = useState([]);
    let [page, setPage] = useState(1);
    let [index, setIndex] = useState(0);
    let [selectedIndex, setSelectedIndex] = useState(value.selectedIndex >= 0 ? value.selectedIndex : -1);
    let [total, setTotal] = useState(0);
    let [query, setQuery] = useState(parentQuery ? parentQuery : '');
    let [scrollLeft, setScrollLeft] = useState(0);
    let [sortBy, setSortBy] = useState({});
    let [crudFilter, setCrudFilter] = useState({ data: changedFilter, changed: changedFilter });
    let [dialog, setDialog] = useState(false);
    let [scrolling, setScrolling] = useState(false);
    let [filter, setFilter] = useState(false);
    let [loading, setLoading] = useState(false);
    let [ready, setReady] = useState(false);
    let [model, setModel] = useState({ edit: false, dialog: true });
    let [all, setAll] = useState([]);
    let view = window.view;
    let [detail, setDetail] = useState('');
    let [size, setSize] = useState(value.size ? value.size : 10);
    let [open, setOpen] = React.useState(false);
    let [parent, setParent] = useState(0);
    let [aggList, setAggList] = useState([]);

    let field = value.field;
    let b = [];

    if (typeof field !== 'undefined' && field.indexOf('.') > 0) {
        const s = field.split('.');

        for (let i = 0; i < s.length - 1; i++) {
            b.push(s[i]);
        }
    }

    b.push('id');

    const aux = b.join('.');

    try {
        parent = eval('data.' + aux);
    } catch (error) {
    }

    if (!parent) {
        parent = 0;
    }


    if (value.list) {
        model.parent = value.list.mappedBy;

        if (value.search) {
            model.entity = value.search.entity;
        } else {
            model.entity = value.entity;
        }
        all = [];
    }

    model.crud = value.crud;

    const close = () => {
        setModel(model);
        setDialog(false);

        search();
    }

    model.close = () => {
        close();
    }
    model.refresh = function () {
        search();
    };

    value.refresh = function () {
        search();
    };

    model.confirm = () => {
        const sel = model.selected;

        if (value.type === 'list') {
            if (!model.edit || model.create) {
                let _list_ = [];

                try {
                    _list_ = eval('data.' + value.field);
                } catch (error) {
                }

                if (!_list_) {
                    _list_ = [];
                }
                _list_.push(sel);

                Utils.set(value.field, data, _list_);
                Utils.set(value.field, changed, _list_);

                setNews(_list_);
                news = _list_;
            }
            model.close();
        }
    }

    const select = (cfg, args) => {
        const sel = args.data;
        const indx = cfg.index;
        const click = args.click;

        if (typeof click.set === 'object') {
            for (const fi in click.set) {
                const fe = click.set[fi];

                Utils.set(fi, data, sel[fe]);
                Utils.set(fi, changed, sel[fe]);
            }
        }
    }

    const remove = (cfg, args) => {
        const sel = args.data;
        const indx = args.index;

        if (sel.id > 0) {
            const complete = function () {
                setLoading(false);
                value.refresh();
            }
            const _call_ = {};
            const put = {};

            put.method = 'DELETE';

            if (value.list.mappedBy === 'parent') {
                put.url = crud.entity + '/' + crud.id + '/' + value.field + '/' + sel.id;
            } else {
                put.url = value.entity + '/' + sel.id;
            }

            _call_.element = value;
            _call_.cfg = put;
            _call_.complete = complete;
            _call_.global = false;
            _call_.data = sel;
            _call_.changed = null;
            _call_.crud = crud;
            _call_.view = view;

            setLoading(true);

            Utils.call(_call_);
        } else {
            let _list_ = [];

            for (let i in all) {
                i = parseInt(i);
                const v = all[i];

                if (i !== indx) {
                    _list_.push(v);
                }
            }

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            news = _list_;
            all = _list_;
            setNews(_list_);
            setAll(_list_);

            model.close();
        }
    }
    model.rowSelect = (cfg, args) => {
        const sel = args.data;

        if (data.id > 0 || !value.field) {
            if (value.search) {
                const complete = function () {
                    setLoading(false);


                    delete model.id;
                    delete model.data;
                    delete model.selected;

                    model.edit = false;
                    value.refresh();
                }

                const _call_ = {};
                const put = {};

                put.method = 'POST';
                put.alert = true;
                put.url = value.entity;

                model.changed[value.target] = sel.id;

                if (value.parent && !model.changed[value.parent]) {
                    model.changed[value.parent] = data[value.parent];
                }

                _call_.element = value;
                _call_.cfg = put;
                _call_.complete = complete;
                _call_.data = sel;
                _call_.changed = model.changed;
                _call_.crud = model;
                _call_.view = view;

                setLoading(true);

                Utils.call(_call_);
            } else {
                const complete = function () {
                    setLoading(false);


                    delete model.id;
                    delete model.data;
                    delete model.selected;

                    model.edit = false;
                    value.refresh();
                }
                const _call_ = {};
                const put = {};

                put.method = 'PUT';
                put.alert = false;
                put.url = crud.entity + '/' + crud.id + '/' + value.field + '/' + sel.id;

                _call_.element = value;
                _call_.cfg = put;
                _call_.complete = complete;
                _call_.data = sel;
                _call_.changed = null;
                _call_.crud = crud;
                _call_.view = view;

                setLoading(true);

                Utils.call(_call_);
            }
        } else {
            let _list_ = eval('data.' + value.field);

            if (!_list_) {
                _list_ = [];
            }
            _list_.push(sel);

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            news.push(sel);
            model.close();
        }
        return false;
    }

    const rowEdit = function (element, args) {
        const _data_ = args.data;

        model.edit = true;
        model.create = false;
        model.new = !(_data_.id > 0);
        model.id = _data_.id > 0 ? _data_.id : 0;
        model.entity = element.entity;
        model.init = _data_.id > 0;
        model.data = _data_;
        model.selected = _data_;
        model.changed = {};
        model.changed.entity = value.entity;

        setModel(model);
        setDialog(true);
    };

    const moveUp = function (element, args) {
        move(args, -1);
    };

    const moveDown = function (element, args) {
        move(args, 1);
    };

    const move = function (args, pos) {
        let oldIndx = args.index;
        let newIndx = oldIndx + pos;
        let aux = [];

        if (newIndx < 0) {
            newIndx = list.length - 1;
        }

        if (newIndx >= list.length) {
            newIndx = 0;
        }

        if (newIndx >= list.length) {
            var k = newIndx - list.length + 1;
            while (k--) {
                list.push(undefined);
            }
        }
        list.splice(newIndx, 0, list.splice(oldIndx, 1)[0]);

        setList(list);

        all = [];

        for (const i in news) {
            all.push(news[i]);
        }
        for (const i in list) {
            const o = list[i];

            o.ordem = i * 10;

            if (o.id > 0) {
                aux.push({ id: o.id, ordem: o.ordem });
            } else {
                aux.push(o);
            }

            all.push(o);
        }
        setAll(all);

        Utils.set(value.field, changed, aux);
    };

    async function search(cfg, args) {
        if (!cfg) {
            cfg = value.list;
        }
        setReady(true);

        if (cfg) {
            if (!data) {
                data = {};
            }

            const complete = function (res) {
                setLoading(false);

                if (!cfg.download) {
                    const t = res.total;

                    aggList = [];

                    if (res.aggregate) {
                        aggList = res.aggregate;
                    }

                    setAggList(aggList);

                    if (res.data) {
                        for (const i in res.data) {
                            res.data[i].rowIndex = parseInt(i);
                        }
                        setList(res.data);
                        setTotal(res.total);
                    } else {
                        setList([]);
                        setTotal(0);
                    }

                    if (t > 0) {
                        value.show();
                    } else {
                        value.hide();
                    }

                    let x = size * page;
                    let y = 0;
                    let z = x;

                    if (z > t) {
                        z = t;
                    }

                    if (page > 1) {
                        y = x - size;
                    }

                    detail = (y + 1) + '-' + z;

                    setDetail(detail);
                }
            };

            let a = {};

            a.query = query;
            a.lazy = true;
            a.size = size;
            a.page = page;
            a.field = value.field;

            if (value.fields) {
                const fil = [];

                for (const i in value.fields) {
                    fil.push(value.fields[i]);
                }
                a.fields = fil.join(',');
            }

            a.filters = {};

            for (const i in filters) {
                let fil = filters[i];
                let name = fil.field ? fil.field : fil.name;
                let val = crudFilter.changed[name];
                let dataType = fil.dataType ? fil.dataType : 'string';

                if (typeof val != 'undefined' && val !== null) {
                    if (typeof val === 'object') {
                        if (val.constante) {
                            val = val.constante;
                        } else if (val.id) {
                            val = val.id;
                        } else if (val.value) {
                            val = val.value;
                        }
                    }
                    a[name] = val;
                }
            }

            a.parentClass = crud.entity;

            if (sortBy.name) {
                a.sortBy = sortBy.name;
                a.sortType = sortBy.sortType;
            }

            const mappedBy = value.mappedBy;

            if (mappedBy) {
                a[mappedBy] = parent;
            } else {
                a.id = data.id;
            }


            if (args) {
                for (const i in args) {
                    a[i] = args[i];
                }
            }

            const _call_ = {};


            if (element.main && !query) {
                let qs = window.location.search;

                if (qs) {
                    qs = qs.substr(1, qs.length);

                    let aqs = qs.split('&');

                    for (let iq in aqs) {
                        let vs = aqs[iq];

                        let bqs = vs.split('=');

                        a[bqs[0]] = bqs[1];
                    }

                    if (a.query) {
                        setQuery(a.query);
                    }
                }
            }

            const aggregate = [];

            for (const ci in columns) {
                const cv = columns[ci];

                if (cv.aggregate) {
                    aggregate.push(cv.field + ':' + cv.aggregate);
                }
            }

            if (aggregate.length > 0) {
                a.aggregate = aggregate.join(',');
            }


            _call_.element = value;
            _call_.cfg = cfg;
            _call_.body = cfg.body;
            _call_.complete = complete;
            _call_.data = data;
            _call_.changed = crud.changed;
            _call_.search = a;
            _call_.crud = crud;
            _call_.view = view;

            setLoading(true);

            Utils.call(_call_);
        } else if (element.data) {
            setAll(element.data);
        }
    };

    const columns = [];
    const filters = [];
    const temp = [];

    let hasFilters = false;

    if (value.filters) {
        for (const i in value.filters) {
            const col = value.filters[i];

            if (!col.name) {
                col.name = i;
            }

            if (!col.field) {
                col.name = i;
            }

            if (!col.id) {
                col.name = col.name;
            }

            if (!col.type) {
                col.type = 'span';
            }
            col.filter = true;
            col.name = i;
            temp.push(col);
            hasFilters = true;
        }
    }

    if (value.columns) {
        for (const i in value.columns) {
            const col = value.columns[i];

            if (!col.name) {
                col.name = i;
            }

            if (!col.field) {
                col.name = i;
            }

            if (!col.id) {
                col.name = col.name;
            }

            if (!col.type) {
                col.type = 'span';
            }

            if (col.attrs) {
                for (const key in col.attrs) {
                    col[key] = col.attrs[key];
                }
            }

            if (col.visible !== false) {
                col.name = i;
                columns.push(col);
            }

            if (col.filter === true && !hasFilters) {
                col.name = i;
                temp.push(col);
            }
        }
    }

    for (const i in temp) {
        const ft = temp[i];
        const fi = {};

        for (const ai in ft) {
            fi[ai] = ft[ai];
        }

        if (fi.type === 'span') {
            fi.type = 'text';
        }

        filters.push(fi);
    }

    useEffect(() => {
        if (!ready) {
            if (value.auto !== false) {
                search();
            } else {
                setReady(true);
            }
        }
    }, []);

    let rowCols = value.rowCols;
    let rowClassName = 'ui-table-row';


    const keyPressed = function (event) {
        if (event.key === "Enter") {
            search(value.list);
        }
    }

    const onChangeQuery = function (event) {
        setQuery(event.target.value);
    }

    const onChangeSize = function (event) {
        size = parseInt(event.target.value);
        page = 1;

        setPage(page);
        setSize(size);
        search(value.list);
    }

    const paginate = function (p) {
        let x = total / size;

        if (p - x > 0 && p - x < 1) {
            x = p;
        }

        if (p > x || p <= 0) {
            return;
        }
        page = p;

        setPage(page);
        search(value.list);
    }
    const first = function () {
        paginate(1);
    }
    const previous = function () {
        paginate(page - 1);
    }
    const next = function () {
        paginate(page + 1);
    }
    const last = function () {
        let x = total / size;
        let y = parseInt(x);

        if (x > y) {
            y++;
        }
        paginate(y);
    }

    const add = function () {
        const mappedBy = value.list.mappedBy;

        if (typeof value.add === 'object') {
            const act = { click: value.add };

            if (crud[act.click.action]) {
                crud[act.click.action].call(this, act, changed);
            }
            return;
        } else if (mappedBy) {
            const isNew = !crud.selected.id > 0;

            if (mappedBy === 'parent') {
                model.edit = false;
                model.create = false;
                model.click = value.click;
                model.selected = {};
                model.id = -1;
            } else {
                let ent = value.entity;

                if (value.search) {
                    model.edit = false;
                    model.create = false;
                    model.selected = {};
                    model.changed = {};
                    model.click = {
                        action: 'rowSelect'
                    };
                } else {
                    model.edit = true;
                    model.create = true;
                    model.id = 0;
                    model.new = isNew;
                    model.selected = { id: 0 };
                }

                model.selected[mappedBy] = parent;
                model.selected.entity = ent;
            }

            if (element.params) {
                const params = utils.decode(value.params, crud, data, {});

                if (params) {
                    for (const i in params) {
                        model.selected[i] = params[i];
                    }
                }
            }

            model.data = model.selected;
            model.changed = model.selected;

            setModel(model);
            setDialog(true);
        } else {
            crud.add(value);
        }
    }

    const getColumnClass = function (cl, data, header) {
        const res = Utils.decode(cl, crud, data);
        let cname = 'ui-table-col ui-col-' + cl.name + (cl.click ? ' ui-col-act' : '');

        if (cl.columnStyleClass) {
            cname = cname + ' ' + res.columnStyleClass;
        }

        if (cl.type) {
            cname = cname + ' ui-col-type-' + cl.type;
        }

        if (cl.format) {
            cname = cname + ' ui-col-type-' + cl.format;
        }

        if (header) {
            cname = cname + ' ui-col-header ';
        }

        if (res.bold) {
            cname = cname + ' ui-bold bold ';
        }

        return cname;
    }
    const getColumnStyle = function (cl, data) {
        const res = Utils.decode(cl, crud, data);

        if (res.columnStyle) {
            return res.columnStyle;
        }

        return {};
    }

    const getRowStyleClass = function (li, ib, header) {
        const res = Utils.decode(value, crud, li);

        let cname = rowClassName + ' ui-row-' + (ib % 2 == 0 ? 'even' : 'odd')

        if (res.rowStyleClass) {
            cname = res.rowStyleClass + ' ' + cname;
        }

        if (header) {
            cname = cname + ' ui-row-header ';
        }

        if (header) {

        } else if (value.click || rowClick) {
            cname = cname + ' ui-click ';
        }

        return cname;
    }
    const getRowStyle = function (li, ib) {
        const res = Utils.decode(value, crud, li);

        if (res.rowStyle) {
            return res.rowStyle;
        }

        return {};
    }

    const getSortClass = function (_col_) {
        if (_col_.sort !== true) {
            return '';
        }
        if (sortBy.name == _col_.name) {
            if (_col_.sortType == 'asc') {
                return 'ui-col-sort fa fa-angle-up';
            } else if (_col_.sortType == 'desc') {
                return 'ui-col-sort fa fa-angle-down';
            }
        }
        return 'ui-col-sort fa fa-sort';
    }
    const sortColumn = function (_col_) {
        if (_col_.sort !== true) {
            return;
        }

        if (_col_.sortType == 'asc') {
            _col_.sortType = 'desc';
        } else {
            _col_.sortType = 'asc';
        }

        setSortBy(_col_);
        search();
    }

    crud.expansion = function (value, args) {
        if (args.click) {
            if (args.index === value.selectedIndex) {
                value.selectedIndex = -1;
            } else {
                value.selectedIndex = args.index;
            }
            setSelectedIndex(value.selectedIndex);
            setIndex(index + 1);
            value.refresh();
        }
    };

    const rowClick = function (_data_, _col_, index) {
        if (value.edit === false) {
            return;
        }
        if (_col_ && _col_.click === false) {
            return;
        }
        let click = crud.click ? crud.click : value.click;
        let args = {};

        if (_col_ && _col_.click) {
            click = _col_.click;
        }

        const _userComplete_ = function () {
            const ccomp = click.complete;

            if (ccomp) {
                const ccall = typeof ccomp === 'string' ? crud[ccomp] : ccomp;

                if (ccall) {
                    ccall.call(this, value, args);
                }
            }
            if (value.closeDialog) {
                value.closeDialog();
            }
        };

        args.column = _col_;
        args.click = click;
        args.parent = data.id;
        args.data = _data_;
        args.index = index;

        if (value.list) {
            args.entity = value.list.entity;
        }

        const _execute_ = function () {
            if (onClick) {
                onClick.call(this, click, args, value);
            } else if (typeof click === 'function') {
                click.call(this, click, args);
            } else if (typeof click === 'object') {
                const act = click.action;

                if (act) {
                    if (act === 'moveUp') {
                        moveUp(value, args);
                    } else if (act === 'select') {
                        select(value, args);
                    } else if (act === 'moveDown') {
                        moveDown(value, args);
                    } else if (act === 'rowEdit') {
                        rowEdit(value, args);
                    } else if (act === 'remove') {
                        remove(value, args);
                    } else if (crud[act]) {
                        crud[act].call(this, value, args);
                    } else if (view[act]) {
                        view[act].call(this, value, args);
                    }
                    _userComplete_();
                } else if (click.url) {
                    const complete = function (res) {
                        _userComplete_();
                        setLoading(false);
                        search();
                    }

                    const _call_ = {};

                    _call_.element = value;
                    _call_.cfg = click;
                    _call_.complete = complete;
                    _call_.data = _data_;
                    _call_.parent = data.id;
                    _call_.crud = crud;
                    _call_.view = view;

                    setLoading(true);

                    Utils.call(_call_);
                }
            }
        }
        utils.prompt(click, _execute_);
    }

    const showFilter = function () {
        setFilter(!filter);
    };

    const downloadPdf = function () {
        const down = {};

        down.method = 'POST';
        down.url = value.list.url + '/report/pdf';
        down.download = true;
        down.fileName = 'file.pdf';
        down.body = { element: value };

        search(down);
    };

    const downloadXls = function () {
        const down = {};

        down.method = 'POST';
        down.url = value.list.url + '/report/xls';
        down.fileName = 'file.xlsx';
        down.download = true;
        down.body = { element: value };

        search(down);
    };

    const onChangeDateFilter = function (col, pos, el) {
        let v = el.target.value

        if (pos === 'start') {
            col.filterStart = v;
        }

        if (pos === 'end') {
            col.filterEnd = v;
        }

        if (col.filterStart && col.filterEnd) {
            col.filterValue = col.filterStart + ' | ' + col.filterEnd;
            search();
        }
    };

    const onChangeFilter = function (col, el) {
        let v = el.target.value

        col.filterValue = v;

        search();
    };

    const onKeyDownFilter = function (col, el) {
        let v = el.target.value

        col.filterValue = v;

        if (v.length >= 3) {
            search();
        }
    };

    const itens = [];

    itens.push({ label: 'Todos', value: 0 });
    itens.push({ label: '5', value: 5 });
    itens.push({ label: '10', value: 10 });
    itens.push({ label: '15', value: 15 });
    itens.push({ label: '20', value: 20 });
    itens.push({ label: '25', value: 25 });
    itens.push({ label: '30', value: 30 });
    itens.push({ label: '40', value: 40 });
    itens.push({ label: '50', value: 50 });
    itens.push({ label: '100', value: 100 });

    for (const i in news) {
        all.push(news[i]);
    }
    for (const i in list) {
        all.push(list[i]);
    }

    const nav = function (pos) {
        const el = document.getElementById(value.clientId);

        if (!el) {
            alert('Element not found: ' + value.clientId);
            return;
        }
        const grid = el.getElementsByClassName('ui-table')[0];
        const col = el.getElementsByClassName('ui-table-row')[0];
        const wid = col.clientWidth;
        let npos = grid.scrollLeft;

        setScrolling(true);

        if (pos < 0) {
            npos = npos - wid;
        } else {
            npos = npos + wid;
        }
        if (npos >= 0) {
            grid.scrollLeft = npos;

            setScrollLeft(npos);
        }
        setTimeout(function () { setScrolling(false); }, 500)
    }

    const navLeft = function () {
        nav(-1);
    }

    const navRight = function () {
        nav(1);
    }

    const getFileAddedMessage = function (e) {
        return value.successMessage ? value.successMessage : "Arquivo " + e + " adicionado com sucesso.";
    }

    const onUpload = function (file, result) {
        let _list_ = [];
        const sel = {};

        try {
            _list_ = eval('data.' + value.field);
        } catch (error) {
        }

        const mappedBy = value.list.mappedBy;
        const parent = data[mappedBy] ? data[mappedBy].id : data.id;
        const cfg = value.upload;

        for (const i in cfg) {
            const v = cfg[i];

            if (file[i]) {
                if (typeof v === 'object') {
                    for (const vi in v) {
                        const va = v[vi];

                        sel[va] = file[i];
                    }
                } else {
                    sel[v] = file[i];
                }
            }
        }

        sel[mappedBy] = parent;
        sel[cfg.value] = result;

        if (value.defaults) {
            for (const i in value.defaults) {
                sel[i] = value.defaults[i];
            }
        }

        const complete = function (res) {
            setLoading(false);
            search();
        }

        if (parent > 0) {
            const _call_ = {};
            const put = {};

            put.method = 'POST';
            put.alert = false;
            put.url = value.entity + '/';

            _call_.element = value;
            _call_.cfg = put;
            _call_.complete = complete;
            _call_.data = sel;
            _call_.changed = sel;
            _call_.crud = crud;
            _call_.view = view;

            setLoading(true);

            Utils.call(_call_);
        } else {
            if (!_list_) {
                _list_ = [];
            }

            _list_.push(sel);

            Utils.set(value.field, data, _list_);
            Utils.set(value.field, changed, _list_);

            setNews(_list_);
            news = _list_;
        }
    }

    const fileUploadDrop = function (files) {

        for (const i in files) {
            const file = files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                onUpload(file, reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }

    if (value.widgetVar) {
        const w = {};

        w.element = value;
        w.search = search;
        w.next = next;
        w.previous = previous;
        w.first = first;
        w.last = last;

        window[value.widgetVar] = w;
    }

    return (
        <>
            <div id={id} className="ui-search">
                {value.search !== false && value.layout !== 'carousel' &&
                    <div className="ui-search-top">
                        <div className="ui-search-left">
                            <div className="ui-search-box">
                                <Form.Control value={query} onChange={onChangeQuery} onKeyPress={keyPressed} type="text" placeholder={utils.lng('query', 'Pesquisar')} />
                                <a onClick={() => { search() }}>
                                    <i className="fa fa-search"></i>
                                </a>
                            </div>
                            {crud && value.add &&
                                <Button disabled={disabled} onClick={add} variant="contained" color="secondary">
                                    <i className="fa fa-plus"></i>Novo
                                </Button>
                            }
                            {typeof value.upload !== 'undefined' &&
                                <>
                                    <Button disabled={disabled} variant="contained" color="primary" onClick={() => setOpen(true)}>
                                        <i className="fa fa-upload"></i>
                                        {value.selectLabel ? value.selectLabel : value.label}
                                    </Button>
                                    <DropzoneDialog
                                        acceptedFiles={['image/*']}
                                        cancelButtonText={utils.lng('cancelar', 'Cancelar')}
                                        submitButtonText={utils.lng('enviar', 'Enviar')}
                                        dropzoneText={value.dropLabel}
                                        filesLimit="10"
                                        dialogTitle={value.label}
                                        getFileAddedMessage={getFileAddedMessage}
                                        onChange={fileUploadDrop}
                                        maxFileSize={5000000}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onSave={(files) => {
                                            setOpen(false);
                                        }}
                                    />
                                </>
                            }
                            {filters.length > 0 && value.filter !== false &&
                                <>
                                    <Button onClick={showFilter} variant="contained" color="primary">
                                        <i className="fa fa-filter" id="btn_filtro"></i>{Utils.lng('btn_filtro', 'Filtro')}
                                    </Button>
                                    <Button onClick={downloadPdf} variant="contained" color="primary">
                                        <i className="fa fa-file-pdf"></i>
                                    </Button>
                                    <Button onClick={downloadXls} variant="contained" color="primary">
                                        <i className="fa fa-file-excel"></i>
                                    </Button>
                                </>
                            }
                        </div>
                    </div>
                }
                {filter &&
                    <>
                        {filters.length > 0 &&
                            <div className="ui-search-filters">
                                {filters.map((fi, ib) => (
                                    <div key={'filter-' + ib} className="ui-filter-item">
                                        <Element crud={crudFilter} data={changedFilter} value={fi}></Element>
                                    </div>
                                ))}
                                <div className="ui-filter-apply">
                                    <Button onClick={() => { search() }} variant="contained" color="primary">
                                        <i className="fa fa-search"></i><span style={{ paddingLeft: '10px' }}>Aplicar filtro</span>
                                    </Button>
                                </div>
                            </div>
                        }
                    </>
                }
                {value.layout === 'carousel' &&
                    <div className="ui-search-nav-left" onClick={navLeft}>
                        <i className="fa fa-angle-left" />
                    </div>
                }
                {value.layout === 'carousel-vertical' &&
                    <div className="ui-search-nav-left" onClick={navLeft}>
                        <i className="fa fa-angle-up" />
                    </div>
                }
                <div className="ui-search-data">
                    {loading &&
                        <div>
                            <div className="ui-loading">
                                <i className="fas fas fa-circle-notch fa-spin"></i>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <>
                            <table className={scrolling ? 'ui-table ui-table-scrolling' : 'ui-table'} scrollleft={scrollLeft}>
                                {(all.length > 0 || all.length > 0) &&
                                    <>
                                        {value.groups &&
                                            <>
                                                {value.groups.map((li, ib) => (
                                                    <tr className={getRowStyleClass(li, -1, true) + ' ui-row-group'}>
                                                        {value.groups[ib].map((cl, ia) => (
                                                            <td colSpan={cl.cols} onClick={() => { sortColumn(cl) }} key={ia} style={getColumnStyle(cl, li)}
                                                                className={getColumnClass(cl, li, true)}>
                                                                {utils.lng(cl.name, cl.label)}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        }
                                        {value.header !== false &&
                                            <tr className={getRowStyleClass({}, -1, true)}>
                                                {columns.map((cl, ia) => (
                                                    <td onClick={() => { sortColumn(cl) }} key={ia} className={getColumnClass(cl, ia, true)} >
                                                        {utils.lng(cl.name, cl.label)}<i className={getSortClass(cl)}></i>
                                                    </td>
                                                ))}
                                            </tr>
                                        }
                                        {all.map((li, ib) => (
                                            <>
                                                <tr key={ib} className={getRowStyleClass(li, ib)} style={getRowStyle(li, ib)}>
                                                    {columns.map((cl, ibc) => (
                                                        <td onClick={() => { rowClick(li, cl, ib) }} key={ibc} style={getColumnStyle(cl, li)} className={getColumnClass(cl, li)}>
                                                            <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={!cl.rowEdit}
                                                                value={cl} disabled={disabled} index={ibc} data={li} changed={li} />
                                                        </td>
                                                    ))}
                                                    {value.elements &&
                                                        <div className="ui-col-element">
                                                            {Object.entries(value.elements).map((el, index) => (
                                                                <Element parent={value} view={view} crud={crud} name={el[0]} key={'col' + index}
                                                                    value={el[1]} disabled={disabled} value={el[1]} index={index} data={li} changed={li} />
                                                            ))}
                                                        </div>
                                                    }
                                                </tr>
                                                {ib === value.selectedIndex &&
                                                    <tr className="ui-table-row ui-table-sub">
                                                        <td className="ui-table-col ui-col-12">
                                                            <Element parentQuery={query} id={'sub-' + ib} value={value.sub} crud={crud} data={li} view={view} ></Element>
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        ))}
                                    </>
                                }
                                {aggList.map((li, ib) => (
                                    <>
                                        {(!value.fixSelected || (ib === value.selectedIndex || selectedIndex < 0)) &&
                                            <>
                                                <tr key={ib} className={getRowStyleClass(li, ib) + ' ui-row-agg'} style={getRowStyle(li, ib)}>
                                                    {columns.map((cl, ibc) => (
                                                        <td style={getColumnStyle(cl, li)} className={getColumnClass(cl, li)}>
                                                            {li[cl.field] &&
                                                                <Element parent={value} view={view} crud={crud} name={cl.name} column={true} readOnly={!cl.rowEdit}
                                                                    value={cl} disabled={disabled} index={ibc} data={li} changed={li} />
                                                            }
                                                        </td>
                                                    ))}
                                                </tr>
                                            </>
                                        }
                                    </>
                                ))}
                                {all.length <= 0 &&
                                    <tr className="ui-table-row ui-col ui-col-12">
                                        <td className="ui-table-col ui-col-empty">
                                            {typeof value.empty !== 'undefined' ? value.empty : utils.lng('no_results', 'Nenhum registro encontrado')}
                                        </td>
                                    </tr>
                                }
                            </table>
                            {value.controls !== false && value.layout !== 'carousel' && list.length > 0 &&
                                <div className="ui-grid-controls">
                                    <div className="ui-grid-controls-right">
                                        {size} {utils.lng('linhas', 'Linhas')}

                                        <Form.Control value={size} onChange={onChangeSize} as="select">
                                            {itens.map((i, io) => (
                                                <option key={io} value={i.value} index={io} >{i.label}</option>
                                            ))}
                                        </Form.Control>
                                        {value.feedback !== false &&
                                            <div className="ui-search-feedback">
                                                {detail} {utils.lng('linhas_de', 'de')}{total}
                                            </div>
                                        }
                                        <a onClick={first}>
                                            <i className="fa fa-fast-backward"></i>
                                        </a>
                                        <a onClick={previous}>
                                            <i className="fa fa-arrow-left"></i>
                                        </a>
                                        <a onClick={next}>
                                            <i className="fa fa-arrow-right"></i>
                                        </a>
                                        <a onClick={last}>
                                            <i className="fa fa-fast-forward"></i>
                                        </a>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {value.layout === 'carousel' &&
                    <div className="ui-search-nav-right" onClick={navRight}>
                        <i className="fa fa-angle-right" />
                    </div>
                }
                {value.layout === 'carousel-vertical' &&
                    <div className="ui-search-nav-right" onClick={navRight}>
                        <i className="fa fa-angle-down" />
                    </div>
                }
            </div>
            {dialog &&
                <>
                    <div className="ui-overlay">
                    </div>
                    <div className="ui-dialog">
                        <div className="ui-dialog-header">
                            {value.label}
                            <div className="ui-dlg-close" onClick={close}>
                                <i className="fa fa-times"></i>
                            </div>
                        </div>
                        <div className="ui-dialog-data">
                            <Crud disabled={disabled} view={view} value={model} user={user} onClick={onClick} />
                        </div>
                    </div>
                </>
            }
        </>
    )
};


const Table = ({ parentQuery, id, value, disabled, data, changed, onClick, crud, user }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const element = Utils.decode(value, crud, data);
    let [actions, setActions] = useState(value.actions ? value.actions : []);

    const showDialog = function () {
        setOpenDialog(true);
    };


    const handleCloseDialog = function () {
        if (openDialog) {
            value.refresh();
            setOpenDialog(false);
        }
    };

    value.closeDialog = handleCloseDialog;
    element.closeDialog = handleCloseDialog;

    const actDlgClick = function (act) {
        if (act.action === 'close') {
            handleCloseDialog();
        } else {
            act.complete = handleCloseDialog;

            value.execute(act);
        }
    }


    return (
        <>
            {!value.dialog &&
                <TableInner parentQuery={parentQuery} onClick={onClick} id={id} element={element} disabled={disabled} value={value} data={data} changed={changed} crud={crud} user={user}></TableInner>
            }
            {value.dialog &&
                <>
                    <Button variant="contained" color="primary" onClick={showDialog}>
                        {element.label}
                    </Button>

                    <Dialog maxWidth="lg" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                            {element.label}
                        </DialogTitle>
                        <DialogContent>
                            <TableInner onClick={onClick} id={id} element={element} disabled={disabled} value={value} data={data} changed={changed} crud={crud} user={user}></TableInner>
                        </DialogContent>
                        <DialogActions>
                            {actions.length <= 0 &&
                                <Button onClick={handleCloseDialog} color="primary"> {utils.lng('fechar', 'FECHAR')}</Button>
                            }
                            {actions.length > 0 &&
                                <>
                                    {actions.map((ac, i) => (
                                        <Button onClick={() => { actDlgClick(ac) }} color="primary">
                                            {ac.label}
                                        </Button>
                                    ))}
                                </>
                            }
                        </DialogActions>
                    </Dialog>
                </>
            }
        </>
    );
};

export default Table;