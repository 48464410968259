import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Element from './element';
import utils from './utils';
import CustomDialog from './custom_dialog';

function RecuperarSenha(props) {
  let page = utils.getPath(1);

  const [data, setData] = useState({ source: page });
  const [crud, setCrud] = useState({ changed: data, data: data });
  const [view, setView] = useState({});

  window.view = view;

  let token = new URLSearchParams(props.location.search).get("token");

  crud.changed.token = token;
  crud.data.token = token;
  let msg = utils.lng('msg_recupera_senha', 'ALTERAR SENHA');

  if (page === 'welcome') {
    msg = utils.lng('msg_primeiro_acesso', 'CADASTRE SUA SENHA PARA SEU PRIMEIRO ACESSO');
  }

  let login = {
    styleClass: 'ui-login-panel',
    elements: {
      left: {
        cols: 3
      },
      center: {
        cols: 6,
        elements: {
          logar: {
            type: "form",
            elements: {
              msg: { style: { color: '#fff', textAlign: 'center',paddingBottom: '15px' }, bold: true, value: msg },
              logo: { type: 'img', value: 'static/img/logo.svg', center: true },
              digiteSenha: { type: 'password', label: 'Senha', name: 'digiteSenha', title: 'Digite sua senha' },
              confirmeSenha: { type: 'password', label: 'Confirmar', name: 'confirmeSenha', title: 'Confirmar senha' },
              confirmar: {
                type: 'button', label: 'CONFIRMAR', icon: '', styleClass: 'ui-btn-login',
                click: {
                  url: 'alterarSenha',
                  redirect: '/login',
                  method: 'POST'
                }
              },
              voltar: { rendered: "${@this.source !== 'welcome'}", type: 'link', value: 'voltar', style: { color: '#fff', textAlign: 'center' }, href: 'esqueci_senha' }
            }
          }
        }
      }, right: {
        cols: 3
      }
    }
  };
  return (
    <>
      <CustomDialog />
      <Element crud={crud} value={login} data={data} user={data} />
    </>
  );
}

export default RecuperarSenha;