import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';
import MenuCarrinho from './menu_carrinho';

function Favoritos() {
  let [view, setView] = useState({});
  let [sku, setSku] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: { classificacao: 0 } });
  let [ready, setReady] = useState(false);
  let idSku = 0;
  let [index, setIndex] = useState(0);

  const path = window.location.pathname;
  const split = path.split('/');

  if (split.length >= 4) {
    idSku = parseInt(split[3]);
  }

  useEffect(() => {
    if (!ready) {
      setReady(true);

      const _ks_ = {};

      _ks_.complete = function (res) {
        setSku(res.data[0]);

        crud.data = res.data[0];
        crud.refresh();

      };
      _ks_.element = {};
      _ks_.cfg = {
        url: 'listarProdutosCatalogo?id_sku=' + idSku,
        method: 'GET'
      };

      _ks_.crud = crud;
      _ks_.view = view;
      _ks_.changed = {};

      utils.call(_ks_);
    }

  }, []);

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  crud.open = function (value, args) {
    const click = value.click;
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

 
  const produtos = {
    name: "produtos",
    type: "list",
    layout: 'list',
    rowCols: 4,
    itemVar: 'p',
    size: 30,
    rowStyleClass: 'ui-row-card ui-card-produto',
    entity: 'HubProduto',
    parent: 'catalogo',
    add: false,
    header: false,
    search: false,
    list: {
      url: 'listarProdutosCatalogo?favorito=true',
      method: 'GET'
    },
    elements: {
      corpo: {
        elements: {
          destaque: { type: 'span', field: 'destaque_descricao', rendered: '#{typeof @this.destaque_descricao !== "undefined"}', background: '#{@this.destaque_cor}' },
          avatar: { type: 'link', field: 'avatar', label: ' ', upload: false },
          descricao: { type: 'span', field: 'descricao' },
          valor_preco_venda: {
            styleClass: 'ui-valor-original',
            type: 'span', format: 'money', field: 'valor_preco_venda', value: '#{"de " + utils.money(@this.valor_preco_venda) + " por "}',
            rendered: '${@this.percentual_desconto > 0}',
            style: { color: '#555', fontSize: '20px', fontWeight: 'bold' }
          },
          valor: { type: 'span', format: 'money', field: 'valor', value: "${utils.money(@this.valor) + (@this.codigo_grade === 'PE' ? ' / metro' : '')}" }
        },
        click: {
          action: 'open',
          url: '/carrinho/produto/{id_sku}'
        }
      },
      comandos: {
        elements: {
          comprar: {
            click: {
              action: 'open',
              url: '/carrinho/produto/{id_sku}'
            },
            disabled: "${@this.saldo <= 0 && @this.codigo_grade !== 'PE'}",
            variant: 'text',
            type: 'button', label: 'COMPRAR', icon: 'fa fa-shopping-cart', theme: 'default', cols: 10
          },
          favorito: {
            click: {
              url: 'cart/changeFavorite/{id_sku}'
            },
            variant: 'text',
            styleClass: "${favorito ? 'ui-selected' : ''}",
            type: 'button', icon: "${favorito ? 'fas fa-heart' : 'far fa-heart'}", theme: 'default', cols: 2
          }
        }
      }
    }
  };

  return (
    <View name="carrinho">
      <div className="ui-catalogo ui-favoritos">
        <MenuCarrinho crud={crud} view={view} ></MenuCarrinho>

        <div className="ui-catalogo-content" >
          <div className="ui-catalogo-data" >
            <Element crud={crud} view={view} value={produtos} ></Element>
          </div>
        </div>
      </div>
    </View>
  );
}

export default Favoritos;