import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';

const TransmissaoAoVivo = () => {

  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ data: null, changed: null, requireds: {} });

  let transmissao = null;
  let [tituloTransmissao, setTituloTransmissao] = useState('');
  let [url, setUrl] = useState('');
  let parameters = "autoplay=1&modestbranding=1";

  const loadTransmissao = function () {
    utils.execute(crud, 'transmissao?transmitindo=true', null, function (res) {
      if (res.data.length > 0) {
        transmissao = res.data[0];

        if (transmissao && transmissao.id) {
          tituloTransmissao = transmissao.titulo;
          let completeUrl = transmissao.url;

          let splittedUrl = completeUrl.split('?');
          setUrl(splittedUrl[0]+'?'+parameters);
        }
      }
      crud.refresh();
      setCrud(crud);
      setIndex(index + 1);
    }, true);
  };

  useEffect(() => {
    if (!ready) {
      setReady(true);

      loadTransmissao();
    }
  });

  return (
    <View name="ui-transmissao">
      <div>
        <iframe
          width="80%" 
          height="800px" 
          src={url}
          title={tituloTransmissao}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onContextMenu={() => false} 
        ></iframe>
        <div
          style={{
            position: 'absolute', top: 0, left: 0, width: '100%', height: '800px', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: 999,
          }}
          onClick={ (event) => {
            event.stopPropagation();
          }}
        ></div>
        <div
          style={{
            position: 'absolute', top: 0, left: '20%', width: '100%', height: '1200px', backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: 999,
          }}
          onClick={ (event) => {
            event.stopPropagation();
          }}
        ></div>
      </div>
    </View>
  );
};

export default TransmissaoAoVivo;