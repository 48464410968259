import React, { useState, useEffect } from "react";
import Element from './element';
import Utils from './utils';
import utils from "./utils";

const Crud = ({ value, disabled, user, onClick, parent, view }) => {
    const [element, setElement] = useState({});

    let [ready, setReady] = useState(false);
    let [data, setData] = useState({});
    let [tabIndex, setTabIndex] = useState(0);
    let processo = useState(0);
    let label = useState('');
    let entity = useState();

    if (!view) {
        view = window.view;
    }

    if (!view) {
        view = {};
    }

    const t = {};

    for (const i in value) {
        t[i] = value[i];
    }

    t.edit = false;
    t.requireds = {};

    if (value.id >= 0) {
        t.edit = true;

        if (!t.selected) {
            t.selected = {};
            t.selected.id = value.id;
            t.new = false;
            t.data = t.selected;
        }
    }


    const [crud, setCrud] = useState(t);

    if (!crud.changed) {
        crud.changed = {};
    }

    const onLoadView = function (res) {
        let d = res.data;

        if (!d) {
            d = {};
        }

        if (value.showLabel == false) {
            crud.showLabel = false;
        }

        if (value.add == false) {
            d.add = false;
            crud.showAdd = false;
            d.showLabel = false;
        }

        if (d.processo) {
            processo = d.processo;
            label = d.title;
            entity = d.entity;
        }
        setElement(d);
    };

    const onLoadEntity = function (res) {
        let o = res.data ? res.data : {};
        let key = entity + '_' + (o.id > 0 ? o.id : 0);

        if (o.id > 0 && view.updateTab) {
            view.updateTab({ label: o.label });
        }

        if (!crud.selected) {
            crud.selected = {};
        }

        for (const i in o) {
            if (!crud.selected[i] || o.id > 0) {
                crud.selected[i] = o[i];
            }
        }

        data = crud.selected;

        crud.data = crud.selected;
        crud.edit = true;

        if (data.id <= 0) {
            crud.changed = {};

            for (const i in crud.selected) {
                crud.changed[i] = crud.selected[i];
            }
        }

        crud.id = data.id;

        setData(crud.data);
        setCrud(crud);
        setReady(true);
    };


    if (!crud.close) {
        crud.close = function (element) {
            view.close();
        }
    }

    const print = function (el, args, type) {
        const click = args.click ? args.click : el.click;
        const data = args.data;
        const down = {};

        down.method = 'GET';
        down.url = click.entity + '/print/' + data.id + '.' + type;
        down.download = true;
        down.fileName = 'file.' + type;


        const _call_ = {};

        _call_.global = true;
        _call_.element = element;
        _call_.cfg = down;
        _call_.complete = function (res) {
            console.log(res);
        };

        _call_.data = data;
        _call_.view = view;

        Utils.call(_call_);
    }

    crud.print = function (a, b) {
        print(a, b, 'pdf');
    };

    crud.preview = function (a, b) {
        print(a, b, 'html');
    };

    crud.refresh = function () {
        setCrud(crud);
    }

    crud.setTab = function (index) {
        tabIndex = index;

        crud.tabIndex = index;
        setTabIndex(index);
        setCrud(crud);
    }

    crud.add = function (element) {
        const args = {};

        args.new = true;
        args.data = { id: 0 };
        args.selected = args.data;
        args.entity = element.entity;

        if (element.list) {
            args.entity = element.list.entity;
        }

        view.open(element, args);
    }

    crud.canEdit = function(entity,data){
        console.log(data);
    }

    useEffect(() => {
        const tab = window.tab;
        const cfg = {};
        const name = value.name ? value.name : '';

        cfg.await = true;

        const _call_ = {};

        _call_.global = true;
        _call_.element = element;
        _call_.cfg = cfg;
        _call_.complete = onLoadView;
        _call_.data = {};
        _call_.view = view;
        _call_.changed = {};

        if (value.entity) {
            cfg.method = 'GET';

            let parent = crud.parent ? crud.parent : '';

            cfg.url = 'view/' + value.entity + '?name=' + name + '&parent=' + parent;

            Utils.call(_call_);
        }

        if (value.id >= 0 && crud.init !== false) {
            cfg.method = 'GET';
            cfg.url = value.entity + '/' + value.id;

            if(value.eager) {
                _call_.changed.eager = true;
            }

            if(value.name) {
                _call_.changed.crud = value.name;
            }

            _call_.complete = onLoadEntity;

            Utils.call(_call_);
        } else if (crud.edit) {
            crud.edit = true;
            crud.create = true;

            setData(crud.data);
            setCrud(crud);
            setReady(true);
        } else {
            setReady(true);
        }
    }, []);

    crud.elements = {};

    return (
        <>
            {ready &&
                <Element view={view}
                    value={element}
                    disabled={disabled}
                    parent={parent}
                    data={data}
                    user={user}
                    crud={crud}
                    onClick={onClick}
                />
            }
            {!ready &&
                <div className="ui-loading">
                    <i className="fas fas fa-circle-notch fa-spin"></i>
                </div>
            }
        </>
    )
};

export default Crud;