import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';
import DateFnsUtils from "@date-io/date-fns";

function RelatorioGraduacao() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let dataUtils = new DateFnsUtils();

  const reportCfg = {
    label: utils.lng("graduacao", "Graduação"),
    name: 'reportCfg',
    add: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_graduacao'
    },
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng("data_graduacao", "Data Graduação"),
        default: { 
          start: dataUtils.startOfMonth(new Date()),
          end: dataUtils.endOfMonth(new Date())
        }
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_cidade: {
        type: 'entity',
        entity: 'Cidade',
        label: utils.lng('cidade', "Cidade"),
        list:{url: 'Cidade'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_estado: {
        type: 'complete',
        label: utils.lng('estado', "Estado"),
        list:{url: 'Estado'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_pais: {
        type: 'complete',
        label: utils.lng('pais', "País"),
        list:{url: 'Pais'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_status_penn: {
        type: 'select',
        label: utils.lng('status_penn', "Status Penn"),
        list: { url: 'StatusPenn' },
        itemLabel: 'descricao',
        itemValue: 'id'
      }
    },
    columns: {
      dataGraduacao: { field: 'data_graduacao', type: 'date', label: utils.lng("data_graduacao", "Data Graduação"), filter: false, sort: false },
      codigo: { field: 'codigo', label: utils.lng("codigo", "Código"), filter: false, sort: false },
      nome: { field: 'nome', label: utils.lng("nome", "Nome"), filter: false, sort: false },
      graduacao: { field: 'descricao_graduacao', label: utils.lng("graduacao", "Graduação"), filter: false, sort: false },
      filial: { field: 'filial_referencia', label: utils.lng("filial", "Filial"), filter: false, sort: false },
      cidade: { field: 'cidade', label: utils.lng("cidade", "Cidade"), filter: false, sort: false },
      estado: { field: 'estado', label: utils.lng("estado", "Estado"), filter: false, sort: false },
      email: { field: 'email', label: utils.lng("email", "E-mail"), filter: false, sort: false },
      socio: { field: 'socio', label: utils.lng("socio", "Sócio"), filter: false, sort: false }
    }
  };
  
  return (
    <View className="ui-view-report" name="rel-graduacao">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioGraduacao;