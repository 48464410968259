import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import utils from "./utils";


const Evento = function () {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  let [index, setIndex] = useState(0);
  let idEvento = utils.getPathId(2);

  crud.changed.evento = idEvento;
  crud.data.evento = idEvento;

  let url = window.location.href;
  let label = utils.lng('label.social.share','Olha só o que gostaria de compartilhar');
  
  const social = [
    {
      title: 'Email',
      url: 'mailto:?subject=Interpenn&body=' + label + ' ' + url,
      icon: 'far fa-envelope'
    },
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=' + url,
      icon: 'fab fa-facebook'
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/sharing/share-offsite/?url=' + url,
      icon: 'fab fa-linkedin'
    }
  ];

  let gridEvento = {
    type: 'list',
    add: false, header: false, controls: false, search: false,
    layout: 'column',
    styleClass: 'ui-list-eventos',
    rowStyleClass: 'ui-item-eventos ui-eventos-main',
    rowCols: 3,
    label: 'Notícias',
    size: 10,
    actions: {
      back: {
        value: 'Voltar',
        type: 'link',
        click: {
          redirect: '/eventos'
        }
      }
    },
    list: {
      url: 'Evento?id=' + idEvento + '&lazy=false&resume=true'
    },
    elements: {
      left: {
        space: 6,
        elements: {
          anexos: {
            type: 'list',
            itemValue: 'linkAnexo', gallery: true,
            data: "${@this.anexos}"
          },
          social_midias: {
            type: 'list',
            header: false,
            search: false,
            data: social,
            columns: {
              icone: { type: 'link', icon: '${@this.icon}', href: "${@this.url}", title: '${@this.title}' }
            }
          }
        }
      },
      right: {
        space: 6,
        style: { paddingLeft: '15px', paddingRight: '15px' },
        elements: {
          dhRegistro: { format: 'date', readOnly: true, styleClass: 'ui-destaque' },
          titulo: { bold: true },
          texto: {}
        }
      }
    }
  };

  return (
    <View view={view} name="eventos">
      {idEvento > 0 &&
        <>
          <Element value={gridEvento} crud={crud} view={view} ></Element>
        </>
      }
    </View>
  );
}

export default Evento;