import React, { useState, useEffect } from "react";
import View from './view';
import Utils from './utils';
import Element from './element';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import utils from "./utils";

const CustomTreeItem = function (props) {
  let [tree, setTree] = useState([]);
  let [ready, setReady] = useState(false);

  let crud = {};
  let parent = props.crud;
  let view = props.view;
  let catalogo = props.catalogo;
  let superior = props.superior;

  if (!ready || parent.reload) {
    setReady(true);
    parent.reload = false;

    const _call_ = {};

    _call_.global = false;
    _call_.element = {};
    _call_.cfg = {
      url: 'listarClassificacoes',
      type: 'GET'
    };

    _call_.complete = function (res) {
      setTree(res.data);
    };

    if (!crud.changed) {
      crud.changed = { catalogo: 0 };
    }

    if (!crud.data) {
      crud.data = {};
    }

    _call_.data = { catalogo: catalogo, superior: superior ? superior : 0 };
    _call_.changed = { catalogo: catalogo, superior: superior ? superior : 0 };
    _call_.crud = crud;
    _call_.view = view;


    Utils.call(_call_);
  }

  const onItemClick = function (item) {
    parent.selecionarClassificacao(item);
  }

  return (
    <>
      {tree.map((item, ib) => (
        <TreeItem nodeId={item.id} superior={item.id} label={item.descricao} onClick={() => { onItemClick(item) }} >
          <CustomTreeItem crud={parent} view={view} catalogo={catalogo} superior={item.id} label={item.descricao} ></CustomTreeItem>
        </TreeItem>
      ))}
    </>
  );
}

function Classificacao() {
  let [classificacao, setClassificacao] = useState({});
  let [principal, setPrincipal] = useState(false);
  let [edit, setEdit] = useState(false);
  let [view, setView] = useState({});
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ classificacao: 0 });
  let [catalogo, setCatalogo] = useState(0);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [dialogOpenAdd, setDialogOpenAdd] = useState(false);
  let [descricao, setDescricao] = useState('');

  if (!crud.data) {
    crud.changed = { catalogo: 0, classificacao: { id: 0 } };
    crud.data = crud.changed;
    crud.selected = crud.changed;
  }

  const catalogos = {
    name: "catalogo",
    label: 'Selecione um catálogo',
    type: "select",
    list: {
      url: 'Catalogo',
      method: 'GET'
    },
    itemLabel: 'descricao',
    itemValue: 'id',
    change: {
      action: 'selecionarCatalogo'
    }
  };


  const produtos = {
    name: "produtos",
    widgetVar: "produtos",
    type: "list",
    label: 'Selecionar produto',
    entity: 'ClassificacaoProduto',
    target: 'produto',
    add: {
      action: 'addProduto'
    },
    parent: 'classificacao',
    search: {
      entity: 'HubProduto',
      target: 'produto'
    },
    list: {
      url: "${'ClassificacaoProduto?classificacao=' + crud.classificacao.id}",
      mappedBy: "classificacao",
      method: 'GET'
    },
    columns: {
      remover: { type: 'icon', icon: 'fa fa-trash', click: { action: 'remove', alert: false } },
      codigo: { type: 'text', field: 'produto.codigo' },
      descricao: { type: 'text', field: 'produto.descricao' }
    }
  };

  const produtosAdd = {
    name: "produtos_add",
    type: "list",
    dialog: true,
    onCloseDialog: function () {
      setDialogOpenAdd(false);
      setIndex(index++);
      produtos.refresh();
    },
    autoOpen: true,
    label: 'Selecionar produto',
    list: {
      url: "listarProdutosNaoClassificados",
      method: 'GET',
      send:{
         query: '${@this.query}',
         id_catalogo: '${@this.catalogo}'
      }
    },
    click: {
      url: "ClassificacaoProduto",
      method: 'POST',
      alert: false,
      send: {
        produto: '${@this.id}',
        classificacao: '${crud.classificacao.id}'
      }
    },
    columns: {
      codigo: { type: 'text', field: 'codigo', label: 'Código' },
      descricao: { type: 'text', field: 'produto', label: 'Descrição' }
    }
  };

  crud.addProduto = function (args) {
    setDialogOpenAdd(true);
    setIndex(index++);
  }

  crud.selecionarClassificacao = function (args) {
    setClassificacao(args);
    crud.classificacao = args;
    crud.changed.classificacao = args;
    crud.data.classificacao = args;

    setCrud(crud);
    setIndex(index++);

    if (produtos && produtos.refresh) {
      produtos.refresh();
    }
  }

  crud.selecionarCatalogo = function (cfg, args) {
    let v = args.catalogo.constante;

    if (v > 0) {
      //deixa rolar
    } else {
      v = 0;
    }
    setCatalogo(v);

    crud.catalogo = v;
    crud.changed.catalogo = v;
    crud.data.catalogo = v;
    crud.reload = true;

    setCrud(crud);
  }

  crud.refresh = function () {
    setCrud(crud);
  }


  const addItem = function () {
    setDescricao('');
    setEdit(false);
    setDialogOpen(true);
  }

  const delItem = function () {
    const _call_ = {};

    _call_.complete = function (res) {
      crud.reload = true;
      setDialogOpen(false);
      setCrud(crud);
    };
    _call_.element = {};
    _call_.data = { id: classificacao.id };
    _call_.cfg = {
      url: 'Classificacao/{id}',
      method: 'DELETE'
    };

    _call_.crud = crud;
    _call_.view = view;

    Utils.call(_call_);
  }

  const editItem = function () {
    setDescricao(classificacao.descricao);
    setEdit(true);
    setDialogOpen(true);
  }

  const closeDialog = function () {
    setDialogOpen(false);
  }


  const salvarClassificacao = function () {
    if (descricao) {
      const _call_ = {};

      _call_.element = {};
      _call_.complete = function (res) {
        crud.reload = true;
        setDialogOpen(false);
        setCrud(crud);
      };

      if (edit) {
        _call_.data = { id: classificacao.id };
        _call_.changed = { descricao: descricao, id: classificacao.id };
        _call_.cfg = {
          url: 'Classificacao/{id}',
          method: 'PUT'
        };
      } else {
        _call_.cfg = {
          url: 'Classificacao',
          method: 'POST'
        };

        _call_.changed = { catalogo: catalogo, descricao: descricao };

        if (!principal) {
          _call_.changed.superior = classificacao.id;
        }
      }
      _call_.crud = crud;
      _call_.view = view;

      Utils.call(_call_);
    }
  }

  const onChange = function (event) {
    setDescricao(event.target.value);
  }

  const onCheck = function (event) {
    setPrincipal(event.target.checked);
  }

  return (
    <View>
      <div>
        <h2>Menu catálogo</h2><br />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Classificação</DialogTitle>
          <DialogContent>
            {classificacao.id && !edit > 0 &&
              <>
                <div className="padding-bottom">
                  <TextField variant="outlined" required={true} label="Adicionar abaixo de: "
                    value={classificacao.descricao} disabled={true} type="text" ></TextField>
                </div>
                <div className="padding-bottom">
                  <b>&nbsp;&nbsp;&nbsp;OU</b>
                </div>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox label="Adicionar como principal" checked={principal} onChange={onCheck} > </Checkbox>
                  }
                  label="Adicionar como principal" />
              </>
            }
            <DialogContentText id="alert-dialog-description">
              <TextField variant="outlined" required={true} label="Descrição"
                value={descricao} onChange={onChange} type="text" ></TextField>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="secondary">{utils.lng('fechar','Fechar')}</Button>
            <Button onClick={salvarClassificacao} color="primary">SALVAR</Button>
          </DialogActions>
        </Dialog>

        <Element crud={crud} view={view} value={catalogos} ></Element>
        <div className="ui-layout-group">
          <div className="ui-tree-left">
            <div className="ui-tree-commands">
              <Button variant="contained" disabled={!crud.catalogo} color="primary" onClick={addItem}>
                <i className="fa fa-plus"></i>
              </Button>
              <Button variant="contained" disabled={!crud.classificacao} color="secondary" onClick={delItem}>
                <i className="fa fa-trash"></i>
              </Button>
              <Button variant="contained" disabled={!crud.classificacao} color="primary" onClick={editItem}>
                <i className="fa fa-edit"></i>
              </Button>
            </div>
            <div className="ui-tree-data">
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <CustomTreeItem crud={crud} view={view} catalogo={catalogo}></CustomTreeItem>
              </TreeView>
            </div>
          </div>
          <div className="ui-tree-right">
            <div className="ui-tree-data">
              {classificacao.id > 0 &&
                <Element id={'prds-' + index} crud={crud} view={view} value={produtos} data={crud.data} ></Element>
              }
              {dialogOpenAdd &&
                <Element id={'prdss-' + index} crud={crud} view={view} value={produtosAdd} data={crud.data} ></Element>
              }
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}

export default Classificacao;