import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioExtratoPontuacaoIndividualAnalitico() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('extrato_de_pontuacao_individual', 'Extrato de Pontuação Individual')+" ("+utils.lng('analitico', 'Analítico')+")",
    name: 'reportCfg',
    add: false,
    print:true,
    auto: false,
    type: 'list',
    list: {
      url: 'report_analitico_extratoPontuacaoIndividual'
    },
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng("data", "Data")
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_filial: {
        type: 'complete',
        label: utils.lng('filial', "Filial"),
        list: { url: 'endpoint.listar.filial' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_tipo_movimento: {
        type: 'select',
        label: utils.lng('tipo_movimento', "Tipo Movimento"),
        list:{url: 'TipoMovimento'},
        itemLabel: 'descricao',
        itemValue: 'id'
      },
      id_condicao_pagto: {
        type: 'select',
        label: utils.lng('forma_pagamento', "Forma Pagamento"),
        list:{url: 'HubCondicaoPagamento'},
        itemLabel: 'nome',
        itemValue: 'id'
      }
    },
    columns: {
      dataPedido: { field: 'data_pagamento', type: 'date', label: utils.lng('data', 'Data'), filter: false, sort: false },
      codigo: { field: 'codigo_mundial', label: utils.lng('codigo_cliente', 'Código Cliente'), filter: false, sort: false },
      codFilial: { field: 'codigo_filial', label: utils.lng('codigo_filial', 'Código Filial'), filter: false, sort: false },
      chavePagamento: { field: 'chave_pagamento', label: utils.lng('chave_pagamento', 'Chave Pagamento'), filter: false, sort: false },
      dataPagamento: { field: 'data_pagamento', type: 'date', label: utils.lng('data_pagamento', 'Data Pagamento'), filter: false, sort: false },
      vlrTotalPedido: {aggregate: 'sum',  field: 'valor_total_pedido', format: 'money', label: utils.lng('valor_total_pedido', 'Valor Total do Pedido'), filter: false, sort: false },
      vlrDescontos: { field: 'valor_desconto_pedido', format: 'money', label: utils.lng('valor_descontos', 'Valor Descontos'), filter: false, sort: false },
      vlrLiquido: {aggregate: 'sum', field: 'valor_liquido', format: 'money', label: utils.lng('valor_liquido', 'Valor Líquido'), filter: false, sort: false },
      formasPagto: { field: 'formas_pagamento', label: utils.lng('formas_de_pagamento', 'Formas de Pagamento'), filter: false, sort: false },
      totalPontos: {aggregate: 'sum', field: 'total_pontos', format: 'number', decimalCount: 4, label: utils.lng('pontos', 'Pontos'), filter: false, sort: false },
      estorno: { field: 'is_estorno', label: utils.lng('estorno', 'Estorno'), filter: false, sort: false }
    }
  };

  return (
    <View name="rel-extrato-pontuacao-individual-analitico" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioExtratoPontuacaoIndividualAnalitico;