import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';
import utils from "./utils";


const SolicitarLoja = function () {
  let [view, setView] = useState({});

  useEffect(() => {

  });


  return (
    <View view={view} name="solicitar-loja">
      <div align="center" style={{background: '#fff',fontSize: '30px',padding:'40px',border:'1px solid #dedede'}}> 
         Quero minha Loja D.I Store!
      </div>
    </View>
  );
}

export default SolicitarLoja;