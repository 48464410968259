import React, { useState } from 'react';
import { useParams } from 'react-router';
import Element from '../element';
import View from '../view';
import utils from '../utils';

export default function BuscaRma() {
    let [view, setView] = useState({});
    let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

    crud.printRma = function (el, args) {

        let _ks_ = {};

        _ks_.global = true;
        _ks_.crud = crud;
        _ks_.changed = args.data;
        _ks_.data = args.data;
        _ks_.element = {};

        _ks_.cfg = {
            url: 'print/print_rma.pdf',
            fileName: 'rma.pdf',
            download: true,
            method: 'POST'
        };
        utils.call(_ks_);
    }

    const el = {
        label: utils.lng('busca_rma', "Relatório de RMA's"),
        name: 'el',
        print:true,
        add: false,
        type: 'list',
        list: {
            url: 'sapiens_BuscaRma?target=rma&codMun=:codMun'
        },
        columns: {
            nomFil: { label: 'Filial' },
            codRma: { label: 'RMA', filter: true },
            datGer: { label: 'Data Entrada', filter: false, unmask: false, mask: '99/99/9999' },
            datIni: { label: 'Data Ini.', filter: true, unmask: false, mask: '99/99/9999' },
            datFim: { label: 'Data Fim.', filter: true, unmask: false, mask: '99/99/9999' },
            codPro: { label: 'Cód. Prod.', filter: false },
            codMun: { label: 'Cód. Mun.', filter: false },
            desPro: { label: 'Produto', filter: false },
            desDer: { label: 'Derivação' },
            status: { label: 'Status', value: "${last(@this.status).desSta}", expression: "#{last(@this.status).desSta}" },
            dt_status: { label: 'Dt. Status', value: "${last(@this.status).datSta}", expression: "#{last(@this.status).datSta}" },
            print: {
                icon: 'fa fa-print', click: {
                    action: 'printRma'
                }
            }
        }
    };

    return (
        <View className="ui-view-report" name="rel-qualificacao">
            <Element value={el} crud={crud} data={crud.data} view={view} ></Element>
        </View>
    );

}