import React, { useEffect, useState } from "react";
import Element from './element';
import View from './view';
import utils from './utils';

function Contratos() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let idCliente = utils.getPath(2);
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  const carregarCliente = function () {
    if (!idCliente) {
      idCliente = 0;
    }
    utils.execute(crud, 'Cliente?single=true&id=' + idCliente, null, function (res) {
      crud.data = res.data;
      crud.changed = res.data;

      setCrud(crud);
      setIndex(index + 1);
    }, true);
  }

  useEffect(() => {
    if (!ready) {
      ready = true;
      setReady(true);
      carregarCliente();
    }
  });

  crud.downloadContrato = function(el, args) {
    if (el.click && args && args.data && args.data.url) {
      window.open(args.data.url);
    }
  }

  let ctrs = {
    elements: {
      detail: { label: 'Meus contratos' },
      dados: {
        type: 'card',
        elements: {
          nome: { type: 'span', field: 'nome', value: '${@this.codigo + " - " + @this.nome}', space: 8 }
        }
      },
      itens: {
        type: 'list',
        header: false,
        name: utils.lng("contratos","contratos"),
        list: {
          url: 'listarContratosCliente?id_cliente='+idCliente,
          send: false
        },
        columns: {
          id: {},
          nome: {},
          download: {
            type: 'button',
            columnStyle: { width: '40px' },
            icon: 'fa fa-download',
            click: {
              action: 'downloadContrato'
            }
          }
        }
      }
    }
  };

  return (
    <View name="ui-contratos" view={view}>
      <Element value={ctrs} crud={crud} data={crud.data} view={view} ></Element>
    </View >
  );
}

export default Contratos;