import React, { useState, useEffect } from "react";
import View from './view';


const BlogCampanhas = function () {

  return (
    <View styleClass="ui-full-page" name="blog">
       <iframe src="https://blog.nipponflex.com/campanhasativas/"  style={{border: 'none',width: '100%', height: '100%'}}></iframe>
    </View>
  );
}

export default BlogCampanhas;