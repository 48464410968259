import React, { useState, useEffect } from "react";
import utils from './utils';
import Button from '@material-ui/core/Button';
import Toogle from './toggle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Element from './element';
import { FormControl, InputLabel, NativeSelect, Select } from '@material-ui/core';

const Promocao = function (props) {
  let crud = props.crud;
  let view = props.view;
  let data = props.data;
  let [ready, setReady] = useState(false);
  let [valorTotal, setValorTotal] = useState(0);
  let [valorComDesconto, setValorComDesconto] = useState(0);
  let [todos, setTodos] = useState([]);
  let [index, setIndex] = useState(0);
  let [quantidadePromocoes, setQuantidadePromocoes] = useState(1);
  let [produtoEscolha, setProdutoEscolha] = useState({});
  let [listaOpcoesSku, setListaOpcoesSku] = useState([]);
  let [skusEscolhidos, setSkusEscolhidos] = useState([]);
  let [mapProdutosPromocao, setMapProdutosPromocao] = useState([]);
  let [showDialog, setShowDialog] = useState(false);
  let [isPedidoPronto, setIsPedidoPronto] = useState(false);
  
  useEffect(() => {
    if (!ready) {
      setReady(true);

      utils.execute(crud, 'listarProdutosEmPromocao?id_promocao=' + data.id, null, function (res) {
        for (const i in res.data) {
          const itemPromocao = res.data[i];
          itemPromocao.selected = false;
          const filterList = mapProdutosPromocao.filter((itemLista) => {
            return itemLista.id_produto === itemPromocao.id_produto;
          });
          let produtoPromocao = null;
          if (filterList && filterList.length > 0) {
            produtoPromocao = filterList[0];
          } else {
            produtoPromocao = {
              id_produto: itemPromocao.id_produto, 
              codigo_produto: itemPromocao.codigo_produto,
              descricao: itemPromocao.descricao_produto,
              descricao_produto: itemPromocao.descricao_produto,
              avatar: itemPromocao.avatar,
              selected: false, 
              obrigatorio: itemPromocao.obrigatorio, 
              listaSku: []
            };
            mapProdutosPromocao.push(produtoPromocao);
          }
          produtoPromocao.listaSku.push(itemPromocao);
          todos.push(itemPromocao);
        }

        setTodos(todos);
        calcularDescontos();
      });
    }
  });

  const calcularDescontos = function () {
    valorComDesconto = 0;
    valorTotal = 0;
    for (const i in skusEscolhidos) {
      const c = skusEscolhidos[i];

      if (c.selected) {
        valorTotal += c.valor;
        valorComDesconto += c.valor - (c.valor * c.percentual_desconto / 100);
      }
    }
    let qtdePromo = quantidadePromocoes ? quantidadePromocoes : 1;
    valorComDesconto = valorComDesconto * qtdePromo;
    valorTotal = valorTotal * qtdePromo;
    setIndex(index + 1);
    setValorComDesconto(valorComDesconto);
    setValorTotal(valorTotal);
  }

  const selectPromoItem = function (idSku, args) {
    listaOpcoesSku.forEach((skuLista) => {
      skuLista.selected = skuLista.id_sku == idSku;
    });
    valorTotal = 0;
    valorComDesconto = 0;
    refreshProdutosEscolhidos();
    refreshEscolhidos();
    calcularDescontos();
  }

  const updateQuantidadePromocoes = function(newQtde) {
    setQuantidadePromocoes(parseInt(newQtde));
    quantidadePromocoes = parseInt(newQtde);
    calcularDescontos(); 
  }

  const refreshProdutosEscolhidos = function() {
    mapProdutosPromocao.forEach((produto) => {
      produto.sku_escolhido = null;
      produto.legenda = "";
      produto.valor = 0.0;
      let listaSkuSelecionado = produto.listaSku.filter((sku) => {
        return sku.selected;
      });
      if (listaSkuSelecionado && listaSkuSelecionado.length > 0) {
        produto.percentual_desconto = listaSkuSelecionado[0].percentual_desconto;
        listaSkuSelecionado.forEach((sku) => {
          produto.sku_escolhido = sku.id_sku;
          produto.legenda += produto.legenda === "" ? " ("+sku.legenda+")" : " / ("+sku.legenda+")";
          produto.valor += parseFloat(sku.valor);
        });
      }
    })
  }

  const refreshEscolhidos = function() {
    let escolhidos = todos.filter((item) => {
      return item.selected;
    });
    skusEscolhidos = escolhidos;
    setSkusEscolhidos(escolhidos);
  }

  const openDialogEscolherSKU = function (prod) {
    setProdutoEscolha(prod);
    setListaOpcoesSku(prod.listaSku);
    produtoEscolha = prod;
    listaOpcoesSku = prod.listaSku;
    if (!hasSelectedItem(listaOpcoesSku)) {
      listaOpcoesSku[0].selected = true;
    }
    setShowDialog(true);
  }

  const hasSelectedItem = function(listaSkus) {
    return (listaSkus && listaSkus.length > 0) && listaSkus.some((sku) => {
      return sku.selected;
    });
  }

  const closeDialog = function () {
    setShowDialog(false);
  }

  const confirmDialog = function () {
    validaCompra();
    closeDialog();
  }

  const validaCompra = function () {
    let isPedidoOk = skusEscolhidos && skusEscolhidos.length > 0;
    if (isPedidoOk) {
      for (const i in mapProdutosPromocao) {
        let produtoForEach = mapProdutosPromocao[i];
        let selectedList = [];
        if (produtoForEach.obrigatorio && produtoForEach.listaSku && produtoForEach.listaSku.length > 0) {
          selectedList = produtoForEach.listaSku.filter((item) => {
            return item.selected;
          })
          isPedidoOk = selectedList && selectedList.length > 0;
          if (!isPedidoOk) {
            break;
          }
        }
      }
    }
    setIsPedidoPronto(isPedidoOk);
  }

  const addPromocao = function () {
    const _ks_ = {};
    const _pr_ = {};


    _pr_.produtos = [];

    _ks_.global = true;
    _ks_.crud = crud;
    _ks_.changed = _pr_;
    _ks_.element = {};

    for (const i in skusEscolhidos) {
      const v = skusEscolhidos[i];

      if (v.selected) {
        _pr_.produtos.push(v.id_sku);
      }
    }
    _pr_.quantidadePromocoes = quantidadePromocoes;

    _ks_.complete = function (res) {
      setIndex(index + 1);
    };

    _ks_.cfg = {
      url: 'cart/savePromotion/' + data.id,
      redirect: '/carrinho/pedido',
      method: 'POST'
    };
    utils.call(_ks_);
  };

  console.log(utils.money);
  return (
    <div className="ui-promocao">
      {data.observacao &&
        <div className="ui-promocao-titulo">
          {data.observacao}
        </div>
      }
      <div className="ui-promocao-data ui-grid ui-box-dark" >
        {mapProdutosPromocao.length == 3 &&
          <>
            <div className="ui-promo-bloco ui-promo-centro ui-promo-norequired" >
              {mapProdutosPromocao.map((p, index) => (
                <div 
                  className={'ui-card ui-promo-item ui-promo-' + index + (p.obrigatorio ? ' ui-promo-required' : '')}
                  onClick={(e) => { openDialogEscolherSKU(p) }}
                >
                  <div className="ui-promo-avatar">
                    <img src={p.avatar}></img>
                  </div>
                  <div className="ui-promo-detail">
                    <div className="ui-promo-description">
                      {p.descricao}
                      {p.legenda && p.legenda}
                    </div>
                    {!p.sku_escolhido && 
                      <div className="ui-padding-top ui-medium-font ui-bold ajust-descricao" >
                        {utils.lng('selecione_tamanho', 'Selecione o Tamanho')}
                        <i className="fa fa-edit ui-padding-left"></i>
                      </div>
                    }
                    <div className="ui-promo-price ui-price ui-fix-bottom">
                      {p.percentual_desconto > 0 &&
                        <div className="ui-bold ui-discount"><strike>de {utils.money(p.valor)} por</strike></div>
                      }
                      {utils.money(p.valor - (p.valor * p.percentual_desconto / 100))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        }
        {mapProdutosPromocao.length < 3 &&
          <>
            <div className={'ui-promo-bloco ui-promo-centro ui-promo-norequired ' + ('ui-promo-' + todos.length)} >
              {mapProdutosPromocao.map((p, index) => (
                <div className={'ui-card ui-promo-item ui-promo-' + index} onClick={(e) => { openDialogEscolherSKU(p) }}>
                  <div className="ui-promo-avatar">
                    <img src={p.avatar}></img>
                  </div>
                  <div className="ui-promo-detail">
                    <div className="ui-promo-description">
                      {p.descricao}
                      {p.legenda && p.legenda}
                    </div>
                    {!p.sku_escolhido && 
                      <div className="ui-padding-top ui-medium-font ui-bold ajust-descricao" >
                        {utils.lng('selecione_tamanho', 'Selecione o Tamanho')}
                        <i className="fa fa-edit ui-padding-left"></i>
                      </div>
                    }
                    <div className="ui-promo-price ui-price ui-fix-bottom">
                      {p.percentual_desconto > 0 &&
                        <div className="ui-bold ui-discount"><strike>de {utils.money(p.valor)} por</strike></div>
                      }
                      {utils.money(p.valor - (p.valor * p.percentual_desconto / 100))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        }
        <div className="ui-promo-bloco ui-promo-resume" >
          <div className="ui-card ui-padding ui-promo-item">
            <div className="ui-promo-tag" >Promoção</div>
            <div className="ui-padding-top ui-medium-font ui-bold ajust-descricao" >{data.descricao}</div>
            <div className="ui-promo-data" >
              <div className="ui-grid ui-promo-resume-itens" >
                {mapProdutosPromocao.map((p, index) => (
                  <>
                    {p.selected &&
                      <div className="ui-row ui-promo-resume-item">
                        <div className="ui-col ui-promo-resume-item-description">
                          {p.descricao}
                        </div>
                        <div className="ui-col ui-promo-resume-item-price ui-price">
                          {utils.money(p.valor - (p.valor * p.percentual_desconto / 100))}
                        </div>
                      </div>
                    }
                  </>
                ))}
              </div>
              <div className="ui-bold">{utils.lng('quantidade', 'Quantidade')}: </div>
              <div className="ui-spinner qtd-classe">
                  <Button className="add-spinn-classe-e" onClick={() => { updateQuantidadePromocoes(parseInt(quantidadePromocoes) - 1) }}>
                    <i className="fas fa-angle-left"></i>
                  </Button>
                  <label autoComplete="new-password" disabled={true} className="ui-spinner-value label-qtd" value={quantidadePromocoes} type="text">{quantidadePromocoes}</label>
                  <Button className="add-spinn-classe-d" onClick={() => { updateQuantidadePromocoes(parseInt(quantidadePromocoes) + 1) }}>
                    <i className="fas fa-angle-right"></i>
                  </Button>
              </div>
            </div>
            <div className="ui-promo-resume-total ui-fix-bottom" >
              <div className="ui-padding">
                <div className="ui-bold">Valor total:<br /></div>
                {valorTotal > valorComDesconto &&
                  <div className="ui-bold"><strike>de {utils.money(valorTotal)} por</strike></div>
                }
                <div className="ui-price">{utils.money(valorComDesconto)}</div>
              </div>
              <Button id="btnComprar" className="ui-w100" color="primary" variant="contained" onClick={addPromocao} disabled={!isPedidoPronto}>
                <i className="fa fa-shopping-cart"></i>
                <span className="ui-button-label ui-label-icon">Comprar</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="1000px">
        <DialogTitle id="alert-dialog-title">{utils.lng('produto','Produto')}</DialogTitle>
        <DialogContent>
          <div className={'ui-promo-bloco ui-promo-centro ui-promo-norequired'} style={{width: '1000px'}}>
            <img className="ui-col ui-col-7" src={produtoEscolha.avatar}></img>
            <div className="ui-col ui-col-5" style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '30px'}}>
              <div className="ui-el-type-span" style={{ fontWeight: 'bold', fontSize: '25px' }}>
                {produtoEscolha.descricao}
              </div>
              <div className="ui-promo-price ui-price" style={{ width: "300px", paddingTop: '30px', paddingBottom: '30px', paddingLeft: '30px', fontWeight: 'bold', fontSize: '25px'}}>
                <div className="ui-bold ui-discount" style={{ display: 'inline', fontSize: '20px'}}>
                  <s>{utils.lng('de','de')} {utils.money(produtoEscolha.valor)}</s>
                </div>
                <div>
                  {utils.lng('por','por')} {utils.money(produtoEscolha.valor - (produtoEscolha.valor * produtoEscolha.percentual_desconto / 100))}
                </div>
              </div>
              <div className={'ui-card ui-promo-item ui-el-type-select'} style={{display: 'inline', ddingLeft: '30px' }}>
                <FormControl variant='outlined' label={utils.lng('selecione_tamanho', 'Selecione o Tamanho')}>
                  <InputLabel>{utils.lng('tamanho', 'Tamanho')}</InputLabel>
                  <Select 
                    className="ui-el-type-select"
                    native 
                    defaultValue='' 
                    required={true} 
                    select 
                    label={utils.lng('tamanho', 'Tamanho')} 
                    value={produtoEscolha.sku_escolhido}
                    onChange={(e) => { selectPromoItem(e.target.value, e) }}
                  >
                    <option key={null} value={null} index={-1} selected={false}>
                      {utils.lng('selecione_tamanho', 'Selecione o Tamanho')}
                    </option>
                    {listaOpcoesSku.map((sku, io) => (
                        <option key={sku.id_sku} value={sku.id_sku} index={io} selected={sku.selected}>
                          {sku.legenda}
                          {/* {sku.legenda+' ('+utils.money(sku.valor - (sku.valor * sku.percentual_desconto / 100))+')'} */}
                        </option>
                    ))}
                  </Select>
                  </FormControl>
                {/* <div className="ui-promo-select">
                  <Toogle value={sku.selected} onChange={(e) => { selectPromoItem(sku, e) }} label={false}></Toogle>
                </div> */}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="btnConfirmarAnotacao" onClick={confirmDialog} variant="outlined" color="primary">{utils.lng('confirmar','CONFIRMAR')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Promocao;