import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from './utils';


function Home(props) {
  const [crud, setCrud] = useState({showAdd: false, changed: { size: 100 }, data: { size: 100 } });
  const [app, setApp] = useState({});
  const [view, setView] = useState({});
  const [index, setIndex] = useState(0);

  view.refresh = function () {
    setIndex(index + 1);
  }

  const menu = {
    type: 'list',
    name: 'menu',
    field: 'menu',
    rowCols: 3,
    size: 100,
    layout: 'card',
    styleClass: 'ui-main-menu',
    header: false,
    controls: false,
    add: false,
    feedback: false,
    columns: {
      label: {}
    },
    list: {
      url: 'listarSubMenus',
      send: {
        id_superior: "${crud.id_processo}",
        size: "size",
        query: "${@this.query}"
      }
    }
  };

  crud.id_processo = utils.getParam('superior');

  const rowClick = function (cfg, args) {
    const b = args.data;
    const id = b.id;

    delete b.id;

    b.id_processo = id;
    
    utils.open(b);
  }


  return (
    <View view={view}>
      <Element crud={crud} view={view} value={menu} onClick={rowClick} data={crud.data}></Element>
    </View>
  );
}

export default Home;