import React, { useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import View from './view';
import utils from './utils';
import Chart from './chart';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Element from './element';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


function MapaPontos() {
  const [view, setView] = useState({});
  const [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  const [index, setIndex] = useState(0);

  view.refresh = function () {
    setIndex(index + 1);
  }


  return (
    <View view={view}>
      <Chart randomColors={true} crud={crud} id="ch10" space="12" type="pie" legend={false} recursive={false}
       title={utils.lng('mapa_pontos', "Mapa de pontos")} action="listarMapaPontos" fieldKey="id"  parentFieldKey="id_superior" parent={{ id: 'superior' }} 
       itemKey="nome" itemValue="pontos" tooltip="{nome}, Pontos: {pontos}" ></Chart>
    </View>
  );
}

export default MapaPontos;