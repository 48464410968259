import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';

export default function External() {
  let [state, setState] = useState({});

  const onReady = (d) => {
    let processo = d?.id_processo;

    utils.execute({ data: {}, changed: {} }, 'ProcessoParametro?processo=' + processo, null, function (res) {
      for (const i in res.data) {
        let o = res.data[i];


        state[o.nome] = o.valor;
      }
      setState({ ...state });
    });
  }

  return (
    <View onReady={onReady} styleClass="ui-full-page" name="external">
      {state.link_externo &&
        <iframe src={state.link_externo} style={{ border: 'none', width: '100%', height: '100%' }}></iframe>
      }
      {state.html_externo &&
        <span dangerouslySetInnerHTML={{ __html: state.html_externo }}></span>
      }
    </View>
  );
}

