import React, { useState, useEffect } from "react";
import DateFnsUtils from '@date-io/date-fns';
import View from './view';
import utils from './utils';
import Chart from './chart';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Element from './element';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


function DashboardAdmCadastro() {
  const [view, setView] = useState({});
  const [data, setData] = useState({});
  const [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  const [ready, setReady] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDetalheClientes, setOpenDialogDetalheClientes] = useState(false);
  const [index, setIndex] = useState(0);
  const [urlDetalheClientes, setUrlDetalheClientes] = useState('');

  view.refresh = function () {
    setIndex(index + 1);
  }

  if (!ready) {
    setReady(true);

    let agora = new Date();
    let dataUtils = new DateFnsUtils();
    let dataInicial = dataUtils.startOfMonth(agora);
    let dataFinal = dataUtils.endOfMonth(agora);

    crud.changed.date_start = dataInicial.getTime();
    crud.changed.date_end = dataFinal.getTime();

    crud.selected.date_start = dataInicial.getTime();
    crud.selected.date_end = dataFinal.getTime();

    crud.data.date_start = dataInicial.getTime();
    crud.data.date_end = dataFinal.getTime();
    crud.sendChanged = true;

  }

  let series = {
    key: 'key',
    value: 'quantidade',
    group: 'grupo'
  };

  let periodos = {
    mes_atual: "mês atual",
    mes_anterior: "mês anterior",
    trimestre: "trimestre",
    outros: "outros"
  };

  //DIALOG
  let gridDialogDetalheClientes = {
  // setGridDialogDetalheClientes({
    type: 'list',
    add: false,
    rows: 10,
    list: {
       url: urlDetalheClientes
    },
    columns: {
      codigo: { label: utils.lng('codigo', 'Código') },
      codigo_referencia: { label: utils.lng('codigo_sapiens', 'Código Sapiens') },
      nome: { label: utils.lng('nome', 'Nome') },
      tipo_cliente: { label: utils.lng('tipo', 'Tipo') },
      status_cliente: { label: utils.lng('status', 'Status') },
      status_penn: { label: utils.lng('status_penn', 'Status Penn') },
      cidade: { label: utils.lng('cidade', 'Cidade') },
      sigla_uf: { label: utils.lng('estado', 'Estado') },
      pais: { label: utils.lng('pais', 'Pais') }
    }
  };

  let openListagemCadastrosPorStatus = function (evt, args) {
    setUrlDetalheClientes('dashboard_adm_cad_listagemCadastrosPorStatus?filter_key='+evt);
    // crud.changed.gridDialogDetalheClientes = gridDialogDetalheClientes;
    setOpenDialogDetalheClientes(true);
  };

  let openListagemNovosXAtivacao = function (evt, args) {
    setUrlDetalheClientes('dashboard_adm_cad_listagemNovosXAtivacao?filter_key='+evt);
    setOpenDialogDetalheClientes(true);
  };

  let openListagemClientesTempoEspera = function (evt, args) {
    setUrlDetalheClientes('dashboard_adm_cad_listagemClientesTempoEspera?filter_key='+evt);
    setOpenDialogDetalheClientes(true);
  };

  crud.showCalendarDialog = function (onChangeDate) {
    crud.onChangeDate = onChangeDate;
    setOpenDialog(true);
  };

  crud.openListagemClientesTempoEspera = function (el, args) {
    crud.changed.id_categoria_bonus = args.data.id;
    setOpenDialogDetalheClientes(true);
  };

  const handleCloseDialog = function () {
    if (crud.changed.date_start && crud.changed.date_end) {
      if (crud.onChangeDate) {
        crud.onChangeDate.call(this);
      }
      setOpenDialog(false);
    }
  };

  const cancelPeriodDialog = function () {
    setOpenDialog(false);
  };

  const handleCloseDialogDetalheClientes = function () {
    setOpenDialogDetalheClientes(false);
  };

  const dates = {
    name: 'dates',
    elements: {
      date_start: {
        type: 'date',
        dateFormat: 'long',
        label: 'Data inicial'
      }, 
      date_end: {
        type: 'date',
        dateFormat: 'long',
        label: "Data final"
      }
    }
  };


  const showItem = function (o) {
    return o.pontos > 0;
  }

  return (
    <View view={view}>
      <>
        <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {utils.lng('select_period', 'Selecione um periodo')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={dates} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={cancelPeriodDialog} color="primary">
              {utils.lng('cancelar', 'Cancelar')}
            </Button>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="lg" onClose={handleCloseDialogDetalheClientes} aria-labelledby="customized-dialog-title" open={openDialogDetalheClientes}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialogDetalheClientes}>
            {utils.lng('dashboard_detail_clientes', 'Detalhamento de Clientes')}
          </DialogTitle>
          <DialogContent>
            <Element crud={crud} value={gridDialogDetalheClientes} data={crud.data} />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialogDetalheClientes} color="primary">
              {utils.lng('confirmar', 'Confirmar')}
            </Button>
          </DialogActions>
        </Dialog> 

        <div className="ui-grid ui-dashboard ui-dashboard-class">
          <Chart id="cadastros_por_status"
            crud={crud} 
            space="6" 
            type="bar" 
            title={utils.lng('cadastros_por_status', "Cadastros por Status")} 
            series={ series } 
            period="mes_atual"
            periods={periodos}
            tooltip="{name}: {valueY}"
            click={ openListagemCadastrosPorStatus }
            action="dashboard_adm_cad_cadastrosPorStatus">
          </Chart>

          <Chart id="novos_x_ativacao"
            crud={crud} 
            space="6" 
            type="bar" 
            layout="horizontal" 
            title={utils.lng('novos_x_ativacao', "Registros Novos X Ativação")} 
            series={series} 
            tooltip="{name}: {valueX}"
            period="mes_atual" 
            periods={periodos}
            click={ openListagemNovosXAtivacao }
            action="dashboard_adm_cad_novosXativacao">
          </Chart>

          <Chart id="tempo_espera"
            crud={crud}  
            space="12"
            height="1000px"
            type="bar" 
            // barSize={ 200 }
            layout="horizontal" 
            title={utils.lng('tempo_espera', "Tempo de Espera")} 
            series={series} 
            tooltip="{name}: {valueX}"
            click={ openListagemClientesTempoEspera }
            action="dashboard_adm_cad_tempoEspera">
          </Chart>
        </div>
      </>
    </View>
  );
}

export default DashboardAdmCadastro;