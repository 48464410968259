import React, { useState, useEffect } from "react";
import View from './view';
import Element from './element';

function Eventos() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  crud.openEvento =function(el,args){
    window.location.href = '/crud/Evento/'+args.data.id;
  }

  let element = {
    fixSelected: true,
    layout: "span",
    styleClass: "ui-component ui-grid-card",
    header: false,
    rowStyleClass: "ui-border-${@this.finalizado ? 'red' : 'green'} ui-border-left",
    groupValue: "tipo_constante",
    groupLabel: "tipo_descricao",
    name: "eventos",
    field: "eventos",
    type: "list",
    space: 12,
    edit: true,
    visible: true,
    required: false,
    entity: 'Evento',
    add: {
      redirect: 'crud/Evento/0'
    },
    label: "Eventos",
    columns: {
      info: {
        layout: "span",
        styleClass: "ui-component ",
        value: "<span>${@this.titulo}\n<br> ${@this.finalizado ? '<b style=\"color: #dc3545\">Finalizado</b><br>' : ''} \n<b>${@this.data_inicial}</b>\n</span>",
        name: "info",
        field: "info",
        type: "column",
        attrs: {
          columnStyle: {}
        }
      },
      texto: {
        layout: "span",
        styleClass: "ui-component ",
        name: "texto",
        field: "texto",
        type: "column",
        visible: true,
      },
      more: {
        layout: "span",
        styleClass: "ui-component ",
        icon: "fas fa-ellipsis-v",
        name: "more",
        field: "more",
        type: "column",
        entity: 'Evento',
        columnStyle: {
          background: "#0C3C75",
          color: "white",
          width: "20px"
        }
      }
    },
    click: {
      action: "openEvento"
    },
    list: {
      url: "listarTodosEventos",
      method: "GET"
    }
  }
  return (
    <View name="eventos">
      <Element value={element} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default Eventos;