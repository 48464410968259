import React, { useState, useEffect } from "react";
import Crud from './crud';
import View from './view';
import utils from './utils';

function Generic() {
  const [view, setView] = useState({});
  const [value, setValue] = useState({});
  const path = window.location.pathname;

  const split = path.split('/');
  const tab = utils.getTab();

  value.entity = split[2];
  value.label = split[2];

  if (split.length > 3) {
    try {
      let id = parseInt(split[3]);

      if (id >= 0) {
        value.id = id;
      }
    } catch (error) {
      //faz nada
    }
  }
  window.tab = tab;

 //utils.addTab(value);

  return (
    <View view={view}>
      <Crud view={view} value={value} ></Crud>
    </View>
  );
}

export default Generic;