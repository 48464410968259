import React, { useState, useEffect } from "react";
import Element from './element';
import View from './view';
import utils from "./utils";
import Button from '@material-ui/core/Button';

const EntrevistaConsultor = () => {
    const [element, setElement] = useState({});
    const [crud, setCrud] = useState({ data: {}, changed: {} });
    const [data, setData] = useState({});
    const [view, setView] = useState({});
    const [name, setName] = useState('none');
    let [index, setIndex] = useState(0);
    let [ready, setReady] = useState(false);
    let [entrevista, setEntrevista] = useState(0);

    let split = window.location.pathname.substring(1).split('/');

    if (split.length > 2) {
        entrevista = parseInt(split[2]);
    }

    crud.refresh = function () {
        index = index + 1;
        setIndex(index);
    };

    const lerDados = function (entrevista, cmp) {
        utils.execute(crud, 'carregarDadosEntrevista?id_entrevista=' + entrevista, null, function (res) {
            for (const i in res.data) {
                const vi = res.data[i];

                for (const x in vi) {
                    crud.changed[x] = vi[x];
                    crud.data[x] = vi[x];
                }
            }
            setElement(cmp);
            setReady(true);
        }, true);
    }

    useEffect(() => {
        if (!ready) {
            utils.execute(crud, 'formulario_entrevista/nova_entrevista_consultor?id_entrevista=' + entrevista, null, function (res) {
                crud.changed.reference = entrevista;
                crud.changed.id_entrevista = entrevista;

                crud.data.reference = entrevista;
                crud.data.id_entrevista = entrevista;

                let vw = res.data;

                if (vw) {
                    let ed = vw.data;

                    if (ed) {
                        console.log(ed)
                        for (const key in ed) {
                            crud.changed[key] = ed[key];
                            crud.data[key] = ed[key];
                        }
                    }
                }

                lerDados(entrevista, vw);
            }, true);
        }
    }, []);


    const onClickFormulario = function () {
        const _call_ = {};
        const btn = {
            click: {
                url: 'salvarEntrevista',
                redirect: '/entrevistas',
                method: 'POST'
            }
        };

        _call_.event = 'click';
        _call_.element = btn;
        _call_.global = true;
        _call_.cfg = btn.click;
        _call_.data = crud.data;
        _call_.changed = crud.changed;
        _call_.crud = crud;
        _call_.view = view;
        _call_.validate = true;

        utils.call(_call_);
    };

    window.crud = crud;

    return (
        <View name="entrevista">
            {ready &&
                <>
                    <Element view={view}
                        value={element}
                        data={data}
                        crud={crud}
                    />
                    <div className="ui-acoes-entrevista">
                        <center>
                            <Button onClick={onClickFormulario} variant="contained">
                                {utils.lng('enviar_formulario', 'Enviar formulario')}
                            </Button>
                        </center>
                    </div>
                </>
            }
            {!ready &&
                <div className="ui-loading">
                    <i className="fas fas fa-circle-notch fa-spin"></i>
                </div>
            }
        </View>
    )
};

export default EntrevistaConsultor;