import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioBonusIndividualPeriodo() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let [visualizarApuracao, setVisualizarApuracao] = useState(false);
  
  //utilizada consulta do resumo do dashboart pois já tras o parâmetro tratado com data do servidor.
  utils.execute(
    crud, "dashboard_encontrarResumoDashboard", null,
    function (res) {
      if (res.data) {
        try {
          setVisualizarApuracao(res.data[0].visualizar_spv_apurado);
          if (!visualizarApuracao) {
            reportCfg.filters.id_tipo_movimento.value = 1;
            crud.changed.id_tipo_movimento = reportCfg.filters.id_tipo_movimento.value;
          }
        } catch (e) {
          setVisualizarApuracao(false);
        }
      }
    }
  );
  const reportCfg = {
    label: utils.lng('bonus_individual_por_periodo', 'Bônus Individual por Período'),
    name: 'reportCfg',
    add: false,
    auto: false,
    table: true,
    type: 'list',
    
    list: {
      url: 'report_bonus_individual_por_periodo', define: 'resultList'
    },
    groups: [
      [
        { label: '', cols: 7 },
        { label: utils.lng('estorno_creditado','Estorno Creditado'), cols: 1, style: { textAlign: 'center' } },
        { label: utils.lng('estorno_debitado','Estorno Debitado'), cols: 1, style: { textAlign: 'center' } },
        { label: 'SPV Gerado', cols: 5, style: { textAlign: 'center' } }
      ]
    ],
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data', "Data")
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_tipo_movimento: {
        type: 'select',
        label: utils.lng('tipo_movimento', "Tipo Movimento"),
        list:{url: 'TipoMovimento'},
        itemLabel: 'descricao',
        itemValue: 'id',
        disabled: !visualizarApuracao
      },
      btn_nota: {
        type: 'button',
        label: utils.lng("gerar_espelho_nota","Gerar Espelho Nota"), 
        click: function(el, args) {
          let urlLinkNota = window.resultList && window.resultList[0] ? window.resultList[0].url_link_nota : '';
          if (typeof urlLinkNota != 'undefined' && urlLinkNota !== '') {
              window.location.href = urlLinkNota;
          }
        }
      }
    },
    columns: {
      arvoreGeracoes: { field: 'arvore_geracao', label: utils.lng("hierarquia","Hierarquia"), filter: false, sort: false },
      codigo: { field: 'codigo_mundial', label: utils.lng('codigo', 'Código'), filter: false, sort: false },
      codigo_referencia: { field: 'cod_referencia', label: utils.lng('cod_erp', 'Cód. ERP'), filter: false, sort: false },
      nome: { field: 'nome_cliente', label: utils.lng('nome', 'Nome'), filter: false, sort: false },
      codigo_graduacao_titulo: { field: 'codigo_graduacao_titulo', label: utils.lng('grad', 'Grad.'), filter: false, sort: false },
      is_qualificado: { field: 'is_qualificado', label: 'Q', filter: false, sort: false },
      //SPV Gerado
      baseCalculo: { aggregate: 'sum', field: 'base_calculo', format: 'number', label: utils.lng('base_calculo', 'Base de Cálculo'), filter: false, sort: false },
      spv_chd_credito: { aggregate: 'sum', field: 'spv_chd_credito', format: 'number', label: utils.lng('chd', 'CHD'), filter: false, sort: false },
      spv_chd_debito: { aggregate: 'sum', field: 'spv_chd_debito', format: 'number', label: utils.lng('chd', 'CHD'), filter: false, sort: false },
      percSpvDireto: { field: 'perc_spv_direto', format: 'number', label: utils.lng('perc_spv_direto', '% Direto'), filter: false, sort: false, style: { textAlign: 'center' } },
      spvDireto: { aggregate: 'sum', field: 'spv_direto', format: 'number', label: utils.lng('spv_direto', 'SPV Direto'), filter: false, sort: false },
      percSpvGraduacao: { field: 'perc_spv_graduacao', format: 'number', label: utils.lng('perc_graduacao', '% Graduação'), filter: false, sort: false, style: { textAlign: 'center' } },
      spvGraduacao: { aggregate: 'sum', field: 'spv_graduacao', format: 'number', label: utils.lng('spv_graduacao', 'SPV Graduação'), filter: false, sort: false },
      totalSPV: { aggregate: 'sum', field: 'total_spv', format: 'number', label: utils.lng('total_spv', 'Total SPV'), filter: false, sort: false },
    }
  };

  return (
    <View name="rel-bonus-individual-periodo" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioBonusIndividualPeriodo;