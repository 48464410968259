import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Element from './element';
import Button from '@material-ui/core/Button';


function Notificacoes() {
  let [view, setView] = useState({});
  let [notificacao, setNotificacao] = useState({});
  let [ready, setReady] = useState(false);
  let [dialogOpen, setDialogOpen] = useState(false);
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let user = utils.getUser();

  const notificacoes = {
    name: 'notificacoes',
    header: false,
    widgetVar: 'notificacoes',
    label: 'Minhas notificações',
    add: false,
    type: 'list',
    layout: 'line-card',
    list: {
      url: 'listarNotificacoes',
      send: {
        query: '${@this.query}'
      }
    },
    click: {
      action: 'open'
    },
    columns: {
      icone: { value: 'fa fa-bell', type: "icon", columnStyle: { width: '80px' } },
      titulo: {},
      dh_registro: { type: 'datetime' }
    }
  };


  crud.open = function (el, args) {
    const d = args.data;

    notificacao = d;
    notificacao.visualizada = true;

    dialogOpen = true;

    setNotificacao(d);
    setDialogOpen(true);
  };

  const saveNot = function (complete) {
    const _ks_ = {};
    const _dt_ = notificacao;

    _ks_.crud = crud;
    _ks_.changed = _dt_;
    _ks_.element = {};
    _ks_.complete = function (res) {
      if (complete) {
        complete.call(res);
      }
      notificacoes.refresh();
    };

    _ks_.cfg = {
      url: 'NGSNotificacaoUsuario/' + notificacao.id,
      method: 'PUT',
      alert: false
    };
    utils.call(_ks_);
  };

  const handleGoDialog = function () {
    saveNot(function () {
      window.location.href = notificacao.url;
    });
  }

  const handleCloseDialog = function () {
    saveNot(function () {
      window.location.reload();
    });
  }

  return (
    <View name="notificacao" view={view} >
      <div className="ui-notificacao">
        <Dialog maxWidth="lg" onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={dialogOpen}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            {notificacao.titulo}
          </DialogTitle>
          <DialogContent>
            {notificacao.texto}
          </DialogContent>
          <DialogActions>
            {notificacao.url &&
              <Button autoFocus onClick={handleGoDialog} color="primary">
                {utils.lng('ir', 'IR')}
              </Button>
            }
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              {utils.lng('fechar', 'FECHAR')}
            </Button>
          </DialogActions>
        </Dialog>
        <Element value={notificacoes} crud={crud} data={crud.data} view={view} ></Element>
      </div>
    </View >
  );
}

export default Notificacoes;