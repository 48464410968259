import React, { useState, useEffect, useRef } from "react";
import Element from "./element";
import utils from "./utils";
import View from "./view";

export default function Credenciamento(props) {
    let [view, setView] = useState({});
    let [element, setElement] = useState({});
    let [crud, setCrud] = useState({ data: {}, changed: {} });
    let [index, setIndex] = useState(0);
    let api = utils.getUrlApi();

    crud.onSelectCliente = function (el, args) {
        index = index + 1;
        setIndex(index + 1);
        setCrud(crud);
    }

    crud.onChangeSocio = function (el, args) {
        if (crud.changed.tipo.constante === 'socio') {
            crud.changed.nome = crud.objects.socio.nome;
            crud.changed.cargo = crud.objects.socio.tipo_socio;
        } else if (crud.changed.tipo.constante === 'conjuge') {
            crud.changed.nome = crud.objects.socio.nome_conjuge;
            crud.changed.cargo = 'Cônjuge';
        }

        crud.data.nome = crud.changed.nome;
        crud.data.cargo = crud.changed.cargo;
        crud.onSelectCliente();
    }

    crud.onChangeTipo = function () {
        crud.changed.nome = '';
        crud.data.nome = '';
        crud.data.socio = {};
        crud.changed.socio = {};
        crud.objects.socio = {};
        crud.onSelectCliente();
    }

    crud.imprimirCracha = function (el, args) {
        let data = crud.changed;

        if (data.cliente && data.evento && data.cargo) {
            let token = localStorage.getItem('token');
            let url = api + 'public/cracha/' + data.cliente.id + '-' + data.evento.id + '.png?nome=' + data.nome + '&cargo=' + data.cargo + '&access_token=' + token;

            let win = window.open('');
            let html = '<html><head>{head}</head><body>{body}</body></html>';
            let style = '<style> @media print { @page {margin: 0 ;}body {padding-top: 0 ;}}</style>';

            html = html.replace('{head}', style);
            html = html.replace('{body}', '<img src="' + url + '" onload="window.print();window.close()" />');

            win.document.write(html);
            win.focus();
        }

    }
    element.elements = {
        dados: {
            type: 'card',
            allowRefresh: true,
            label: 'Emitir crachás',
            elements: {
                evento: { type: 'entity', entity: 'Evento', label: 'Evento', space: 6, field: 'evento', required: true },
                cliente: {
                    type: 'entity', entity: 'Cliente', label: 'Cliente', field: 'cliente', space: 6, required: true,
                    change: {
                        action: 'onSelectCliente',
                        update: ['socio']
                    }
                },
                tipo: {
                    type: 'select',
                    label: 'Tipo', itemValue: 'value', itemLabel: 'label', required: true,
                    rendered: '${true}',
                    change: {
                        action: 'onChangeTipo'
                    },
                    data: [
                        { value: 'socio', label: 'Sócio' },
                        { value: 'conjuge', label: 'Cônjuge' },
                        { value: 'outros', label: 'Outros' }
                    ],
                    space: 6
                },
                socio: {
                    type: 'select', label: 'Sócio', itemValue: 'nome', itemLabel: 'nome',
                    rendered: "${@this.tipo.constante === 'socio' || @this.tipo.constante === 'conjuge'}",
                    list: {
                        url: 'listar_socios_credenciamento',
                        send: {
                            'id_pessoa': '${@this.cliente.id_pessoa}'
                        }
                    },
                    change: {
                        action: 'onChangeSocio'
                    },
                    space: 6
                },
                nome: { type: 'text', rendered: "${exists(@this,'tipo')}", disabled: "${@this.tipo.constante !== 'outros'}", label: 'Nome', space: 8, required: true },
                cargo: {
                    type: 'select', object: false, rendered: "${@this.tipo.constante === 'outros'}", label: 'Cargo', space: 4, itemLabel: 'key', itemKey: 'key', itemValue: 'key',
                    data: [
                        { key: 'Assessor' },
                        { key: 'Consultor' },
                        { key: 'Diretoria' },
                        { key: 'Convidado' },
                        { key: '.' }
                    ]
                }
            }
        },
        botoes: {
            type: 'card',
            elements: {
                gerar: { icon: 'fa fa-print', type: 'button', label: 'Emitir', space: 4, click: { action: 'imprimirCracha', validate: true } }
            }
        }
    };

    return (
        <View name="credenciamento">
            <Element index={index} value={element} crud={crud} data={crud.data} view={view} ></Element>
        </View>
    );
}
