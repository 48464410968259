import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioValidacaoGraduacao() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let listaMeses = [
    { label: utils.lng('janeiro', "Janeiro"), value: '0' },
    { label: utils.lng('fevereiro', "Fevereiro"), value: '1' },
    { label: utils.lng('marco', "Março") , value: '2' },
    { label: utils.lng('abril', "Abril"), value: '3' },
    { label: utils.lng('maio', "Maio"), value: '4' },
    { label: utils.lng('junho', "Junho"), value: '5' },
    { label: utils.lng('julho', "Julho"), value: '6' },
    { label: utils.lng('agosto', "Agosto"), value: '7' },
    { label: utils.lng('setembro', "Setembro"), value: '8' },
    { label: utils.lng('outubro', "Outubro"), value: '9' },
    { label: utils.lng('novembro', "Novembro"), value: '10' },
    { label: utils.lng('dezembro', "Dezembro"), value: '11' }
  ];

  crud.validaFiltros = function (value, args) {
    let rtno = false;
    let isClienteOk = crud.validaCliente(value, args);
    let isPeriodoOk = crud.validaPeriodo(value, args);
    rtno = isClienteOk === "" && isPeriodoOk === "";
  }

  crud.validaCliente = function (value, args) {
    let data = args.data;
    let id_cliente = data ? data.id_cliente : null;
    return id_cliente ? "" : utils.lng("cliente_nao_informado","Cliente não informado");
  }

  crud.validaPeriodo = function (value, args) {
    let periodoValido = false;
    let data = args.data;
    let mesInicial = data ? data.mes_inicial : null;
    let anoInicial = data ? data.ano_inicial : null;
    let mesFinal = data ? data.mes_final : null;
    let anoFinal = data ? data.ano_final : null;
    //
    if (mesInicial != null && anoInicial != null && mesFinal != null && anoFinal != null) {
      let dataInicial = new Date();
      let dataFinal = new Date();
      dataInicial.setMonth(mesInicial);
      dataInicial.setFullYear(anoInicial);
      dataFinal.setMonth(mesFinal);
      dataFinal.setFullYear(anoFinal);
      periodoValido = dataInicial < dataFinal;
    }
    return periodoValido ? "" : utils.lng("periodo_informado_invalido","Período Informado é Inválido");
  }

  const reportCfg = {
    label: utils.lng('validacao_graduacao','Validação de Graduação'),
    name: 'reportCfg',
    add: false,
    type: 'list',
    list: {
      url: 'report_validacao_graduacao',
      validate: 'validaFiltros'
    },
    filters: {
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        columnStyle : {width: '100%'},
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      mes_inicial: {
        type: 'select',
        label: utils.lng('mes_inicial', "Mês Inicial"),
        change: {
          action: 'validaPeriodo'
        },
        data: listaMeses
      },
      ano_inicial: {
        type: 'number',
        label: utils.lng('ano_inicial', "Ano Inicial"),
        change: {
          action: 'validaPeriodo'
        }
      },
      mes_final: {
        type: 'select',
        label: utils.lng('mes_final', "Mês Final"),
        change: {
          action: 'validaPeriodo'
        },
        data: listaMeses
      },
      ano_final: {
        type: 'number',
        label: utils.lng('ano_final', "Ano Final"),
        change: {
          action: 'validaPeriodo'
        }
      }
    },
    columns: {
      codigo: { field: 'codigo', label: utils.lng('codigo', "Código"), filter: false, sort: false },
      nome: { field: 'nome', label: utils.lng('nome', "Nome"), filter: false, sort: false },
      dataReferencia: { field: 'ano_mes', label: utils.lng('data_referencia', "Data Referência"), filter: false, sort: false },
      pontuacao: { field: 'pontuacao', format: 'number', decimalCount: 4, label: utils.lng('pontuacao', "Pontuação"), filter: false, sort: false },
      filhosDiretos: { field: 'filhos_diretos', format: 'number', label: utils.lng('filhos_diretos', "Filhos Diretos"), filter: false, sort: false },
      graduacao: { field: 'graduacao', label: utils.lng('graduacao', "Graduação"), filter: false, sort: false },
      pesoGraduacao: { field: 'peso_graduacao', label: utils.lng('peso_graduacao', "Peso Graduação"), filter: false, sort: false },
      graduacaoPretendida: { field: 'graduacao_pretendida', label: utils.lng('graduacao_pretendida', "Grad. Pretendida"), filter: false, sort: false },
      pesoGraduacaoPretendida: { field: 'peso_graduacao_pretendida', label: utils.lng('peso_graduacao_pretendida', "Peso Grad. Pretendida"), filter: false, sort: false },
      filhoSuperior: { field: 'filho_superior', label: utils.lng('filho_superior', "Filho Superior"), filter: false, sort: false }
    }
  };

  return (
    <View className="ui-view-report" name="rel-validacao_graduacao">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioValidacaoGraduacao;