import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioPontuacaoRede() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('pontuacao_em_rede', 'Pontuação em Rede'),
    name: 'reportCfg',
    add: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_pontuacaoRede'
    },
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data', "Data")
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true || (utils.getUser().tipo+'').indexOf('ADMINISTRATIVO') > -1 ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      mostrar_di_sem_pontos: {
        type: 'checkbox',
        label: utils.lng("mostrar_dis_sem_pontos", "Mostrar DIs sem pontos")
      },
      id_tipo_movimento: {
        type: 'select',
        label: utils.lng('tipo_movimento', "Tipo Movimento"),
        list:{url: 'TipoMovimento'},
        itemLabel: 'descricao',
        itemValue: 'id',
        rendered: utils.getUser().administrativo === true || (utils.getUser().tipo+'').indexOf('ADMINISTRATIVO') > -1
      }
    },
    columns: {
      arvoreGeracoes: { field: 'arvore_geracao', label: utils.lng("arvore_geracoes","Árvore de Gerações"), filter: false, sort: false },
      codigo: { field: 'codigo_mundial', label: utils.lng("codigo","Código"), filter: false, sort: false },
      codigo_referencia: { field: 'codigo_referencia', label: utils.lng("cod_erp","Cód.ERP"), filter: false, sort: false },
      nome: { field: 'nome_cliente', label: utils.lng("nome","Nome"), filter: false, sort: false },
      graduacao: { field: 'graduacao', label: utils.lng("graduacao","Graduação"), filter: false, sort: false },
      filial: { field: 'fantasia_filial', label: utils.lng("filial","Filial"), filter: false, sort: false },
      dataAtivacao: { field: 'data_ativacao', label: utils.lng("data_ativacao","Data de Ativação"), filter: false, sort: false , type:'date'},
      statusPenn: { field: 'status_penn', label: utils.lng("status_penn","Status Penn"), filter: false, sort: false },
      ptsIndividuais: { field: 'pts_individuais', format: 'number', decimalCount: 4, label: utils.lng("pontos_individuais","Pontos Individuais"), filter: false, sort: false },
      presidente: { field: 'presidente', label: utils.lng("presidente","Presidente"), filter: false, sort: false },
      totalPontos: { field: 'total_pontos', format: 'number', decimalCount: 4, label: utils.lng("pontos_com_rede","Pontos com Rede"), filter: false, sort: false },
      nrFilhos: { field: 'nr_filhos_diretos', label: utils.lng("nr_filhos_diretos","Nº Filhos Diretos"), filter: false, sort: false },
      dhUltimaCompra: { field: 'dh_ultima_compra', label: utils.lng("dh_ultima_compra","Data última compra"), filter: false, sort: false, type:"date"}
    }
  };

  return (
    <View className="ui-view-report" name="rel-pontuacao_rede">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioPontuacaoRede;