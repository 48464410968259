import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';
import MenuCarrinho from './menu_carrinho';
import { useParams } from "react-router";

function MeusOrcamentos() {
  let [view, setView] = useState({});
  let [element, setElement] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  let { id } = useParams();

  const createElement = function () {
    return {
      elements: {
        orcamento: {
          name: 'itens',
          type: 'list',
          layout: 'table',
          rows: 10,
          label: 'Orçamentos',
          add: false,
          showLabel: true,
          header: false,
          rowStyleClass: "${'ui-row-' + @this.constante_status}",
          list: {
            url: 'listarMeusPedidos?orcamento=true&id_cliente=' + id
          },
          columns: {
            numero_pedido: { type: 'span', value: "${'Pedido ' + @this.numero_pedido}", columnStyleClass: "${'ui-col-status-ped ui-col-' + @this.constante_status}" },
            dh_registro: { label: 'Data do pedido', type: 'span', bold: true },
            valor_total: { label: 'Valor total', type: 'span', format: 'money', bold: true },
            quantidade: { label: 'Itens no pedido', type: 'span', bold: true },
            descricao_status: { label: 'Status do pedido', type: 'span', bold: true },
            detalhes: {
              disabled: '${!@this.is_administrativo && @this.bloquear_edicao}',
              type: 'button',
              label: "VER DETALHES",
              click: {
                action: "open",
                url: '/carrinho/pedido/{id_pedido}'
              }
            }
          }
        }
      }
    }
  };

  if (!ready) {
    element = createElement();
    setElement(element);
    ready = true;
    setReady(true);
  }

  crud.open = function (value, args) {
    const click = value.click;

    if (!click) {
      return;
    }
    const data = args.data;
    let d = {};
    let url = click.url;

    for (const i in data) {
      const v = data[i];
      const b = '{' + i + '}';
      const c = v + '';

      url = url.replace(b, c);
    }
    d.path = url;
    d.label = click.label;

    window.location.href = url;
  }

  crud.setarTab = function (el, args) {
    crud.tabSel = args.data.key;

    element = createElement();
    setElement(element);

    index = index + 1;
    setIndex(index + 1);
    setCrud(crud);
    setElement(element);
  }



  return (
    <View name="meus-pedidos">
      <div className="ui-meus-pedidos">
        <Element index={index} value={element} crud={crud} data={crud.data} view={view} ></Element>
      </div>
    </View>
  );
}

export default MeusOrcamentos;