import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';

function GerarCard() {
  const [view, setView] = useState({});
  const [data, setData] = useState({});
  const [columns, setColumns] = useState({});
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [crud, setCrud] = useState({ requireds: {}, data: {}, changed: {} });

  const complete_execute = function (res) {
    const rdata = res.data;
    const rresult = rdata.result;
    const rcolumns = rdata.columns;
    const rcount = rdata.count;

    setList(rresult);
    setColumns(rcolumns);
    setCount(rcount);
  };

  const editor = {
    name: 'editor',
    elements: {
      nome: { type: 'text', label: 'Nome do card', required: true },
      valor: { type: 'textarea', label: 'Informe a SQL aqui', required: true, rows: 10 },
      acoes: {
        elements: {
          executar: {
            type: 'button', label: 'Executar SQL', style: { textAlign: 'right', width: '200px', marginBottom: '10px' }, click: {
              url: 'execute_sql',
              method: 'POST',
              complete: complete_execute
            }
          },
          gerar_card: {
            type: 'button', label: 'Gerar card', style: { textAlign: 'right', width: '200px', marginBottom: '10px' }, click: {
              url: 'gerarCard',
              prompt: 'Tem certeza que deseja gerar este card?',
              method: 'POST'
            }
          }
        }
      }
    }
  };

  return (
    <View>
      <div className="ui-card ui-padding">
        <Element value={editor} crud={crud} data={data} view={view} ></Element>
        {list.length > 0 &&
          <div className="ui-sql-result">
            <table className="ui-sql-grid">
              <thead>
                <tr>
                  {Object.entries(columns).map((cl, ib) => (
                    <th>
                      {cl[0]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {list.map((ri, ia) => (
                  <tr className={ia % 2 === 0 ? 'ui-row-even' : 'ui-row-odd'}>
                    {Object.entries(columns).map((cl, ib) => (
                      <td>
                        {ri[cl[0]]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    </View>
  );
}

export default GerarCard;