import React, { useState, useEffect } from "react";
import utils from './utils';
import View from './view';
import Element from './element';

function TransferenciaMovimentacao() {
  let data = {};
  let [view, setView] = useState({});
  let [index, setIndex] = useState(0);
  let [crud, setCrud] = useState({ data: data, changed: data, requireds: {} });

  view.refresh = function () {
    setIndex(index + 1);
  }

  crud.refresh = function () {
    setIndex(index + 1);
    setCrud(crud);
  }

  const cconsultor = {
    name: 'consultor',
    elements: {
      dados: {
        label: utils.lng('transferencia_movimentacao', 'Transferência de Movimentação'),
        type: 'legend',
        elements: {
          card_geracao: {
            type: 'card',
            styleClass: 'nomargin-left',
            label: utils.lng('informe_cliente_origem_destino','Informe o cliente de origem e destino'),
            elements: {
              cliente_origem: {
                space: 6,
                field: 'origem',
                type: 'entity',
                label: utils.lng('origem','Origem'),
                entity: 'Cliente',
                required: true,
                list: {
                  url: 'listarClientesFluxo',
                  send: false
                }
              },
              cliente_destino: {
                space: 6,
                field: 'destino',
                type: 'entity',
                label: utils.lng('destino','Destino'),
                entity: 'Cliente',
                required: true,
                list: {
                  url: 'listarClientesFluxo',
                  send: false
                }
              },
            }
          },
          bloco_botoes: {
            type: 'card',
            styleClass: 'nomargin-left',
            elements: {
              botoes: {
                style: { marginTop: '0',textAlign:'right' },
                elements: {
                  salvar: {
                    label: utils.lng('transferir','Transferir'),
                    type: 'button',
                    theme: 'green',
                    click: {
                      method: 'POST',
                      url: 'transfereMovimentacao',
                      send: {
                        id_origem: '${@this.origem.id}',
                        id_destino: '${@this.destino.id}'
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <View name="troca-api" view={view} >
      <Element value={cconsultor} crud={crud} data={crud.data} view={view} ></Element>
    </View >
  );
}

export default TransferenciaMovimentacao;