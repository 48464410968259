import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';

function TesteRotinaGraduacao() {
  const [view, setView] = useState({});
  let [cliente, setCliente] = useState({});
  let [list, setList] = useState([]);
  const [crud, setCrud] = useState({ requireds: {}, data: {}, changed: {} });
  const [data, setData] = useState({});
  // const [columns, setColumns] = useState({});
  // const [count, setCount] = useState(0);
  

  const complete_execute = function (res) {
    setList(res.data);
  };

  crud.changeCliente = function (el, args) {
    let id_cliente = args && args.cliente ? args.cliente.id : null;
    if (id_cliente) {
      const requestCfg = {};
      
      requestCfg.method = 'GET';
      requestCfg.alert = false;
      requestCfg.url = 'GraduacaoHistorico?cliente=' + id_cliente;

      crud.sendRequest(requestCfg);
    }
  };

  crud.changeTratamentoPts = function (el, args) {
    if (args.processar_pontuacao_real) {
      crud.elements.pontos_rede.setValue(null);
      crud.elements.pontos_individuais.setValue(null);
    }
  };

  crud.executaTeste = function (value, args) {
    let data = args.data;
    let dados = {};
    dados.id_cliente = data && data.cliente ? data.cliente.id : null;
    dados.mes = data && data.mes ? data.mes : null;
    dados.ano = data && data.ano ? data.ano : null;
    dados.pontos_rede = data && data.pontos_rede ? data.pontos_rede : 0;
    dados.pontos_individuais = data && data.pontos_individuais ? data.pontos_individuais : 0;
    dados.processar_pontuacao_real = data && data.processar_pontuacao_real ? data.processar_pontuacao_real : false;

    const requestCfg = {};
    
    requestCfg.method = 'POST';
    requestCfg.alert = true;
    requestCfg.url = 'testeRotinaGraduacaoCliente';
    requestCfg.url += '?id_cliente='+dados.id_cliente;
    requestCfg.url += '&mes='+dados.mes;
    requestCfg.url += '&ano='+dados.ano;
    requestCfg.url += '&pontos_rede='+dados.pontos_rede;
    requestCfg.url += '&pontos_individuais='+dados.pontos_individuais
    requestCfg.url += '&processar_pontuacao_real='+dados.processar_pontuacao_real
    
    crud.sendRequest(requestCfg);
  };

  crud.sendRequest = function(requestCfg) {
    const _call_ = {};
    // sel.observacao = observacao;
    const sel = {};//dados ? dados : {};
    if (crud.acao) {
      sel.status = crud.acao.id_status;
    }

    _call_.global = true;
    _call_.element = {};
    _call_.cfg = requestCfg;
    _call_.complete = complete_execute;
    _call_.data = sel;
    _call_.changed = sel;
    _call_.crud = crud;
    _call_.view = view;

    utils.call(_call_);
  }

  crud.onClickHistorico = function(id) {
    let url = '/crud/GraduacaoHistorico/'+id;
    window.location.href = url;
  }

  const editor = {
    name: 'editor',
    elements: {
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        name: "cliente",
        field: "cliente",
        cols : 4,
        label: utils.lng('cliente', "Cliente"),
        list: { url: 'endpoint_listar_all_clientes'},
        itemLabel: 'nome',
        itemValue: 'id',
        change: {
          action: 'changeCliente'
        }
      },
      mes: { type: 'number', label: utils.lng('mes', "Mês"), cols : 1 , required: true},
      ano: { type: 'number', label: utils.lng('ano', "Ano"), cols : 1 , required: true},
      pontos_rede: { type: 'number', label: utils.lng('pontos_rede', "Pontos Rede"), cols : 2, disabled: "${@this.processar_pontuacao_real}" },
      pontos_individuais: { type: 'number', label: utils.lng('pontos_individuais', "Pontos Individuais"), cols : 2, disabled: "${@this.processar_pontuacao_real}" },
      processar_pontuacao_real : { 
        type: 'checkbox', label: utils.lng('utilizar_pts_apurados', "Utilizar Pts Apurados"), cols : 2, change: { action: 'changeTratamentoPts' }
      },
      executar: {
        type: 'button', 
        label: 'Executar Teste', 
        style: { textAlign: 'right' }, 
        click: {
          action: 'executaTeste'
        }
      }
    }
  };

  return (
    <View>
      <div>
        <Element value={editor} crud={crud} data={data} view={view} ></Element>

        <div className="ui-sql-result">
          <table className="ui-sql-grid" width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Data Registro</th>
                <th>Ativo</th>
                <th>Graduação Título</th>
                <th>Graduação Ganho</th>
                <th>Graduação Peso</th>
                <th>Status</th>
                <th>Tipo Evolução</th>
                <th>Cota Atingida</th>
              </tr>
            </thead>
            <tbody>
              {list.map((row, ia) => (
                <tr className={ia % 2 === 0 ? 'ui-row-even' : 'ui-row-odd'}>
                  <td onClick={() => {
                    crud.onClickHistorico(row.id)
                  }} className='ui-text-bold'>{row.id}</td>
                  <td>{row.dhRegistro}</td>
                  <td>{row.ativo ? 'SIM' : 'NÃO'}</td>
                  <td>{row.graduacaoTitulo ? row.graduacaoTitulo.descricao : ''}</td>
                  <td>{row.graduacaoGanho ? row.graduacaoGanho.descricao : ''}</td>
                  <td>{row.graduacaoPeso ? row.graduacaoPeso.descricao : ''}</td>
                  <td>{row.status ? row.status.constante : ''}</td>
                  <td>{row.tipoEvolucao ? row.tipoEvolucao.constante : ''}</td>
                  <td>{row.cotaAtingida}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </View>
  );
}

export default TesteRotinaGraduacao;