import React, { useState, useEffect } from "react";
import View from './view';
import utils from './utils';
import Element from './element';

function Report() {
  const [view, setView] = useState({});
  const [data, setData] = useState({});
  const [crud, setCrud] = useState({ selected: {}, data: {}, changed: {} });
  const [value, setValue] = useState({});
  const [ready, setReady] = useState(false);

  const path = window.location.pathname;

  const split = path.split('/');
  const tab = utils.getTab();
  const user = utils.getUser();

  value.name = split[2];
  value.label = split[2];


  useEffect(() => {
    const cfg = {};

    cfg.await = true;

    const _call_ = {};

    _call_.cfg = cfg;
    _call_.complete = function (res) {
      setValue(res.data);
      setReady(true);
    };
    _call_.data = {};
    _call_.view = view;
    _call_.element = value;
    _call_.crud = crud;
    _call_.changed = {};

    if (value.name) {
      cfg.method = 'GET';
      cfg.url = 'report/' + value.name;

      utils.call(_call_);
    }
  }, []);

  crud.print = function (a, b) {
    const click = b.click;
    const data = b.data;
    const down = {};


    down.method = 'GET';
    down.url = click.url ? click.url : click.entity + '/print/' + data.id + '.pdf';
    down.download = true;
    down.fileName = 'file.pdf';


    const _call_ = {};

    _call_.element = value;
    _call_.cfg = down;
    _call_.complete = function (res) {
      console.log(res);
    };
    _call_.data = data;
    _call_.view = view;

    utils.call(_call_);
  };


  return (
    <View name="reports">
      <>
        {ready &&
          <Element view={view}
            value={value}
            data={data}
            user={user}
            crud={crud}
          />
        }
        {!ready &&
          <div className="ui-loading">
            <i className="fas fas fa-circle-notch fa-spin"></i>
          </div>
        }
      </>
    </View>
  );
}

export default Report;