import React from "react";
import Routes from './routes';
import { initializeApp } from 'firebase/app';
import utils from './components/utils';


const startFireBase = () => {
    let host = window.location.host;

    if (host.indexOf('nipponflex.com') >= 0) {
        const firebaseConfig = {
            apiKey: "AIzaSyBnF5zAyQe6kalVYcpYMhpTYNOkGCyVuH8",
            authDomain: "decent-subset-265720.firebaseapp.com",
            projectId: "decent-subset-265720",
            storageBucket: "decent-subset-265720.appspot.com",
            messagingSenderId: "471543122885",
            appId: "1:471543122885:web:77e6bad315ee61107119ac",
            measurementId: "G-HFNNHQKKPK"
        };


        const firebase = initializeApp(firebaseConfig);
    }
}


export default function App() {
    let push = utils.getParam('app_push_token');

    if (push) {
        localStorage.setItem('app_push_token', push);
    }

    return (<Routes />);
};

startFireBase();