import React, { useState, useEffect } from "react";
import Crud from './crud';
import View from './view';
import utils from './utils';
import Element from "./element";

function MeusDados() {
  const [usuario, setUsuario] = useState({ changed: { eager: true }, selected: {} });
  const [data, setData] = useState({ changed: { eager: true }, selected: {} });
  const [dataa, setDataa] = useState({ changed: { eager: true }, selected: {} });
  const [eventos, setEventos] = useState([]);

  let [ready, setReady] = useState(false);
  let [crud, setCrud] = useState({});

  const user = utils.getUser();
  const id = user.id;

  usuario.entity = 'Usuario';
  usuario.label = utils.lng('meus_dados','Meus dados');
  usuario.path = 'meus_dados';
  usuario.id = id;
  usuario.eager = true;
  usuario.selected.id = id;
  usuario.name = 'meus_dados';

  
  crud.refresh = function () {

  };

  useEffect(() => {
    if (!ready) {
      setReady(true)

      utils.execute(crud, 'listarTimelineUsuario', null, function (res) {
        setEventos(res.data);
      });
    }
  });

  return (
    <View>
      <div className="ui-meus-dados-area ui-grid ui-card">
        <div className="ui-col ui-col-6">
          <Crud value={usuario} data={data}></Crud>
        </div>
        <div className="ui-col ui-col-6">
          <div className="ui-title ui-card-timeline-title">
            <b>{utils.lng('timeline_graduacao','Timeline de graduação')}</b>
          </div>
          <div className="ui-card-data">
            <div className="gr-timeline">
              {eventos.map((e, i) => (
                <div key={'evt-' + i} className={'gr-timeline-item gr-timeline-item-' + i}>
                  <div className="gr-timeline-date">
                    {e.data_aprovacao}
                  </div>
                  <div className="gr-timeline-avatar">
                    <div className="gr-timeline-avatar-risco"></div>
                    <div className="gr-timeline-avatar-inner" style={{ background: e.cor ? e.cor : '#D2B134' }}>
                      <img src={e.imagem_graduacao}></img>
                    </div>
                  </div>
                  <div className="gr-timeline-label">
                    <span className="gr-timeline-label-inner" style={{ background: e.cor ? e.cor : '#D2B134' }}>
                      {e.descricao}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}

export default MeusDados;