import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioBonusPeriodo() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let [visualizarApuracao, setVisualizarApuracao] = useState(false);
  
  //utilizada consulta do resumo do dashboart pois já tras o parâmetro tratado com data do servidor.
  utils.execute(
    crud, "dashboard_encontrarResumoDashboard", null,
    function (res) {
      if (res.data) {
        try {
          setVisualizarApuracao(res.data[0].visualizar_spv_apurado);
          if (!visualizarApuracao) {
            reportCfg.filters.id_tipo_movimento.value = 1;
            crud.changed.id_tipo_movimento = reportCfg.filters.id_tipo_movimento.value;
          }
        } catch (e) {
          setVisualizarApuracao(false);
        }
      }
    }
  );
  const reportCfg = {
    label: utils.lng('bonus_por_periodo', 'Bônus por Período'),
    name: 'reportCfg',
    add: false,
    table: true,
    type: 'list',
    
    list: {
      url: 'report_bonusPorPeriodo', define: 'resultList'
    },
    groups: [
      [
        { label: '', cols: 5 },
        { label: 'Crédito', cols: 3 },
        { label: 'Débito', cols: 5 },
        { label: '', cols: 1 }
      ]
    ],
    filters: {
      date: {
        type: 'date',
        range: true,
        dateFormat: 'long',
        label: utils.lng('data', "Data")
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id'
      },
      id_tipo_movimento: {
        type: 'select',
        label: utils.lng('tipo_movimento', "Tipo Movimento"),
        list:{url: 'TipoMovimento'},
        itemLabel: 'descricao',
        itemValue: 'id',
        disabled: !visualizarApuracao
      },
      btn_nota: {
        type: 'button',
        label: utils.lng("gerar_espelho_nota","Gerar Espelho Nota"), 
        click: function(el, args) {
          let urlLinkNota = window.resultList && window.resultList[0] ? window.resultList[0].url_link_nota : '';
          if (typeof urlLinkNota != 'undefined' && urlLinkNota !== '') {
              window.location.href = urlLinkNota;
          }
        }
      }
    },
    columns: {
      arvoreGeracoes: { field: 'arvore_geracao', label: utils.lng("arvore_geracoes","Árvore de Gerações"), filter: false, sort: false },
      codigo: { field: 'codigo_mundial', label: utils.lng('codigo', 'Código'), filter: false, sort: false },
      codigo_referencia: { field: 'cod_referencia', label: utils.lng('cod_erp', 'Cód. ERP'), filter: false, sort: false },
      nome: { field: 'nome_cliente', label: utils.lng('nome', 'Nome'), filter: false, sort: false },
      tipoMovto: { field: 'tipo_movimento', label: utils.lng('tipo_movimento', 'Tipo Movimento'), filter: false, sort: false },
      baseCalculo: { field: 'base_calculo', format: 'money', label: utils.lng('base_calculo', 'Base de Cálculo'), filter: false, sort: false },
      //Credito
      spvGraduacao: { field: 'credito_spv_graduacao', format: 'money', label: utils.lng('spv_graduacao', 'SPV Graduação')+" [+]", filter: false, sort: false },
      spvDireto: { field: 'credito_spv_direto', format: 'money', label: utils.lng('spv_direto', 'SPV Direto')+" [+]", filter: false, sort: false },
      totalCreditos: { field: 'total_creditos', format: 'money', label: utils.lng('total_creditos', 'Total Créditos')+" [+]", filter: false, sort: false },
      //Debito
      spvSaque: { field: 'debito_spv_saque', format: 'money', label: utils.lng('spv_saque', 'SPV Saque')+" [-]", filter: false, sort: false },
      spvCompraPrevia: { field: 'debito_spv_compra_previa', format: 'money', label: utils.lng('spv_compra_previa', 'SPV Compra Prévia')+" [-]", filter: false, sort: false },
      spvCompraApurado: { field: 'debito_spv_compra_apurado', format: 'money', label: utils.lng('spv_compra_apuracao', 'SPV Compra Apuração')+" [-]", filter: false, sort: false },
      debSpvGraduacao: { field: 'debito_spv_graduacao', format: 'money', label: utils.lng('spv_graduacao', 'SPV Graduação')+" [-]", filter: false, sort: false },
      debSpvDireto: { field: 'debito_spv_direto', format: 'money', label: utils.lng('spv_direto', 'SPV Direto')+" [-]", filter: false, sort: false },
      totalDebitos: { field: 'total_debitos', format: 'money', label: utils.lng('total_debitos', 'Total Débitos')+" [-]", filter: false, sort: false },
      //
      saldoFinal: { field: 'saldo_final', format: 'money', label: utils.lng('saldo_final', 'Saldo Final')+" [=]", filter: false, sort: false }
    }
  };

  return (
    <View name="rel-bonus-periodo" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioBonusPeriodo;