import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioConferenciaSpvGraduacaoPedido() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });

  const reportCfg = {
    label: utils.lng('conferencia_spv_graduacao_pedido', 'Conferência de SPV de Graduação por Pedido'),
    name: 'reportCfg',
    add: false,
    auto: false,
    print:true,
    type: 'list',
    list: {
      url: 'report_conferencia_spv_graduacao_pedido'
    },
    filters: {
      id_pedido: {
        type: 'entity',
        entity: 'HubPedidoVenda',
        label: utils.lng('pedido_venda', "Pedido de Venda"),
        list: { url: 'listarPedidosAprovados' }
      },
      id_tipo_movimento: {
        type: 'select',
        label: utils.lng('tipo_movimento', "Tipo Movimento"),
        list:{url: 'TipoMovimento'},
        itemLabel: 'descricao',
        itemValue: 'id'
      }
    },
    columns: {
      numeroPedido: { field: 'numero_pedido', type: 'number', label: utils.lng('pedido', 'Pedido'), filter: false, sort: false },
      codigo: { field: 'codigo_cliente', label: utils.lng('codigo_cliente', 'Código Cliente'), filter: false, sort: false },
      nome: { field: 'nome', label: utils.lng('nome', 'Nome'), filter: false, sort: false },
      filial: { field: 'filial', label: utils.lng('filial', 'Filial'), filter: false, sort: false },
      ordemHierarquia: { field: 'ordem_hierarquia', label: utils.lng('ordem_hierarquia', 'Ordem Hierarquia'), filter: false, sort: false },
      graduacao: { field: 'descricao_graduacao', label: utils.lng('graduacao', 'Graduação'), filter: false, sort: false },
      dataMovimento: { field: 'data_movimento', format: 'date', label: utils.lng('data_movimento', 'Data Movimento'), filter: false, sort: false },
      valorSpvPedido: { field: 'valor_spv_pedido', format: 'money', label: utils.lng('valor_spv_pedido', 'Valor SPV Pedido'), filter: false, sort: false },
      spvDistribuido: { field: 'spv_distribuido', format: 'money', label: utils.lng('spv_distribuido', 'SPV Distribuído'), filter: false, sort: false },
      fatorBonusGraduacao: { field: 'fator_bonus_graduacao', format: 'number', label: utils.lng('fator_bonus_graduacao', 'Fator Bônus Graduação'), filter: false, sort: false },
      percentualSpvEfetivo: { field: 'percentual_spv_efetivo', format: 'number', label: utils.lng('percentual_spv_efetivo', 'Percentual SPV Efetivo'), filter: false, sort: false },
      valor: { field: 'valor', format: 'money', label: utils.lng('valor', 'Valor'), filter: false, sort: false }
    }
  };

  return (
    <View name="rel-conferencia_spv_graduacao_pedido" className="ui-view-report">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioConferenciaSpvGraduacaoPedido;