import React, { useState, useEffect } from "react";
import View from '../view';
import Element from '../element';
import utils from '../utils';

function RelatorioValidacaoRede() {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {}, requireds: {} });
  let [selectedUrl, setSelectedUrl] = useState('');
  let [selectedColumnModel, setSelectedColumnModel] = useState([]);
  let [selectedTotalHeader, setSelectedTotalHeader] = useState([]);
  //
  let urlMembrosAtivosSemPais = 'report_validacaoRede_membrosAtivosSemPais';
  let urlDuplicidadePais = 'report_validacaoRede_duplicidadePais';
  let urlEstruturaRede = 'report_validacaoRede_estruturaRede';
  // let urlLoopingInfinito = 'report_validacaoRede_loopingInfinito';
  //
  let columnModelMembrosAtivosSemPais = {
    codigo: { field: 'codigo_mundial', label: utils.lng("codigo","Código"), filter: false, sort: false },
    nome: { field: 'nome_cliente', label: utils.lng("nome","Nome"), filter: false, sort: false },
    filial: { field: 'codigo_filial', label: utils.lng("filial","Filial"), filter: false, sort: false }
  };
  let columnModelDuplicidadePais = {
    nomeFilho: { field: 'nome_filho', label: utils.lng("nome_filho","Nome do Filho"), filter: false, sort: false },
    nomePai: { field: 'nome_pai', label: utils.lng("nome_pai","Nome do Pai"), filter: false, sort: false },
    codigoFilho: { field: 'codigo_filho', label: utils.lng("codigo_mundial_filho","Código Mundial Filho"), filter: false, sort: false },
    codigoPai: { field: 'codigo_pai', label: utils.lng("codigo_mundial_pai","Código Mundial Pai"), filter: false, sort: false },
    filial: { field: 'codigo_filial', label: utils.lng("filial","Filial"), filter: false, sort: false }
  };
  let columnModelEstruturaRede = {
    arvoreGeracoes: { field: 'arvore_geracao', label: utils.lng("arvore_geracoes","Árvore de Gerações"), filter: false, sort: false },
    codigo: { field: 'codigo_mundial', label: utils.lng("codigo","Código"), filter: false, sort: false },
    codigoReferencia: { field: 'codigo_sapiens', label: utils.lng("codigo_sapiens","Código Sapiens"), filter: false, sort: false },
    nome: { field: 'nome_cliente', label: utils.lng("nome","Nome"), filter: false, sort: false },
    pesoGraduacao: { field: 'peso_graduacao', label: utils.lng("peso_graduacao","Peso Graduação"), filter: false, sort: false , sum: true},
    statusCliente: { field: 'status_cliente', label: utils.lng("status_cliente","Status Cliente"), filter: true, sort: false , sum: false},
    statusPenn: { field: 'status_penn', label: utils.lng("status_penn","Status Penn"), filter: true, sort: false , sum: false},
    dataAtivacao: { field: 'data_ativacao', type: 'date', label: utils.lng('data_ativacao', "Data Ativação"), filter: false, sort: false },
    filial: { field: 'codigo_filial', label: utils.lng("filial","Filial"), filter: false, sort: false }
  };
  // let columnModelLoopingInfinito = {};
  //
  let headerMembrosAtivosSemPai = [
    {
      count: { field: 'count', value: "Quantidade de Membros ativos sem pai: #{@this.count}", style: {float: "left"} }
    }
  ];
  let headerMembrosDuplicidadePai = [
    {
      count: { field: 'count', value: "Quantidade de Filhos ativos que possuem mais de um pai: #{@this.count}" , style: {float: "left"} }
    }
  ];
  let headerEstruturaRede = [
    {
      count: { field: 'count', value: "Quantidade de Filhos na rede: #{@this.count -1}" , style: {float: "left"} }
    },
    {
      sum: { field: 'sum', value: "Soma de pesos total da rede: #{@this.sum}", style: {float: "left"} }
    }
  ];

  crud.changeTipoValidacao = function (cfg, args, crudFilter) {
    if (args.tipo_validacao.constante === 'membrosAtivosSemPais') {
      setSelectedUrl(urlMembrosAtivosSemPais);
      setSelectedColumnModel(columnModelMembrosAtivosSemPais);
      setSelectedTotalHeader(headerMembrosAtivosSemPai);
    } else if (args.tipo_validacao.constante === 'duplicidadePais') {
      setSelectedUrl(urlDuplicidadePais);
      setSelectedColumnModel(columnModelDuplicidadePais);
      setSelectedTotalHeader(headerMembrosDuplicidadePai);
    } else if (args.tipo_validacao.constante === 'estruturaRede') {
      setSelectedUrl(urlEstruturaRede);
      setSelectedColumnModel(columnModelEstruturaRede);
      setSelectedTotalHeader(headerEstruturaRede);
    // } else if (args.tipo_validacao.constante === 'redeLoopingInfinito') {
    //   setSelectedUrl(urlLoopingInfinito);
    //   setSelectedColumnModel(columnModelLoopingInfinito);
    //   setSelectedTotalHeader({});
    }
    crud.tipo_validacao = args.tipo_validacao.constante;
    crudFilter.tipo_validacao = args.tipo_validacao.constante;
    crudFilter.data.id_cliente = null;
    crudFilter.changed.id_cliente = null;
    
    crud.wReportCfg.clear();

    crud.reload = true;
    setCrud(crud);
  }
  //
  const reportCfg = {
    label: utils.lng('validacao_rede', 'Validação de Rede'),
    name: 'reportCfg',
    add: false,
    print: true,
    auto: false,
    type: 'list',
    //table: true,
    widgetVar: 'wReportCfg',
    list: {
      url: selectedUrl
    },
    filters: {
      tipo_validacao: {
        type: 'select',
        label: utils.lng('tipo_validacao', "Tipo de Validação"),
        change: {
          action: 'changeTipoValidacao'
        },
        data: [
          { label: utils.lng('membros_ativos_sem_pais', "Membros Ativos sem pais"), value: 'membrosAtivosSemPais' },
          { label: utils.lng('duplicidade_pais', "Duplicidade de Pais"), value: 'duplicidadePais' },
          { label: utils.lng('estrutura_rede', "Estrutura de Rede") , value: 'estruturaRede' }//,
          //{ label: utils.lng('redeLoopingInfinito', "Rede com looping Infinito"), value: 'redeLoopingInfinito' }
        ]
      },
      id_cliente: {
        type: 'entity',
        entity: 'Cliente',
        label: utils.lng('cliente', "Cliente"),
        list: { url: utils.getUser().administrativo === true || (utils.getUser().tipo+'').indexOf('ADMINISTRATIVO') > -1 ? 'endpoint_listar_all_clientes' : 'endpoint_listar_clientes_minha_rede' },
        itemLabel: 'nome',
        itemValue: 'id',
        rendered: "${crud.data.tipo_validacao.constante === 'estruturaRede'}"
      }
    },
    totalizer: selectedTotalHeader,
    columns: selectedColumnModel
  };

  return (
    <View className="ui-view-report" name="rel-validacao_rede">
      <Element value={reportCfg} crud={crud} data={crud.data} view={view} ></Element>
    </View>
  );
}

export default RelatorioValidacaoRede;