import React from "react";
import View from './view';
import utils from './utils';
import Element from './element';
import './css/produto.css';

const Card_produto = function (props) {

  let crud = props.crud;
  let view = props.view;
  let data = props.data;
  let idClassificacao = props.idClassificacao;

  const urlCatalogo = 'listarProdutosCatalogo?' + (idClassificacao > 0 ? 'classificacao=' + idClassificacao : '');

  const produtos = {
    name: "produtos",
    type: "list",
    layout: 'list',
    rowCols: 3,
    itemVar: 'p',
    size: 30,
    rowStyleClass: 'ui-row-card ui-card-produto card-produto-classe',
    entity: 'HubProduto',
    parent: 'catalogo',
    add: false,
    header: false,
    search: false,
    list: {
      url: urlCatalogo,
      method: 'GET'
    },
    elements: {
      favorito: {
        click: {
          url: 'cart/changeFavorite/{id_sku}'
        },
        variant: 'text',
        styleClass: "favorito-classe ${favorito ? 'ui-selected' : ''}",
        type: 'button', icon: "${favorito ? 'fas fa-heart' : 'far fa-heart'}", theme: 'default', cols: 2
      },
      corpo: {
        elements: {
          destaque: { type: 'span', field: 'destaque_descricao', rendered: '#{typeof @this.destaque_descricao !== "undefined"}', styleClass: 'destaque-classe', background: '#{@this.destaque_cor}' },
          avatar: { type: 'link', field: 'avatar', label: ' ', upload: false, typeStyle: 'CardProduct', primeiro_avatar: '${@this.primeiro_avatar}', segundo_avatar: '${@this.segundo_avatar}' },
          descricao: { type: 'span', field: 'descricao' },
          indisponivel: { rendered: "${@this.saldo <= 0 && @this.valida_estoque}", value: utils.lng('produto_indisponivel', 'Produto indisponível') },
          tag_grade: { type: 'span', field: 'codigo_grade', styleClass: 'ui-tag ui-bottom ui-left ${@this.rowIndex % 2 == 0 ? "ui-tag-destaque" : ""}' },
          valor_preco_venda: {
            styleClass: 'ui-valor-original',
            type: 'span', format: 'money', field: 'valor_preco_venda', value: '#{"de " + utils.money(@this.valor_preco_venda) + " por "}',
            rendered: '${@this.percentual_desconto > 0}',
            style: { color: '#555', fontSize: '20px', fontWeight: 'bold' }
          },
          valor: {
            type: 'span',
            format: 'money',
            field: 'valor',
            value: "${@this.exibir_centavos ? (utils.money(@this.valor) + (@this.codigo_grade === 'PE' ? ' / metro' : '')) : (utils.moneyCents(@this.valor) + (@this.codigo_grade === 'PE' ? ' / metro' : ''))}"
          },
          valor_dolar: {
            type: 'span',
            rendered: '${@this.exibir_custo_dolar}',
            field: 'valor',
            style: {
              paddingTop: '10px'
            },
            value: "${utils.customMoney('$',@this.valor_custo)}"
          }
        },
        click: {
          action: 'open',
          url: '/carrinho/produto/{id_sku}'
        }
      },
      comandos: {
        elements: {
          comprar: {
            disabled: "${@this.saldo <= 0 && @this.valida_estoque}",
            click: {
              action: 'open',
              url: '/carrinho/produto/{id_sku}'
            },
            variant: 'text',
            type: 'button', label: 'COMPRAR', styleClass: 'ui-classe-font-produto', icon: 'fa fa-shopping-cart', theme: 'default', cols: 10
          },
        }
      }
    }
  };

  return (
    <Element crud={crud} view={view} value={produtos} ></Element>
  );
};

export default Card_produto;