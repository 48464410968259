import React, { useEffect, useState } from "react";
import Element from '../element';
import View from '../view';
import utils from '../utils';

function ContratosPublico(props) {
  let [view, setView] = useState({});
  let [crud, setCrud] = useState({ data: {}, changed: {} });
  let [idCliente, setIdCliente] = useState(0);
  let [ready, setReady] = useState(false);
  let [index, setIndex] = useState(0);

  const carregarCliente = function () {
    let token = utils.getParam("token");

    crud.changed.token = token;
    crud.data.token = token;

    if (!idCliente) {
      idCliente = 0;
    }
    utils.execute(crud, 'encontrarContratoPublico?single=true&codigo_sessao=' + token, null, function (res) {
      crud.data = res.data;
      crud.changed = res.data;
      idCliente = crud.data.id;
      setIdCliente(idCliente);

      setCrud(crud);
      setIndex(index + 1);
    }, true);
  }

  useEffect(() => {
    if (!ready) {
      ready = true;
      setReady(true);
      carregarCliente();
    }
  });

  let ctrs = {
    elements: {
      detail: { label: 'Meus contratos' },
      dados: {
        type: 'card',
        elements: {
          nome: { type: 'span', field: 'nome', value: '${@this.codigo + " - " + @this.nome}' }
        }
      },
      itens: {
        type: 'list',
        header: false,
        paginate: false,
        print: false,
        name: 'contratos',
        list: {
          url: 'Contrato',
          send: false
        },
        columns: {
          id: {},
          nome: {},
          download: {
            columnStyle: { width: '40px' },
            icon: 'fa fa-download',
            click: {
              url: 'contrato/{id}/' + idCliente + '.pdf',
              method: 'GET',
              fileName: 'contrato.pdf',
              download: true
            }
          }
        }
      }
    }
  };

  return (
    <div className="ui-contratos ui-contratos-publico">
      <Element value={ctrs} crud={crud} data={crud.data} view={view} ></Element>
    </div>
  );
}

export default ContratosPublico;